<template>
  <main id="supportsChat">
    <vue-advanced-chat
      :height="chatViewHeight"
      :room-id="roomId"
      :current-user-id="currentUserId"
      :rooms="JSON.stringify(rooms)"
      :messages="JSON.stringify(messages)"
      :messages-loaded="messagesLoaded"
      :show-add-room="false"
      :emojis-suggestion-enabled="true"
      :show-files="true"
      accepted-files=".doc, .docx, .xls, .xlsx, .pdf, .txt, image/png, image/jpeg"
      @open-file="handleDownloadFile($event.detail[0])"
      :show-audio="false"
      @send-message="sendMessage($event.detail[0])"
      @fetch-messages="fetchMessages($event.detail[0])"
      :text-messages="JSON.stringify(textMessages)"
      :rooms-list-opened="false"
      show-new-messages-divider="false"
    >
    </vue-advanced-chat>
  </main>
</template>

<script>
import { register } from "vue-advanced-chat";
register();
const $ = require("jquery");
import axios from "axios";
//
import Globals from "/src/common/js/Globals";
import waitLoader from "/src/common/js/waitLoader";
import functions from "/src/common/js/functions";
import popup from "/src/common/js/popup";
import moment from "moment";
//
import { mapStores } from "pinia";
import { useUsersStore } from "/src/common/stores/users.js";

//login component
export default {
  computed: {
    ...mapStores(useUsersStore),
  },

  data() {
    return {
      pageName: "Supports Chat",
      chatViewHeight: "calc(100vh - 48px)",
      currentUserId: "1234",
      roomId: "",
      rooms: [],
      messages: [],
      messagesLoaded: false,
      textMessages: {
        ROOMS_EMPTY: "Aucune conversation",
        ROOM_EMPTY: "Aucune conversation sélectionnée",
        NEW_MESSAGES: "Nouveaux messages",
        MESSAGE_DELETED: "Ce message a été supprimé",
        MESSAGES_EMPTY: "Aucun message",
        CONVERSATION_STARTED: "Commencé le :",
        TYPE_MESSAGE: "Tapez votre message",
        SEARCH: "Rechercher",
        IS_ONLINE: "est en ligne",
        LAST_SEEN: "dernière connexion ",
        IS_TYPING: "est en train d'écrire...",
        CANCEL_SELECT_MESSAGE: "Annuler Sélection",
      },
      //
      hasPlayNotifSound: false,
      notifyAudio: null,
      //
      isInDownloading: false,
    };
  },

  mounted() {
    waitLoader.show();
    //
    this.notifyAudio = new Audio(
      Globals.website_url + "notify_new_message.wav"
    );
    //
    $("#routeName")[0].innerHTML = this.pageName;
    //
    //for update on vue mounted
    document.addEventListener("clientMainIsReady", () => {
      this.__update();
    });
    if (Globals.clientMainIsOk) {
      this.__update();
    }
  },

  methods: {
    __update() {
      waitLoader.show();
      //
      setTimeout(() => {
        this.update();
        waitLoader.hide();
      }, 1);
    },

    update() {
      this.currentUserId = this.usersStore.User.account.id;
      this.getClientRoomFromDatabase();
    },

    handleDownloadFile(data) {
      if (this.isInDownloading) return;
      //
      this.isInDownloading = true;
      //
      axios({
        url:
          Globals.website_url +
          "php/readMediaForClient.php?relpath=chats/docs/" +
          data.file.file.localPath, //your url
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", data.file.file.name); //or any other extension
        link.click();
        URL.revokeObjectURL(href);
        this.isInDownloading = false;
      });
    },

    async getClientRoomFromDatabase() {
      try {
        const resp = await axios.get(
          Globals.website_url +
            "php/api.php?query=create-client-room-if-not-exists/" +
            this.currentUserId
        );
        this.rooms = resp.data;
        this.rooms[0].users[0].username = this.usersStore.User.data.fname;
        //
        this.roomId = resp.data[0].roomId;
        this.syncMessages();
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    },

    playNotifySound() {
      this.notifyAudio.play();
    },

    makeMessages() {
      this.hasPlayNotifSound = false;
      this.messages.forEach((message) => {
        //get date data
        // Example date string
        const dateString = message.dateTime;
        // Split the date string into its components
        const [datePart, timePart] = dateString.split(" ");
        const [year, month, day] = datePart.split("-");
        const [hour, minute, second] = timePart.split(":");
        // Create a new Date object
        const mDate = new Date(year, month - 1, day, hour, minute, second);
        //
        const options = {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        const frenchDate = new Intl.DateTimeFormat("fr-FR", options).format(
          mDate
        );
        const ts = mDate.toString().substring(16, 21);
        //inject data in each message
        message.date = frenchDate;
        message.timestamp = ts;
      });
      //---------------------------------
      //notify sound for the last message
      //---------------------------------
      let message = this.messages[this.messages.length - 1];
      let dt1 = moment(message.dateTime).utc();
      // Get the current date and time in UTC
      let dtUTC = moment.utc();
      // Calculer la différence en secondes
      let diffSecs = dtUTC.diff(dt1, "seconds");
      //
      if (
        message.senderId != this.usersStore.User.account.id &&
        !this.hasPlayNotifSound &&
        diffSecs < 5
      ) {
        this.playNotifySound();
        this.hasPlayNotifSound = true;
      }
    },

    async getMessageFromDatabase() {
      try {
        const resp = await axios.get(
          Globals.website_url +
            "php/api.php?query=get-chat-messages/1/" +
            this.roomId
        );
        this.messages = resp.data;
        this.makeMessages();
        this.messagesLoaded = true;
        //
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    },

    syncMessages() {
      if (this.$route.name != "client-support-chat") {
        return;
      }
      //
      this.getMessageFromDatabase();
      //
      setTimeout(() => {
        this.syncMessages();
      }, Globals.chatFetchTime);
    },

    fetchMessages(/*{ room, options }*/) {
      setTimeout(async () => {
        // this.roomId = room.roomId;
        //
        this.getMessageFromDatabase();
        this.messagesLoaded = true;
      }, 1);
    },

    async sendMessage(message) {
      const files = message.files ?? [];
      //
      if (message.content == "" && (!files || files.length == 0)) {
        return;
      }
      //

      try {
        //send message
        const resp = await axios.get(
          Globals.website_url +
            "php/api.php?query=send-chat-message/1/" +
            this.roomId +
            "/" +
            this.usersStore.User.account.id +
            "/" +
            functions.correctQueryString(message.content) +
            "/" +
            functions.formatDateToYMDHMS(new Date()) +
            "/" +
            functions.correctQueryString(JSON.stringify([]))
        );

        //on error
        if (typeof resp.data == String) {
          popup.action = "notification";
          popup.setTitle("Notification");
          popup.setMessage("Problème d'envoi du message.");
          popup.setButton("Ok");
          popup.show();
          return;
        }

        //on message sended with success
        this.getMessageFromDatabase();
        //
        //send files after send message content
        for (let i = 0; i < files.length; i++) {
          let file = new File(
            [files[i].blob],
            files[i].name + "." + files[i].extension
          );
          //
          const formData = new FormData();
          formData.append("file", file);
          formData.append("messageId", resp.data.messageId);
          //
          axios
            .post(Globals.website_url + "php/uploadChatDocs.php", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((resp) => {
              //
            })
            .catch((err) => {
              //
            });
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style scoped>
#supportsChat {
  color: black;
  width: 100%;
}
</style>
