<template>
  <div id="bg"></div>

  <center>
    <form
      v-if="data.natureRegister == 'PERSON'"
      class="row mt-4"
      @submit.prevent="validatePerson()"
    >
      <h4 class="text-center mb-3">Document d'identification du particulier</h4>
      <!--  -->
      <div id="process" class="mb-3">
        <b>Important*: </b> <br />
        Les recto et verso doivent figurer sur une seule image ou dans un un
        même document PDF.
      </div>

      <table class="mb-3 table table-bordered">
        <tbody>
          <tr>
            <td class="align-middle">
              <div class="text-center">
                Pièce d'identité
                <br />
                ou passport
              </div>
            </td>
            <td class="text-center">
              <img
                id="img1"
                v-if="img1Src && isImg(doc1)"
                class="border border-dark"
                :src="img1Src"
                style="width: 112px"
              />
              <svg
                v-else-if="img1Src && !isImg(doc1)"
                height="112px"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.4"
                  d="M1 18a.969.969 0 0 0 .933 1h12.134A.97.97 0 0 0 15 18M1 7V5.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.828 1h8.239A.97.97 0 0 1 15 2v5M6 1v4a1 1 0 0 1-1 1H1m0 9v-5h1.5a1.5 1.5 0 1 1 0 3H1m12 2v-5h2m-2 3h2m-8-3v5h1.375A1.626 1.626 0 0 0 10 13.375v-1.75A1.626 1.626 0 0 0 8.375 10H7Z"
                />
              </svg>
              <!--  -->
              <!-- <svg v-else height="112px" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                  viewBox="0 0 20 18">
                  <path fill="currentColor"
                    d="M13 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM7.565 7.423 4.5 14h11.518l-2.516-3.71L11 13 7.565 7.423Z" />
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"
                    d="M18 1H2a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z" />
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"
                    d="M13 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM7.565 7.423 4.5 14h11.518l-2.516-3.71L11 13 7.565 7.423Z" />
                </svg> -->
              <div v-else class="d-flex noPreview">
                <div class="w-100 text-center noPrev">
                  Aucun fichier
                  <br />
                  sélectionné
                </div>
              </div>
            </td>
            <td class="align-middle text-center">
              <div class="btn btn-primary btn-rounded">
                <label class="form-label text-white m-1" for="doc1"
                  >Importer un fichier</label
                >
                <input
                  type="file"
                  class="form-control d-none"
                  id="doc1"
                  @input="getImg1Src('doc1')"
                  accept=".pdf, .jpg, .jpeg"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <!--  -->
      <!--  -->
      <div id="process" class="mb-3">
        <b>Important*: </b> <br />
        Importer le formulaire téléchargé, signé et cacheté avec la mention lu
        et approuvé.
      </div>

      <table class="mb-3 table table-bordered">
        <tbody>
          <tr>
            <td class="align-middle">
              <div class="text-center">
                Formulaire du nom
                <br />
                de domaine
              </div>
            </td>
            <td class="text-center">
              <svg
                v-if="pdfForm"
                height="112px"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.4"
                  d="M1 18a.969.969 0 0 0 .933 1h12.134A.97.97 0 0 0 15 18M1 7V5.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.828 1h8.239A.97.97 0 0 1 15 2v5M6 1v4a1 1 0 0 1-1 1H1m0 9v-5h1.5a1.5 1.5 0 1 1 0 3H1m12 2v-5h2m-2 3h2m-8-3v5h1.375A1.626 1.626 0 0 0 10 13.375v-1.75A1.626 1.626 0 0 0 8.375 10H7Z"
                />
              </svg>
              <!--  -->
              <div v-else class="d-flex noPreview">
                <div class="w-100 text-center noPrev">
                  Aucun fichier
                  <br />
                  sélectionné
                </div>
              </div>
            </td>
            <td class="align-middle text-center">
              <div class="btn btn-primary btn-rounded">
                <label class="form-label text-white m-1" for="docForm"
                  >Importer un fichier</label
                >
                <input
                  type="file"
                  class="form-control d-none"
                  id="docForm"
                  accept=".pdf"
                  @input="getPdfForm('docForm')"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <!--  -->
      <!--  -->
      <div class="col-12 d-flex justify-content-center">
        <button type="submit">
          VALIDER LES DOCUMENTS ET TERMINER
          <i class="bi bi-check-lg"></i>
        </button>
      </div>
    </form>

    <form
      v-else-if="data.natureRegister == 'STRUCTURE'"
      class="row mt-4"
      @submit.prevent="validateStruct()"
    >
      <h4 class="text-center mb-3">
        Document d'identification de la structure
      </h4>
      <!--  -->
      <div id="process" class="mb-3">
        <b>Important*: </b> <br />
        Les recto et verso doivent figurer sur une seule image ou dans un un
        même document PDF.
      </div>

      <table class="mb-3 table table-bordered">
        <tbody>
          <tr>
            <td class="align-middle">
              <div class="text-center">
                Preuve d'existance de la structure
                <br />
                (IFU, RCCM, Récépissé...)
              </div>
            </td>
            <td class="text-center">
              <img
                id="img1"
                v-if="img1Src && isImg(doc1)"
                class="border border-dark"
                :src="img1Src"
                style="width: 112px"
              />
              <svg
                v-else-if="img1Src && !isImg(doc1)"
                height="112px"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.4"
                  d="M1 18a.969.969 0 0 0 .933 1h12.134A.97.97 0 0 0 15 18M1 7V5.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.828 1h8.239A.97.97 0 0 1 15 2v5M6 1v4a1 1 0 0 1-1 1H1m0 9v-5h1.5a1.5 1.5 0 1 1 0 3H1m12 2v-5h2m-2 3h2m-8-3v5h1.375A1.626 1.626 0 0 0 10 13.375v-1.75A1.626 1.626 0 0 0 8.375 10H7Z"
                />
              </svg>
              <!--  -->
              <!-- <svg v-else height="112px" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                  viewBox="0 0 20 18">
                  <path fill="currentColor"
                    d="M13 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM7.565 7.423 4.5 14h11.518l-2.516-3.71L11 13 7.565 7.423Z" />
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"
                    d="M18 1H2a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z" />
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"
                    d="M13 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM7.565 7.423 4.5 14h11.518l-2.516-3.71L11 13 7.565 7.423Z" />
                </svg> -->
              <div v-else class="d-flex noPreview">
                <div class="w-100 text-center noPrev">
                  Aucun fichier
                  <br />
                  sélectionné
                </div>
              </div>
            </td>
            <td class="align-middle text-center">
              <div class="btn btn-primary btn-rounded">
                <label class="form-label text-white m-1" for="sDoc1"
                  >Importer un fichier</label
                >
                <input
                  type="file"
                  class="form-control d-none"
                  id="sDoc1"
                  @input="getImg1Src('sDoc1')"
                  accept=".pdf, .jpg, .jpeg"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <!--  -->
      <!--  -->
      <div id="process" class="mb-3">
        <b>Important*: </b> <br />
        Importer le formulaire téléchargé, signé et cacheté avec la mention lu
        et approuvé.
      </div>

      <table class="mb-3 table table-bordered">
        <tbody>
          <tr>
            <td class="align-middle">
              <div class="text-center">
                Formulaire du nom
                <br />
                de domaine
              </div>
            </td>
            <td class="text-center">
              <svg
                v-if="pdfForm"
                height="112px"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.4"
                  d="M1 18a.969.969 0 0 0 .933 1h12.134A.97.97 0 0 0 15 18M1 7V5.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.828 1h8.239A.97.97 0 0 1 15 2v5M6 1v4a1 1 0 0 1-1 1H1m0 9v-5h1.5a1.5 1.5 0 1 1 0 3H1m12 2v-5h2m-2 3h2m-8-3v5h1.375A1.626 1.626 0 0 0 10 13.375v-1.75A1.626 1.626 0 0 0 8.375 10H7Z"
                />
              </svg>
              <!--  -->
              <div v-else class="d-flex noPreview">
                <div class="w-100 text-center noPrev">
                  Aucun fichier
                  <br />
                  sélectionné
                </div>
              </div>
            </td>
            <td class="align-middle text-center">
              <div class="btn btn-primary btn-rounded">
                <label class="form-label text-white m-1" for="sDocForm"
                  >Importer un fichier</label
                >
                <input
                  type="file"
                  class="form-control d-none"
                  id="sDocForm"
                  accept=".pdf"
                  @input="getPdfForm('sDocForm')"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <!--  -->
      <!--  -->
      <div class="col-12 d-flex justify-content-center">
        <button type="submit">
          VALIDER LES DOCUMENTS ET TERMINER
          <i class="bi bi-check-lg"></i>
        </button>
      </div>
    </form>

    <form v-else class="row mt-4" @submit.prevent="validatePrest()">
      <h4 class="text-center mb-3">Document d'identification du prestataire</h4>
      <!--  -->
      <div id="process" class="mb-3">
        <b>Important*: </b> <br />
        Les recto et verso doivent figurer sur une seule image ou dans un un
        même document PDF.
      </div>

      <table class="mb-3 table table-bordered">
        <tbody>
          <tr>
            <td class="align-middle">
              <div class="text-center">
                Preuve d'existance de la structure
                <br />
                (IFU, RCCM, Récépissé...)
              </div>
            </td>
            <td class="text-center">
              <img
                id="img1"
                v-if="img1Src && isImg(doc1)"
                class="border border-dark"
                :src="img1Src"
                style="width: 112px"
              />
              <svg
                v-else-if="img1Src && !isImg(doc1)"
                height="112px"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.4"
                  d="M1 18a.969.969 0 0 0 .933 1h12.134A.97.97 0 0 0 15 18M1 7V5.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.828 1h8.239A.97.97 0 0 1 15 2v5M6 1v4a1 1 0 0 1-1 1H1m0 9v-5h1.5a1.5 1.5 0 1 1 0 3H1m12 2v-5h2m-2 3h2m-8-3v5h1.375A1.626 1.626 0 0 0 10 13.375v-1.75A1.626 1.626 0 0 0 8.375 10H7Z"
                />
              </svg>
              <!--  -->
              <!-- <svg v-else height="112px" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                  viewBox="0 0 20 18">
                  <path fill="currentColor"
                    d="M13 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM7.565 7.423 4.5 14h11.518l-2.516-3.71L11 13 7.565 7.423Z" />
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"
                    d="M18 1H2a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z" />
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"
                    d="M13 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM7.565 7.423 4.5 14h11.518l-2.516-3.71L11 13 7.565 7.423Z" />
                </svg> -->
              <div v-else class="d-flex noPreview">
                <div class="w-100 text-center noPrev">
                  Aucun fichier
                  <br />
                  sélectionné
                </div>
              </div>
            </td>
            <td class="align-middle text-center">
              <div class="btn btn-primary btn-rounded">
                <label class="form-label text-white m-1" for="pDoc1"
                  >Importer un fichier</label
                >
                <input
                  type="file"
                  class="form-control d-none"
                  id="pDoc1"
                  @input="getImg1Src('pDoc1')"
                  accept=".pdf, .jpg, .jpeg"
                />
              </div>
            </td>
          </tr>

          <tr>
            <td class="align-middle">
              <div class="text-center">
                Mandat
                <br />
                du client
              </div>
            </td>
            <td class="text-center">
              <img
                id="img1"
                v-if="img2Src && isImg(doc2)"
                class="border border-dark"
                :src="img2Src"
                style="width: 112px"
              />
              <svg
                v-else-if="img2Src && !isImg(doc2)"
                height="112px"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.4"
                  d="M1 18a.969.969 0 0 0 .933 1h12.134A.97.97 0 0 0 15 18M1 7V5.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.828 1h8.239A.97.97 0 0 1 15 2v5M6 1v4a1 1 0 0 1-1 1H1m0 9v-5h1.5a1.5 1.5 0 1 1 0 3H1m12 2v-5h2m-2 3h2m-8-3v5h1.375A1.626 1.626 0 0 0 10 13.375v-1.75A1.626 1.626 0 0 0 8.375 10H7Z"
                />
              </svg>
              <!--  -->
              <!-- <svg v-else height="112px" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                  viewBox="0 0 20 18">
                  <path fill="currentColor"
                    d="M13 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM7.565 7.423 4.5 14h11.518l-2.516-3.71L11 13 7.565 7.423Z" />
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"
                    d="M18 1H2a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z" />
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"
                    d="M13 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM7.565 7.423 4.5 14h11.518l-2.516-3.71L11 13 7.565 7.423Z" />
                </svg> -->
              <div v-else class="d-flex noPreview">
                <div class="w-100 text-center noPrev">
                  Aucun fichier
                  <br />
                  sélectionné
                </div>
              </div>
            </td>
            <td class="align-middle text-center">
              <div class="btn btn-primary btn-rounded">
                <label class="form-label text-white m-1" for="pDoc2"
                  >Importer un fichier</label
                >
                <input
                  type="file"
                  class="form-control d-none"
                  id="pDoc2"
                  @input="getImg2Src('pDoc2')"
                  accept=".pdf, .jpg, .jpeg"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <!--  -->
      <!--  -->
      <div id="process" class="mb-3">
        <b>Important*: </b> <br />
        Importer le formulaire téléchargé, signé et cacheté avec la mention lu
        et approuvé.
      </div>

      <table class="mb-3 table table-bordered">
        <tbody>
          <tr>
            <td class="align-middle">
              <div class="text-center">
                Formulaire du nom
                <br />
                de domaine
              </div>
            </td>
            <td class="text-center">
              <svg
                v-if="pdfForm"
                height="112px"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.4"
                  d="M1 18a.969.969 0 0 0 .933 1h12.134A.97.97 0 0 0 15 18M1 7V5.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.828 1h8.239A.97.97 0 0 1 15 2v5M6 1v4a1 1 0 0 1-1 1H1m0 9v-5h1.5a1.5 1.5 0 1 1 0 3H1m12 2v-5h2m-2 3h2m-8-3v5h1.375A1.626 1.626 0 0 0 10 13.375v-1.75A1.626 1.626 0 0 0 8.375 10H7Z"
                />
              </svg>
              <!--  -->
              <div v-else class="d-flex noPreview">
                <div class="w-100 text-center noPrev">
                  Aucun fichier
                  <br />
                  sélectionné
                </div>
              </div>
            </td>
            <td class="align-middle text-center">
              <div class="btn btn-primary btn-rounded">
                <label class="form-label text-white m-1" for="pDocForm"
                  >Importer un fichier</label
                >
                <input
                  type="file"
                  class="form-control d-none"
                  id="pDocForm"
                  accept=".pdf"
                  @input="getPdfForm('pDocForm')"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <!--  -->
      <!--  -->
      <div class="col-12 d-flex justify-content-center">
        <button type="submit">
          VALIDER LES DOCUMENTS ET TERMINER
          <i class="bi bi-check-lg"></i>
        </button>
      </div>
    </form>
  </center>
</template>

<script>
// const $ = require("jquery");
import Globals from "/src/common/js/Globals";
import waitLoader from "/src/common/js/waitLoader";
import functions from "/src/common/js/functions";
import axios from "axios";
import popup from "/src/common/js/popup";
//
import * as localforage from "localforage";
// import html2pdf from "html2pdf.js";

//login component
export default {
  data() {
    return {
      hasEnterprise: false,
      //
      data: {},
      //
      userData: {
        id: "",
        fname: "",
        email: "",
        tel: "",
        home_address: "",
        country: "",
        city: "",
      },
      //
      img1Src: "",
      doc1: null,
      img2Src: "",
      doc2: null,
      pdfForm: null,
      formIsBusy: false,
      //
      domainName: "---",
    };
  },

  mounted() {
    waitLoader.show();
    //
    localforage
      .getItem("ecocloud/fullaccess")
      .then(async (result) => {
        let jsData = result;
        if (jsData == "" || jsData == "{}" || jsData == null) {
          return;
        }
        try {
          jsData = JSON.parse(jsData);
        } catch (e) {
          return;
        }
        //
        this.data = jsData;
        this.data.docs = {};
        waitLoader.hide();
        //
      })
      .catch((err) => {
        console.log(err);
      });
    //
  },

  methods: {
    isImg(file) {
      return file.type.indexOf("image/") >= 0 ? true : false;
    },

    getImg1Src(inputId) {
      const fileInput = document.getElementById(inputId);
      //
      if (fileInput.files && fileInput.files[0]) {
        let file = fileInput.files[0];
        this.doc1 = file;
        //
        //new file name for avoid uploading errors
        let newName = file.name.replaceAll("'", "_");
        let $self = this;
        //
        const reader1 = new FileReader();
        reader1.onload = function (e) {
          const newFile = new File([e.target.result], newName, {
            type: file.type,
          });
          $self.doc1 = newFile;
        };
        reader1.readAsArrayBuffer(file);
        //
        //
        const reader = new FileReader();
        reader.onload = (e) => {
          this.img1Src = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        this.doc1 = null;
      }
    },

    getImg2Src(inputId) {
      const fileInput = document.getElementById(inputId);
      //
      if (fileInput.files && fileInput.files[0]) {
        let file = fileInput.files[0];
        this.doc2 = file;
        //
        //new file name for avoid uploading errors
        let newName = file.name.replaceAll("'", "_");
        let $self = this;
        //
        const reader1 = new FileReader();
        reader1.onload = function (e) {
          const newFile = new File([e.target.result], newName, {
            type: file.type,
          });
          $self.doc2 = newFile;
        };
        reader1.readAsArrayBuffer(file);
        //
        //
        const reader = new FileReader();
        reader.onload = (e) => {
          this.img2Src = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        this.doc2 = null;
      }
    },

    getPdfForm(inputId) {
      const fileInput = document.getElementById(inputId);
      //
      if (fileInput.files && fileInput.files[0]) {
        let file = fileInput.files[0];
        this.pdfForm = file;
        //
        //new file name for avoid uploading errors
        let newName = file.name.replaceAll("'", "_");
        let $self = this;
        //
        const reader1 = new FileReader();
        reader1.onload = function (e) {
          const newFile = new File([e.target.result], newName, {
            type: file.type,
          });
          $self.pdfForm = newFile;
        };
        reader1.readAsArrayBuffer(file);
      } else {
        this.pdfForm = null;
      }
    },

    async validation() {
      waitLoader.setLabel("Enregistrement des informations");
      waitLoader.show();
      //
      try {
        let fname = functions.correctQueryString(this.data.Fname1);
        let email = functions.correctQueryString(this.data.Email1);

        let password = functions.correctQueryString(null);
        let tel = this.data.Tel1;
        tel = functions.correctPhoneNumber(tel.replace(".", ""));
        let country = functions.correctQueryString(this.data.Country1);
        let city = functions.correctQueryString(this.data.City1);
        let address = functions.correctQueryString(this.data.Address1);
        //
        //check if an user exists with email or tel
        let fd = new FormData();
        fd.append("query", "auto-check-if-user-exists/" + email + "/" + tel);
        let resp = await axios.post(Globals.website_url + "php/api.php", fd);
        //
        let h = resp.data;
        this.data.user_id = h;
        //
        if (h == "0") {
          fd = new FormData();
          fd.append(
            "query",
            "auto-create-user/" +
              fname +
              "/" +
              email +
              "/" +
              password +
              "/" +
              tel +
              "/" +
              country +
              "/" +
              city +
              "/" +
              address
          );
          resp = await axios.post(Globals.website_url + "php/api.php", fd);
          //
          h = resp.data;
          this.data.user_id = h;
          //
          if (h == "0") {
            waitLoader.hide();
            this.formIsBusy = true;
            //
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage("Impossible de poursuivre l'opération.");
            popup.setButton("Ok");
            popup.show();
            return;
          }
        }
        //
        //create enterprise data
        let uid = functions.correctQueryString(this.data.user_id);
        let Fname4 = functions.correctQueryString(this.data.Fname4);
        let tel4 = functions.correctPhoneNumber(
          this.data.Tel4.replace(".", "")
        );
        let country4 = functions.correctQueryString(this.data.Country4);
        let city4 = functions.correctQueryString(this.data.City4);
        let address4 = functions.correctQueryString(this.data.Address4);
        //
        fd = new FormData();
        fd.append(
          "query",
          "set-user-enterprise-by-id/" +
            uid +
            "/" +
            Fname4 +
            "/" +
            tel4 +
            "/" +
            country4 +
            "/" +
            city4 +
            "/" +
            address4
        );
        resp = await axios.post(Globals.website_url + "php/api.php", fd);
        //
        h = resp.data;
        console.log(h);

        if (h != "1") {
          waitLoader.hide();
          this.formIsBusy = true;
          //
          popup.action = "notification";
          popup.setTitle("Notification");
          popup.setMessage("Impossible de poursuivre l'opération.");
          popup.setButton("Ok");
          popup.show();
          return;
        }

        //on completed user enterprise
        //check if domain subscription exists
        fd = new FormData();
        fd.append(
          "query",
          "auto-check-if-subscription-domain-exists/" +
            functions.correctQueryString(this.data.Domain)
        );
        resp = await axios.post(Globals.website_url + "php/api.php", fd);
        //
        h = resp.data;
        this.data.subs_ids = h;
        //
        if (h == "0") {
          fd = new FormData();
          fd.append(
            "query",
            "auto-create-subs/" +
              functions.correctQueryString(this.data.Domain) +
              "/" +
              this.data.user_id +
              "/" +
              0 +
              "/" +
              null +
              "/" +
              null +
              "/" +
              null +
              "/" +
              0 +
              "/" +
              this.data.npa_hash
          );
          resp = await axios.post(Globals.website_url + "php/api.php", fd);
          //
          h = resp.data;
          this.data.subs_ids = h;
          //
          if (h == "0") {
            waitLoader.hide();
            this.formIsBusy = true;
            //
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage("Impossible de poursuivre l'opération.");
            popup.setButton("Ok");
            popup.show();
            return;
          }
        }
        //on completed subs
        //
        //set subscription docs or new docs
        fd = new FormData();
        fd.append(
          "query",
          "auto-set-subscription-docs/" +
            this.data.subs_ids +
            "/" +
            functions.correctQueryString(this.data.docs.pdfFormPath) +
            "/" +
            functions.correctQueryString(this.data.docs.docsPath) +
            "/" +
            functions.correctQueryString(JSON.stringify(this.data))
        );
        resp = await axios.post(Globals.website_url + "php/api.php", fd);
        //
        h = resp.data;
        //
        if (h == "0") {
          waitLoader.hide();
          this.formIsBusy = true;
          //
          popup.action = "notification";
          popup.setTitle("Notification");
          popup.setMessage("Impossible de poursuivre l'opération.");
          popup.setButton("Ok");
          popup.show();
          return;
        }
        //
        this.data.Years = "1";
        this.data.externalAccountCreated = true;
        this.data.externalAccountId = this.data.user_id;
        this.data.paymentSpace = "external";
        //
        localforage
          .setItem("ecocloud/fullaccess", JSON.stringify(this.data))
          .then(() => {
            //on success
            waitLoader.hide();
            //FB Pixel event
            window.fbq("trackCustom", "ExternalValidateDomainBfDocs", {
              domain: this.data.Domain,
            });
            //Google Analytics
            this.$gtag.event("ExternalValidateDomainBfDocs", {
              domain: this.data.Domain,
            });
            //
            this.formIsBusy = false;

            if (this.data.noPaymentAccess) {
              //create domain with epp client by npa_hash
              fd = new FormData();
              fd.append("npa_hash", this.data.npa_hash);

              axios
                .post(
                  Globals.website_url + "php/subscriptionOrderNoPayment.php",
                  fd
                )
                .then((response) => {
                  fd = new FormData();
                  fd.append(
                    "query",
                    "is-external-account/" + this.data.externalAccountId
                  );

                  axios
                    .post(Globals.website_url + "php/api.php", fd)
                    .then((response) => {
                      let h = response.data;
                      //on no external account or encountred error
                      if (!h || typeof h === String) {
                        this.$router.push("/login");
                        return;
                      }

                      location =
                        "?uid=" +
                        this.data.externalAccountId +
                        "#/enregistrer-mon-compte";
                      //
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                })
                .catch((err) => {
                  console.log(err);
                });
            }

            if (!this.data.npa_hash) {
              //on new external account with payment access
              this.$router.push("/payer-nom-domaine-bf");
            }
            //
          })
          .catch(function (err) {
            waitLoader.hide();
            console.log(err);
          });
        //
      } catch (err) {
        console.log(err);
        // Handle Error Here
        waitLoader.hide();
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage("Veuillez vérifier votre connexion à internet.");
        popup.setButton("Ok");
        popup.show();
      }
    },

    validatePerson() {
      if (this.formIsBusy) {
        return;
      }
      //
      this.formIsBusy = true;
      waitLoader.setLabel("Téléchargement des documents");
      waitLoader.show();
      //
      let formData = new FormData();
      formData.append("fileToUpload", this.pdfForm);
      formData.append("domain", this.data.Domain);
      formData.append("doc", "form");
      //
      axios
        .post(Globals.website_url + "php/uploadDoc.php", formData)
        .then((response) => {
          let h = response.data;
          //on error
          if (h === "") {
            //
            waitLoader.hide();
            this.formIsBusy = false;
            //say error
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage(
              "Problème de téléchargement des documents!<br>Veuillez reéssayer."
            );
            popup.setButton("Ok");
            popup.show();
            return;
          }
          this.data.docs.pdfFormPath = h;
          //
          formData = new FormData();
          formData.append("fileToUpload", this.doc1);
          formData.append("domain", this.data.Domain);
          formData.append("doc", "doc");
          //
          axios
            .post(Globals.website_url + "php/uploadDoc.php", formData)
            .then((response) => {
              h = response.data;
              //on error
              if (h === "") {
                //
                waitLoader.hide();
                this.formIsBusy = false;
                //say error
                popup.action = "notification";
                popup.setTitle("Notification");
                popup.setMessage(
                  "Problème de téléchargement de vos documents!<br>Veuillez reéssayer."
                );
                popup.setButton("Ok");
                popup.show();
                return;
              }
              //
              this.data.docs.docsPath = JSON.stringify([h]);
              //
              //on success
              this.validation();
              //
            })
            .catch((err) => {
              console.log(err);
              //say error
              popup.action = "notification";
              popup.setTitle("Notification");
              popup.setMessage("Impossible de télécharger vos documents.");
              popup.setButton("Ok");
              popup.show();
              //
              waitLoader.hide();
              this.formIsBusy = false;
            });
          //
          //
        })
        .catch((err) => {
          console.log(err);
          //say error
          popup.action = "notification";
          popup.setTitle("Notification");
          popup.setMessage("Impossible de télécharger vos documents.");
          popup.setButton("Ok");
          popup.show();
          //
          waitLoader.hide();
          this.formIsBusy = false;
        });
    },

    validateStruct() {
      if (this.formIsBusy) {
        return;
      }
      //
      this.formIsBusy = true;
      waitLoader.setLabel("Téléchargement des documents");
      waitLoader.show();
      //
      let formData = new FormData();
      formData.append("fileToUpload", this.pdfForm);
      formData.append("domain", this.data.Domain);
      formData.append("doc", "form");
      //
      axios
        .post(Globals.website_url + "php/uploadDoc.php", formData)
        .then((response) => {
          let h = response.data;
          //on error
          if (h === "") {
            //
            waitLoader.hide();
            this.formIsBusy = false;
            //say error
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage(
              "Problème de téléchargement des documents !<br>Veuillez reéssayer."
            );
            popup.setButton("Ok");
            popup.show();
            return;
          }
          //
          this.data.docs.pdfFormPath = h;
          //on success
          //
          formData = new FormData();
          formData.append("fileToUpload", this.doc1);
          formData.append("domain", this.data.Domain);
          formData.append("doc", "docX");
          //
          axios
            .post(Globals.website_url + "php/uploadDoc.php", formData)
            .then((response) => {
              h = response.data;
              //on error
              if (h === "") {
                //
                waitLoader.hide();
                this.formIsBusy = false;
                //say error
                popup.action = "notification";
                popup.setTitle("Notification");
                popup.setMessage(
                  "Problème de téléchargement de vos documents !<br>Veuillez reéssayer."
                );
                popup.setButton("Ok");
                popup.show();
                return;
              }
              //
              this.data.docs.docsPath = JSON.stringify([h]);
              //
              //on success
              this.validation();
              //
            })
            .catch(() => {
              //say error
              popup.action = "notification";
              popup.setTitle("Notification");
              popup.setMessage("Impossible de télécharger vos documents.");
              popup.setButton("Ok");
              popup.show();
              //
              waitLoader.hide();
              this.formIsBusy = false;
            });
          //
          //
        })
        .catch(() => {
          //say error
          popup.action = "notification";
          popup.setTitle("Notification");
          popup.setMessage("Impossible de télécharger vos documents.");
          popup.setButton("Ok");
          popup.show();
          //
          waitLoader.hide();
          this.formIsBusy = false;
        });
    },

    validatePrest() {
      if (this.formIsBusy) {
        return;
      }
      //
      this.formIsBusy = true;
      waitLoader.setLabel("Téléchargement des documents");
      waitLoader.show();
      //
      let formData = new FormData();
      formData.append("fileToUpload", this.pdfForm);
      formData.append("domain", this.data.Domain);
      formData.append("doc", "form");
      //
      axios
        .post(Globals.website_url + "php/uploadDoc.php", formData)
        .then((response) => {
          let h = response.data;
          //on error
          if (h === "") {
            //
            waitLoader.hide();
            this.formIsBusy = false;
            //say error
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage(
              "Problème de téléchargement des documents !<br>Veuillez reéssayer."
            );
            popup.setButton("Ok");
            popup.show();
            return;
          }
          //
          this.data.docs.pdfFormPath = h;
          //
          formData = new FormData();
          formData.append("fileToUpload", this.doc1);
          formData.append("domain", this.data.Domain);
          formData.append("doc", "doc1");
          //
          axios
            .post(Globals.website_url + "php/uploadDoc.php", formData)
            .then((response) => {
              let h = response.data;
              //on error
              if (h === "") {
                //
                waitLoader.hide();
                this.formIsBusy = false;
                //say error
                popup.action = "notification";
                popup.setTitle("Notification");
                popup.setMessage(
                  "Problème de téléchargement des documents !<br>Veuillez reéssayer."
                );
                popup.setButton("Ok");
                popup.show();
                return;
              }
              let doc1Path = h;
              //
              formData = new FormData();
              formData.append("fileToUpload", this.doc2);
              formData.append("domain", this.data.Domain);
              formData.append("doc", "doc2");
              //
              axios
                .post(Globals.website_url + "php/uploadDoc.php", formData)
                .then((response) => {
                  h = response.data;
                  //on error
                  if (h === "") {
                    //
                    waitLoader.hide();
                    this.formIsBusy = false;
                    //say error
                    popup.action = "notification";
                    popup.setTitle("Notification");
                    popup.setMessage(
                      "Problème de téléchargement de vos documents !<br>Veuillez reéssayer."
                    );
                    popup.setButton("Ok");
                    popup.show();
                    return;
                  }
                  let doc2Path = h;
                  //
                  this.data.docs.docsPath = JSON.stringify([
                    doc1Path,
                    doc2Path,
                  ]);
                  //
                  //on success
                  this.validation();
                  //
                })
                .catch(() => {
                  //say error
                  popup.action = "notification";
                  popup.setTitle("Notification");
                  popup.setMessage("Impossible de télécharger vos documents.");
                  popup.setButton("Ok");
                  popup.show();
                  //
                  waitLoader.hide();
                  this.formIsBusy = false;
                });
              //
              //
            })
            .catch(() => {
              //say error
              popup.action = "notification";
              popup.setTitle("Notification");
              popup.setMessage("Impossible de télécharger vos documents.");
              popup.setButton("Ok");
              popup.show();
              //
              waitLoader.hide();
              this.formIsBusy = false;
            });
        })
        .catch(() => {
          //say error
          popup.action = "notification";
          popup.setTitle("Notification");
          popup.setMessage("Impossible de télécharger vos documents.");
          popup.setButton("Ok");
          popup.show();
          //
          waitLoader.hide();
          this.formIsBusy = false;
        });
    },
  },
  //
};
</script>

<style scoped>
@import "intl-tel-input/build/css/intlTelInput.css";

#bg {
  background: url("/src/assets/images/app-bg.jpg") no-repeat center center fixed;
  background-size: cover;
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

form {
  margin: 20px 0;
  width: 100%;
  max-width: 800px;
  background-color: #f2fbff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.35);
  text-align: left;
  color: #111a2b;
  padding: 28px;
}

/*----------------------------------
USER DATA EDIT
----------------------------------*/
form {
  max-width: 640px;
  background-color: #f2fbff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  text-align: left;
  color: #111a2b;
  padding: 28px;
}

form input[readonly] {
  background-color: #f8f8f8;
  color: #888;
}

form select {
  /**/
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("/src/assets/images/select-arrow.svg");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  background-color: white;
}

form button[type="submit"] {
  margin-left: 12px;
  border: none;
  border-radius: 3px;
  padding: 8px 24px;
  width: auto;
  box-sizing: content-box;
  color: white;
  background-color: #111a2b;
  font-size: 17px;
}

form button[type="submit"]:hover {
  color: white;
  background-color: #1b2842;
}

form button[type="submit"] i {
  color: rgba(123, 255, 0);
  font-size: 16px;
}

form button[type="submit"]:hover {
  background-color: #346;
}

.rDomaintitle {
  border-bottom: solid 1px black;
  padding-bottom: 8px;
  margin-bottom: 16px;
}

.rDomaintitle button {
  float: left !important;
}

#process {
  margin: 8px 0;
  background-color: rgb(255, 230, 230);
  color: rgb(199, 46, 46);
  padding: 8px 16px;
  border-radius: 6px;
  width: 768px;
}

.noPreview {
  height: 112px !important;
  align-items: center !important;
}

.noPrev {
  height: max-content !important;
}
</style>
