<template>
  <main id="rDomain">
    <center>
      <form class="row" @submit.prevent="validate">
        <h4 class="text-center mb-3">Activation du nom de domaine (.bf)</h4>
        <div class="rDomaintitle">
          <h5>Information sur le nom de domaine</h5>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              id="rDomainDname"
              spellcheck="false"
              class="form-control"
              :value="domainName"
              disabled
              required
              readonly
            />
            <label for="rDomainDname">Nom de domaine</label>
          </div>

          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              id="rDomainYears"
              class="form-control"
              :value="1"
              disabled
              required
              readonly
            />
            <label for="rDomainYears">Nombre d'années d'enregistrement</label>
          </div>
        </div>

        <div class="mt-4 rDomaintitle">
          <h5>Information sur le demandeur</h5>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating p-1 mb-3">
            <select class="form-control" v-model="data.natureRegister" required>
              <option value="PERSON">Personne physique</option>
              <option value="STRUCTURE">Structure</option>
              <option value="PRESTATAIRE">Prestataire</option>
            </select>
            <label for="rNatureRegister">Nature du demandeur*</label>
          </div>

          <div
            class="form-floating p-1 mb-3"
            v-if="data.natureRegister == 'PERSON'"
          >
            <input
              type="text"
              spellcheck="false"
              class="form-control"
              placeholder="Krepin Jhon"
              v-model="data.Fname1"
              required
            />
            <label for="rDomainFname1">Nom/Prénoms*</label>
          </div>

          <div class="form-floating p-1 mb-3" v-else>
            <input
              type="text"
              spellcheck="false"
              class="form-control"
              placeholder="Ecodev"
              v-model="data.organism1"
              required
            />
            <label for="rDomainOrganism1">Organisme*</label>
          </div>
        </div>
        <!--  -->
        <div class="row oneRow" v-if="data.natureRegister != 'PERSON'">
          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Ouaga 2000"
              v-model="data.Sigle"
            />
            <label for="rDomainSigle">Sigle de l'entreprise</label>
          </div>

          <div class="form-floating p-1 mb-3">
            <input type="text" class="form-control" v-model="data.rJuridic" />
            <label for="rJuridic"
              >Forme Juridique (Société,Université,...)*</label
            >
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating p-1 mb-3">
            <input
              vinput="email"
              @input="checkEmailInput"
              type="text"
              class="form-control"
              placeholder="krepin@jhon.com"
              v-model="data.Email1"
              required
            />
            <label for="rDomainEmail1">Adresse Email*</label>
          </div>

          <div class="form-floating mb-3">
            <a style="font-size: 14px">Numéro de téléphone*</a>
            <br />
            <input
              id="rDomainTel1"
              type="tel"
              class="form-control rDomainTel"
              v-model="data.Tel1"
              required
            />
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Ouagadougou"
              v-model="data.rProfession"
              required
            />
            <label for="rProfession">Profession/Activité*</label>
          </div>

          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Ouaga 2000"
              v-model="data.Address1"
              required
            />
            <label for="rDomainAddress1">Adresse*</label>
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating p-1 mb-3">
            <select
              id="rDomainCountry1"
              v-model="data.Country1"
              class="form-control"
              required
            >
              <option value="">Aucun Pays sélectionné</option>
              <option v-for="(c, index) in cc" :key="index" :value="c.code">
                {{ c.name }}
              </option>
            </select>
            <label for="rDomainCountry1">Pays*</label>
          </div>

          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Ouagadougou"
              v-model="data.City1"
              required
            />
            <label for="rDomainCity1">Ville*</label>
          </div>
        </div>
        <!--  -->
        <div v-show="isFullForm" class="row oneRow">
          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Ouaga 2000"
              v-model="data.PostalBox1"
            />
            <label for="rDomainPostalBox1">Boîte Postale</label>
          </div>

          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Ouaga 2000"
              v-model="data.Fax1"
            />
            <label for="rDomainFax1">Fax</label>
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <span v-show="isFullForm" class="col-6 form-floating p-1 mb-3">
            <input
              type="number"
              class="form-control"
              placeholder="226"
              v-model="data.PostalCode1"
              required
            />
            <label for="rDomainPostalCode1">Code postal*</label>
          </span>
        </div>

        <div v-show="isFullForm">
          <div
            class="d-flex justify-content-between mb-2 mt-4 rDomaintitle"
            v-show="isFullForm"
          >
            <h5 class="d-block float-start me-3 mt-2">
              Information sur le technicien
            </h5>
            <button
              type="button"
              class="btn btn-primary w-auto"
              @click="fillInput(2)"
            >
              <a>{{ repeatDataText }}</a
              ><i class="bi bi-arrow-down ms-2"></i>
            </button>
          </div>
          <!--  -->
          <div class="row oneRow">
            <div class="form-floating p-1 mb-3">
              <input
                type="text"
                spellcheck="false"
                class="form-control"
                placeholder="Krepin Jhon"
                v-model="data.Fname2"
                required
              />
              <label for="rDomainFname2">Nom/Prénoms*</label>
            </div>

            <div class="form-floating p-1 mb-3">
              <select
                id="rDomainCountry2"
                v-model="data.Country2"
                class="form-control"
                required
              >
                <option value="">Aucun Pays sélectionné</option>
                <option v-for="(c, index) in cc" :key="index" :value="c.code">
                  {{ c.name }}
                </option>
              </select>
              <label for="rDomainCountry3">Pays*</label>
            </div>
          </div>
          <!--  -->
          <div class="row oneRow">
            <div class="form-floating p-1 mb-3">
              <input
                vinput="email"
                @input="checkEmailInput"
                type="text"
                class="form-control"
                placeholder="krepin@jhon.com"
                v-model="data.Email2"
                required
              />
              <label for="rDomainEmail2">Adresse Email*</label>
            </div>

            <div class="form-floating mb-3">
              <a style="font-size: 14px">Numéro de téléphone*</a>
              <br />
              <input
                id="rDomainTel2"
                type="tel"
                class="form-control rDomainTel"
                v-model="data.Tel2"
                required
              />
            </div>
          </div>
          <!--  -->
          <div v-show="isFullForm" class="row oneRow">
            <div class="form-floating p-1 mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="X"
                v-model="data.rStructure2"
              />
              <label for="rStructure2">Structure</label>
            </div>

            <div class="form-floating p-1 mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="X"
                v-model="data.rServiceFunction2"
              />
              <label for="rServiceFunction2">Service & Fonction</label>
            </div>
          </div>
          <!--  -->
          <div class="row oneRow">
            <div class="form-floating p-1 mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Ouagadougou"
                v-model="data.City2"
                required
              />
              <label for="rDomainCity2">Ville*</label>
            </div>

            <div class="form-floating p-1 mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Ouaga 2000"
                v-model="data.Address2"
                required
              />
              <label for="rDomainAddress2">Adresse*</label>
            </div>
          </div>
          <!--  -->
          <div v-show="isFullForm" class="row oneRow">
            <div class="form-floating p-1 mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Ouaga 2000"
                v-model="data.PostalBox2"
              />
              <label for="rDomainPostalBox2">Boîte Postale</label>
            </div>

            <div class="form-floating p-1 mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Ouaga 2000"
                v-model="data.Fax2"
              />
              <label for="rDomainFax2">Fax</label>
            </div>
          </div>
          <!--  -->
          <div class="row oneRow">
            <div class="form-floating p-1 mb-3">
              <input
                type="number"
                id="rDomainPostalCode2"
                class="form-control"
                placeholder="226"
                :value="data == null ? '' : data.PostalCode2"
                required
              />
              <label for="rDomainPostalCode2">Code postal*</label>
            </div>
          </div>
        </div>

        <div v-show="isFullForm">
          <div
            class="d-flex justify-content-between mb-2 mt-4 rDomaintitle"
            v-show="isFullForm"
          >
            <h5 class="d-block float-start me-3 mt-2">
              Information sur l'administrateur
            </h5>
            <button
              type="button"
              class="btn btn-primary w-auto"
              @click="fillInput(3)"
            >
              <a>{{ repeatDataText }}</a
              ><i class="bi bi-arrow-down ms-2"></i>
            </button>
          </div>
          <!--  -->
          <div class="row oneRow">
            <div class="form-floating p-1 mb-3">
              <input
                type="text"
                spellcheck="false"
                class="form-control"
                placeholder="Krepin Jhon"
                v-model="data.Fname3"
                required
              />
              <label for="rDomainFname3">Nom/Prénoms*</label>
            </div>

            <div class="form-floating p-1 mb-3">
              <select
                id="rDomainCountry3"
                v-model="data.Country3"
                class="form-control"
                required
              >
                <option value="">Aucun Pays sélectionné</option>
                <option v-for="(c, index) in cc" :key="index" :value="c.code">
                  {{ c.name }}
                </option>
              </select>
              <label for="rDomainCountry3">Pays*</label>
            </div>
          </div>
          <!--  -->
          <div class="row oneRow">
            <div class="form-floating p-1 mb-3">
              <input
                vinput="email"
                @input="checkEmailInput"
                type="text"
                class="form-control"
                placeholder="krepin@jhon.com"
                v-model="data.Email3"
                required
              />
              <label for="rDomainEmail3">Adresse Email*</label>
            </div>

            <div class="form-floating mb-3">
              <a style="font-size: 14px">Numéro de téléphone*</a>
              <br />
              <input
                id="rDomainTel3"
                type="tel"
                class="form-control rDomainTel"
                v-model="data.Tel3"
                required
              />
            </div>
          </div>
          <!--  -->
          <div v-show="isFullForm" class="row oneRow">
            <div class="form-floating p-1 mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="X"
                v-model="data.rStructure3"
              />
              <label for="rStructure3">Structure</label>
            </div>

            <div class="form-floating p-1 mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="X"
                v-model="data.rServiceFunction3"
              />
              <label for="rServiceFunction3">Service & Fonction</label>
            </div>
          </div>
          <!--  -->
          <div class="row oneRow">
            <div class="form-floating p-1 mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Ouagadougou"
                v-model="data.City3"
                required
              />
              <label for="rDomainCity3">Ville*</label>
            </div>

            <div class="form-floating p-1 mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Ouaga 2000"
                v-model="data.Address3"
                required
              />
              <label for="rDomainAddress3">Adresse*</label>
            </div>
          </div>
          <!--  -->
          <div v-show="isFullForm" class="row oneRow">
            <div class="form-floating p-1 mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Ouaga 2000"
                v-model="data.PostalBox3"
              />
              <label for="rDomainPostalBox3">Boîte Postale</label>
            </div>

            <div class="form-floating p-1 mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Ouaga 2000"
                v-model="data.Fax3"
              />
              <label for="rDomainFax3">Fax</label>
            </div>
          </div>
          <!--  -->
          <div class="row oneRow">
            <div class="form-floating p-1 mb-3">
              <input
                type="number"
                class="form-control"
                placeholder="Ouaga 2000"
                v-model="data.PostalCode3"
                required
              />
              <label for="rDomainPostalCode3">Code postal*</label>
            </div>
          </div>
        </div>

        <div v-show="isFullForm">
          <div
            class="d-flex justify-content-between mb-2 mt-4 rDomaintitle"
            v-show="isFullForm"
          >
            <h5 class="d-block float-start me-3 mt-2">
              Information de facturation
            </h5>
            <button
              type="button"
              class="btn btn-primary w-auto"
              @click="fillInput(4)"
            >
              <a>{{ repeatDataText }}</a
              ><i class="bi bi-arrow-down ms-2"></i>
            </button>
          </div>
          <!--  -->
          <div class="row oneRow">
            <div class="form-floating p-1 mb-3">
              <select
                class="form-control"
                v-model="data.natureBilling"
                required
              >
                <option value="PERSON">Personne physique</option>
                <option value="STRUCTURE">Structure</option>
                <option value="PRESTATAIRE">Prestataire</option>
              </select>
              <label for="rNatureBilling">Nature - Facturation*</label>
            </div>

            <div
              class="form-floating p-1 mb-3"
              v-if="data.natureBilling == 'PERSON'"
            >
              <input
                type="text"
                spellcheck="false"
                class="form-control"
                placeholder="Krepin Jhon"
                v-model="data.Fname4"
                required
              />
              <label for="rDomainFname4">Nom/Prénoms*</label>
            </div>

            <div class="form-floating p-1 mb-3" v-else>
              <input
                type="text"
                spellcheck="false"
                class="form-control"
                placeholder="Ecodev"
                v-model="data.organism4"
              />
              <label for="rDomainOrganism4">Organisme*</label>
            </div>
          </div>
          <!--  -->
          <div class="row oneRow">
            <div class="form-floating p-1 mb-3">
              <input
                vinput="email"
                @input="checkEmailInput"
                type="text"
                class="form-control"
                placeholder="krepin@jhon.com"
                v-model="data.Email4"
                required
              />
              <label for="rDomainEmail4">Adresse Email*</label>
            </div>

            <div class="form-floating mb-3">
              <a style="font-size: 14px">Numéro de téléphone*</a>
              <br />
              <input
                id="rDomainTel4"
                type="tel"
                class="form-control rDomainTel"
                v-model="data.Tel4"
                required
              />
            </div>
          </div>
          <!--  -->
          <div class="row oneRow">
            <div class="form-floating p-1 mb-3">
              <select
                id="rDomainCountry4"
                v-model="data.Country4"
                class="form-control"
                required
              >
                <option value="">Aucun Pays sélectionné</option>
                <option v-for="(c, index) in cc" :key="index" :value="c.code">
                  {{ c.name }}
                </option>
              </select>
              <label for="rDomainCountry4">Pays*</label>
            </div>

            <div class="form-floating p-1 mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Ouagadougou"
                v-model="data.City4"
                required
              />
              <label for="rDomainCity4">Ville*</label>
            </div>
          </div>
          <!--  -->
          <div v-show="isFullForm" class="row oneRow">
            <div class="form-floating p-1 mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Ouaga 2000"
                v-model="data.PostalBox4"
              />
              <label for="rDomainPostalBox4">Boîte Postale</label>
            </div>

            <div class="form-floating p-1 mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Ouaga 2000"
                v-model="data.Fax4"
              />
              <label for="rDomainFax4">Fax</label>
            </div>
          </div>
          <!--  -->
          <div class="row oneRow">
            <div class="form-floating p-1 mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Ouaga 2000"
                v-model="data.Address4"
                required
              />
              <label for="rDomainAddress4">Adresse*</label>
            </div>

            <div class="form-floating p-1 mb-3">
              <input
                type="number"
                class="form-control"
                placeholder="Ouaga 2000"
                v-model="data.PostalCode4"
                required
              />
              <label for="rDomainPostalCode4">Code postal*</label>
            </div>
          </div>
        </div>

        <!--  -->
        <!--  -->
        <!--  -->
        <div v-show="isFullForm" class="mt-4 rDomaintitle">
          <h5>Serveurs de nom de domaine</h5>
          <h5 class="text-danger fs-6">
            Par défaut ce sont les dns de Ecodev qui sont renseigné. Si vous
            avez un hébergeur autre, vous devez renseigner ses dns.
          </h5>
        </div>
        <!--  -->
        <div v-show="isFullForm" class="row oneRow3">
          <div class="noRow" :error="dnsErr[0]">
            <div class="form-floating p-1">
              <input
                type="text"
                class="form-control"
                v-model="data.rServer_1"
                required
                @input="checkDnsInput()"
              />
              <label for="rServer_1">Serveur 1*</label>
            </div>
            <div class="uniqueError">
              <i class="text-danger bi bi-info-circle-fill me-1"></i>
              <a class="text-danger">Vous avez déjà entré ce nom de serveur</a>
            </div>
          </div>

          <div class="form-floating p-1">
            <input
              type="text"
              class="form-control"
              v-model="data.rIPv4_1"
              required
            />
            <label for="rIPv4_1">Adresse IPv4*</label>
          </div>

          <div class="form-floating p-1">
            <input type="text" class="form-control" v-model="data.rIPv6_1" />
            <label for="rIPv6_1">Adresse IPv6</label>
          </div>
        </div>

        <div v-show="isFullForm" class="row oneRow3">
          <div class="noRow" :error="dnsErr[1]">
            <div class="form-floating p-1">
              <input
                type="text"
                class="form-control"
                v-model="data.rServer_2"
                required
                @input="checkDnsInput()"
              />
              <label for="rServer_2">Serveur 2*</label>
            </div>
            <div class="uniqueError">
              <i class="text-danger bi bi-info-circle-fill me-1"></i>
              <a class="text-danger">Vous avez déjà entré ce nom de serveur</a>
            </div>
          </div>

          <div class="form-floating p-1">
            <input
              type="text"
              class="form-control"
              v-model="data.rIPv4_2"
              required
            />
            <label for="rIPv4_2">Adresse IPv4*</label>
          </div>

          <div class="form-floating p-1">
            <input
              type="text"
              id="rIPv6_2"
              class="form-control"
              v-model="data.rIPv6_2"
            />
            <label for="rIPv6_2">Adresse IPv6</label>
          </div>
        </div>

        <div v-show="isFullForm" class="row oneRow3">
          <div class="noRow" :error="dnsErr[2]">
            <div class="form-floating p-1">
              <input
                type="text"
                class="form-control"
                v-model="data.rServer_3"
                @input="checkDnsInput()"
              />
              <label for="rServer_3">Serveur 3</label>
            </div>
            <div class="uniqueError">
              <i class="text-danger bi bi-info-circle-fill me-1"></i>
              <a class="text-danger">Vous avez déjà entré ce nom de serveur</a>
            </div>
          </div>

          <div class="form-floating p-1">
            <input type="text" class="form-control" v-model="data.rIPv4_3" />
            <label for="rIPv4_3">Adresse IPv4</label>
          </div>

          <div class="form-floating p-1">
            <input type="text" class="form-control" v-model="data.rIPv6_3" />
            <label for="rIPv6_3">Adresse IPv6</label>
          </div>
        </div>

        <div v-show="isFullForm" class="row oneRow3">
          <div class="noRow" :error="dnsErr[3]">
            <div class="form-floating p-1">
              <input
                type="text"
                class="form-control"
                v-model="data.rServer_4"
                @input="checkDnsInput()"
              />
              <label for="rServer_4">Serveur 4</label>
            </div>
            <div class="uniqueError">
              <i class="text-danger bi bi-info-circle-fill me-1"></i>
              <a class="text-danger">Vous avez déjà entré ce nom de serveur</a>
            </div>
          </div>

          <div class="form-floating p-1">
            <input
              type="text"
              class="form-control"
              placeholder="X"
              v-model="data.rIPv4_4"
            />
            <label for="rIPv4_4">Adresse IPv4</label>
          </div>

          <div class="form-floating p-1">
            <input
              type="text"
              class="form-control"
              placeholder="X"
              v-model="data.rIPv6_4"
            />
            <label for="rIPv6_4">Adresse IPv6</label>
          </div>
        </div>

        <!--  -->
        <!--  -->
        <!--  -->
        <div v-show="isFullForm" class="mt-4 rDomaintitle">
          <h5>Serveurs de nom de domaine pour les zones inverses</h5>
        </div>
        <!--  -->
        <div v-show="isFullForm" class="row oneRow3">
          <div class="noRow" :error="zoneInvErr[0]">
            <div class="form-floating p-1">
              <input
                type="text"
                class="form-control"
                v-model="data.zServer_1"
                @input="checkZoneInvInput"
              />
              <label for="rServer_1">Serveur 1</label>
            </div>
            <div class="uniqueError">
              <i class="text-danger bi bi-info-circle-fill me-1"></i>
              <a class="text-danger">Vous avez déjà entré ce nom de serveur</a>
            </div>
          </div>

          <div class="form-floating p-1">
            <input type="text" class="form-control" v-model="data.zIPv4_1" />
            <label for="rIPv4_1">Adresse IPv4</label>
          </div>

          <div class="form-floating p-1">
            <input type="text" class="form-control" v-model="data.zIPv6_1" />
            <label for="rIPv6_1">Adresse IPv6</label>
          </div>
        </div>

        <div v-show="isFullForm" class="row oneRow3">
          <div class="noRow" :error="zoneInvErr[1]">
            <div class="form-floating p-1">
              <input
                type="text"
                class="form-control"
                v-model="data.zServer_2"
                @input="checkZoneInvInput"
              />
              <label for="rServer_2">Serveur 2</label>
            </div>
            <div class="uniqueError">
              <i class="text-danger bi bi-info-circle-fill me-1"></i>
              <a class="text-danger">Vous avez déjà entré ce nom de serveur</a>
            </div>
          </div>

          <div class="form-floating p-1">
            <input type="text" class="form-control" v-model="data.zIPv4_2" />
            <label for="rIPv4_2">Adresse IPv4</label>
          </div>

          <div class="form-floating p-1">
            <input type="text" class="form-control" v-model="data.zIPv6_2" />
            <label for="rIPv6_2">Adresse IPv6</label>
          </div>
        </div>
        <!--  -->
        <!--  -->
        <!--  -->

        <div
          v-show="isFullForm"
          class="justify-content-between mb-2 mt-4 rDomaintitle"
        >
          <h5>Délégation de gestion</h5>
        </div>
        <!--  -->
        <div v-show="isFullForm" class="row oneRow">
          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Ouaga 2000"
              v-model="data.OldPrest"
            />
            <label for="rDomainPostalBox4">Ancien Prestataire</label>
          </div>

          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Ouaga 2000"
              v-model="data.NewPrest"
            />
            <label for="rDomainFax4">Prestataire</label>
          </div>
        </div>

        <!--  -->
        <!--  -->
        <!--  -->

        <div v-show="data.natureRegister == 'STRUCTURE'" class="mb-3">
          <div class="d-flex justify-content-between mb-2 mt-4 rDomaintitle">
            <h5 class="d-block float-start me-3 mt-2">
              STRUCTURE - PIECE A FOURNIR
            </h5>
          </div>
          <!--  -->
          <div class="row">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="exampleRadios"
                value="RCCM"
                checked
                v-model="data.cDocs"
              />
              <label class="form-check-label" for="exampleRadios1">
                Extrait du régistre de commerce et de crédit mobilier (RCCM)
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="exampleRadios"
                value="IFU"
                v-model="data.cDocs"
              />
              <label class="form-check-label" for="exampleRadios2">
                Numéro Imprimé Fiscal Unique IFU
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="exampleRadios"
                value="RECEPISSE"
                v-model="data.cDocs"
              />
              <label class="form-check-label" for="exampleRadios3">
                Document prouvant l'existence
              </label>
            </div>
          </div>
        </div>

        <!--  -->
        <!--  -->
        <!--  -->

        <div class="col-12 d-flex justify-content-center">
          <button type="submit">
            SOUMETTRE LES INFORMATIONS
            <i class="bi bi-check-lg"></i>
          </button>
        </div>
      </form>
    </center>
  </main>
</template>

<script>
//
import countryList from "/src/components/countryList.json";
//
const $ = require("jquery");
import Globals from "/src/common/js/Globals";
import waitLoader from "/src/common/js/waitLoader";
import functions from "/src/common/js/functions";
import axios from "axios";
import popup from "/src/common/js/popup";
import CountryList from "/src/components/countryList.vue";
//
import * as localforage from "localforage";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/js/utils"; // Import du script utils
//
import { mapStores } from "pinia";
import { useUsersStore } from "/src/common/stores/users.js";
//
import validator from "@/modules/validator";

//login component
export default {
  computed: {
    ...mapStores(useUsersStore),
  },

  components: {
    CountryList,
  },

  data() {
    return {
      cc: countryList,
      //
      repeatDataText: "Repéter les informations",
      //
      pageName: "Enregistrer son nom de domaine (.bf)",
      hasEnterprise: false,
      //
      data: {
        Country1: "",
        Country2: "",
        Country3: "",
        Country4: "",
        natureRegister: "PERSON",
        natureBilling: "PERSON",
        cDocs: "RCCM",
        rServer_1: Globals.ns[0].name,
        rIPv4_1: Globals.ns[0].ip,
        rServer_2: Globals.ns[1].name,
        rIPv4_2: Globals.ns[1].ip,
        rServer_3: Globals.ns[2].name,
        rIPv4_3: Globals.ns[2].ip,
        rServer_4: Globals.ns[3].name,
        rIPv4_4: Globals.ns[3].ip,
        PostalCode1: 226,
        PostalCode2: 226,
        PostalCode3: 226,
        PostalCode4: 226,
      },
      //
      userData: {
        id: "",
        fname: "",
        email: "",
        tel: "",
        home_address: "",
        country: "",
        city: "",
      },
      //
      intTel1: null,
      intTel2: null,
      intTel3: null,
      intTel4: null,
      //
      domainName: "---",
      //
      isFullForm: true,
      //
      dnsErr: [false, false, false, false],
      zoneInvErr: [false, false],
    };
  },

  mounted() {
    //set the routeName
    $("#routeName")[0].innerHTML = this.pageName;
    //
    localforage
      .getItem("ecocloud/User")
      .then((result) => {
        let jsData = result;
        if (jsData == "" || jsData == "{}" || jsData == null) {
          // This code runs if there were any errors.
          this.$router.replace("/login");
          return;
        }
        try {
          jsData = JSON.parse(jsData);
        } catch (e) {
          return;
        }
        //
        this.usersStore.User = jsData;
        this.domainName = jsData.activateDomain;
        //
        if (jsData.rAllDomainsBF) {
          if (jsData.rAllDomainsBF[this.domainName]) {
            this.data = jsData.rAllDomainsBF[this.domainName];
          }
          if (this.data && this.data.Domain) {
            this.data.Tel1 = this.data.Tel1.replace(".", "");
            this.data.Tel2 = this.data.Tel2.replace(".", "");
            this.data.Tel3 = this.data.Tel3.replace(".", "");
            this.data.Tel4 = this.data.Tel4.replace(".", "");
          }
        } else {
          this.usersStore.User.rAllDomainsBF = {};
        }
        //
        this.__update();
      })
      .catch(() => {});
    //
    //
    //
    this.intTel1 = intlTelInput($("#rDomainTel1")[0], {
      // any initialisation options go here
      initialCountry: "bf",
      preferredCountries: ["bf", "ci"],
    });
    this.intTel2 = intlTelInput($("#rDomainTel2")[0], {
      // any initialisation options go here
      initialCountry: "bf",
      preferredCountries: ["bf", "ci"],
    });
    this.intTel3 = intlTelInput($("#rDomainTel3")[0], {
      // any initialisation options go here
      initialCountry: "bf",
      preferredCountries: ["bf", "ci"],
    });
    this.intTel4 = intlTelInput($("#rDomainTel4")[0], {
      // any initialisation options go here
      initialCountry: "bf",
      preferredCountries: ["bf", "ci"],
    });
  },

  methods: {
    __update() {
      waitLoader.show();
      //
      setTimeout(() => {
        this.update();
      }, 1);
    },

    update() {
      //check if enterprise id exist
      let h = this.usersStore.User.data.enterprise_id;
      this.hasEnterprise = h == null ? false : true;
      //
      this.getUserData();
    },

    async getUserData() {
      try {
        //get All user data in json format
        let id = this.usersStore.User.account.id;
        id = functions.correctQueryString(id);
        const resp = await axios.get(
          Globals.website_url + "php/api.php?query=get-user-data-by-id/" + id
        );
        this.userData = resp.data;
        //set user country
        $("#rDomainCountry1")[0].value = this.userData.country;
        $("#rDomainCountry2")[0].value = this.userData.country;
        $("#rDomainCountry3")[0].value = this.userData.country;
        $("#rDomainCountry4")[0].value = this.userData.country;
        //
        //
        if (this.data && this.data.Domain) {
          this.natureRegister = this.data.natureRegister;
          this.natureBilling = this.data.natureBilling;
          //
          let s = $("#rDomainCountry1 option");
          for (let i = 0; i < s.length; i++) {
            if (s[i].innerHTML == this.data.Country1) {
              s[i].setAttribute("selected", "selected");
              break;
            }
          }
          //
          $("#rDomainCountry1").val(this.data.Country1);
          $("#rDomainCountry2").val(this.data.Country2);
          $("#rDomainCountry3").val(this.data.Country3);
          $("#rDomainCountry4").val(this.data.Country4);
          //
          this.data.Tel1 = this.data.Tel1.replace(".", "");
          this.data.Tel2 = this.data.Tel2.replace(".", "");
          this.data.Tel3 = this.data.Tel3.replace(".", "");
          this.data.Tel4 = this.data.Tel4.replace(".", "");
        } else {
          this.data.Fname1 = this.userData.fname;
          this.data.Email1 = this.userData.email;
          this.data.Tel1 = this.userData.tel;
          this.data.Country1 = this.userData.country;
          this.data.Country1 = this.userData.country;
          this.data.City1 = this.userData.city;
          this.data.Address1 = this.userData.home_address;
        }
        //
        waitLoader.hide();
      } catch (err) {
        console.log(err);
        // Handle Error Here
        waitLoader.hide();
        //say error
        popup.action = "Notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "Erreur de récupération des données. Veuillez actualiser la page"
        );
        popup.setButton("Ok");
        popup.show();
        //
        this.userData = {};
      }
    },

    fillInput(index) {
      this.data["Fname" + index] = this.data["Fname" + (index - 1)];
      this.data["organism" + index] = this.data["organism" + (index - 1)];
      //
      this.data["Email" + index] = this.data["Email" + (index - 1)];
      this.data["Tel" + index] = this.data["Tel" + (index - 1)];
      this.data["Country" + index] = this.data["Country" + (index - 1)];
      this.data["City" + index] = this.data["City" + (index - 1)];
      this.data["PostalCode" + index] = this.data["PostalCode" + (index - 1)];
      this.data["Address" + index] = this.data["Address" + (index - 1)];
      this.data["Fax" + index] = this.data["Fax" + (index - 1)];
      this.data["PostalBox" + index] = this.data["PostalBox" + (index - 1)];
      //
      if (index == 3) {
        this.data["rStructure" + index] = this.data["rStructure" + (index - 1)];
        this.data["rServiceFunction" + index] =
          this.data["rServiceFunction" + (index - 1)];
      }
    },

    checkDnsInput() {
      let dnsZones = [
        this.data.rServer_1,
        this.data.rServer_2,
        this.data.rServer_3,
        this.data.rServer_4,
      ];
      let i = 0;
      dnsZones.forEach((dns1) => {
        let j = 0;
        this.dnsErr[i] = false;
        dnsZones.forEach((dns2) => {
          if (i != j && dns1 && dns2 && dns1 == dns2) {
            this.dnsErr[i] = true;
          }
          j++;
        });
        i++;
      });
    },

    checkZoneInvInput() {
      let dnsZones = [this.data.zServer_1, this.data.zServer_2];
      let i = 0;
      dnsZones.forEach((dns1) => {
        let j = 0;
        this.zoneInvErr[i] = false;
        dnsZones.forEach((dns2) => {
          if (i != j && dns1 && dns2 && dns1 == dns2) {
            this.zoneInvErr[i] = true;
          }
          j++;
        });
        i++;
      });
    },

    checkDns() {
      let dnsZones = [
        this.data.rServer_1,
        this.data.rServer_2,
        this.data.rServer_3,
        this.data.rServer_4,
      ];
      //
      let err = false;
      let i = 0;
      dnsZones.forEach((dns1) => {
        let j = 0;
        if (err) return;
        dnsZones.forEach((dns2) => {
          if (err) return;
          if (i != j && dns1 && dns2 && dns1 == dns2) {
            err = true;
            //say error
            popup.action = "Notification";
            popup.setTitle("Notification");
            popup.setMessage(
              "Tous les serveurs de noms doivent être différents.<br> Veuillez vérifier vos serveurs de noms."
            );
            popup.setButton("Ok");
            popup.show();
            return;
          }
          j++;
        });
        i++;
      });
      return !err;
    },

    checkZoneInv() {
      let dnsZones = [this.data.zServer_1, this.data.zServer_2];
      //
      let err = false;
      let i = 0;
      dnsZones.forEach((dns1) => {
        let j = 0;
        if (err) return;
        dnsZones.forEach((dns2) => {
          if (err) return;
          if (i != j && dns1 && dns2 && dns1 == dns2) {
            err = true;
            //say error
            popup.action = "Notification";
            popup.setTitle("Notification");
            popup.setMessage(
              "Tous les serveurs de zones inverses doivent être différents.<br> Veuillez vérifier vos serveurs de zones inverses."
            );
            popup.setButton("Ok");
            popup.show();
            return;
          }
          j++;
        });
        i++;
      });
      return !err;
    },

    checkEmailInput: function (e) {
      e.target.setAttribute("input-error", "false");
      //
      if (
        e.target.getAttribute("vinput") === "email" &&
        !validator.isEmail(e.target.value)
      ) {
        e.target.setAttribute("input-error", "true");
      }
    },

    validate() {
      if (!this.checkDns()) return;
      if (!this.checkZoneInv()) return;
      //
      waitLoader.show();
      //
      const telFy = () => {
        for (let index = 1; index <= 4; index++) {
          let dc = this["intTel" + index].getSelectedCountryData().dialCode;
          let num = this["intTel" + index].getNumber();
          num = num.substr(dc.length + 1, num.length);
          this.data["Tel" + index] = "+" + dc + "." + num;
        }
      };
      telFy();
      //
      this.data.Domain = this.domainName;
      this.data.Years = 1;
      this.data.Fname1 =
        this.data.natureRegister === "PERSON"
          ? this.data.Fname1
          : this.data.organism1;
      //
      this.data.Fname4 =
        this.data.natureBilling === "PERSON"
          ? this.data.Fname4
          : this.data.organism4;
      //
      if (!this.data.cDocs) {
        this.data.cDocs = "RCCM";
      }
      //
      this.usersStore.User.rDomainBF = this.data;
      this.usersStore.User.rAllDomainsBF[this.domainName] = this.data;
      //save cache data
      localforage
        .setItem("ecocloud/User", JSON.stringify(this.usersStore.User))
        .then(() => {
          //
        })
        .catch(function () {
          // we got an error
          waitLoader.hide();
          // console.log(err);
        });
      //
      //
      //save data into db for update after complete all doamin register contacts steps
      let fd = new FormData();
      fd.append(
        "query",
        "store-subs-domain-data/" +
          functions.correctQueryString(
            this.usersStore.User.activateDomainSubsId
          ) +
          "/" +
          functions.correctQueryString(JSON.stringify(this.data))
      );
      //
      axios
        .post(Globals.website_url + "php/api.php", fd)
        .then((response) => {
          let h = response.data;
          //on error
          if (h != 1) {
            waitLoader.hide();
            //say error
            popup.action = "Notification";
            popup.setTitle("Notification");
            popup.setMessage(
              "Erreur d'enregistrement des informations!<br>Veuillez vérifier vos informations puis reéssayer."
            );
            popup.setButton("Ok");
            popup.show();
            return;
          }
          //
          waitLoader.hide();
          //on success
          this.$router.push("/client/domainBfDetails");
          //
        })
        .catch(() => {
          //
          // console.log(error);
          waitLoader.hide();
          //say error
          popup.action = "notification";
          popup.setTitle("Notification");
          popup.setMessage("Problème de connexion!<br>Veuillez reéssayer.");
          popup.setButton("Ok");
          popup.show();
        });
    },
    //
  },
};
</script>

<style scoped>
@import "intl-tel-input/build/css/intlTelInput.css";

#rDomain {
  padding: 24px 12px;
  position: relative;
}

/*----------------------------------
USER DATA EDIT
----------------------------------*/
form {
  margin: 20px 0;
  width: 100%;
  max-width: 800px;
  background-color: #f2fbff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.35);
  text-align: left;
  color: #111a2b;
  padding: 28px;
}

form input[readonly] {
  background-color: #f8f8f8;
  color: #888;
}

form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("/src/assets/images/select-arrow.svg");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  background-color: white;
}

form button[type="submit"] {
  margin-left: 12px;
  border: none;
  border-radius: 3px;
  padding: 8px 24px;
  width: auto;
  box-sizing: content-box;
  color: white;
  background-color: #111a2b;
  font-size: 17px;
}

form button[type="submit"]:hover {
  color: white;
  background-color: #1b2842;
}

form button[type="submit"] i {
  color: rgba(123, 255, 0);
  font-size: 16px;
}

form button[type="submit"]:hover {
  background-color: #346;
}

.rDomaintitle {
  border-bottom: solid 1px black;
  padding-bottom: 8px;
  margin-bottom: 16px;
}

.rDomaintitle button {
  float: left !important;
}

.form-floating label {
  opacity: 0.5;
}

.rDomainTel {
  width: 100% !important;
}

.oneRow div:not(.noRow) {
  display: inline;
  width: 50%;
}

.noRow {
  padding: 0;
}

.noRow div {
  display: block !important;
  width: 100% !important;
}

.oneRow3 div:first-child {
  display: inline;
  width: 40%;
}

.oneRow3 div:nth-child(2) {
  display: inline;
  width: 25%;
}

.oneRow3 div:last-child {
  display: inline;
  width: 35%;
}

.oneRowItem div {
  display: inline !important;
  width: 100% !important;
}

@media only screen and (max-width: 512px) {
  .oneRow3 {
    background-color: rgb(169, 221, 255);
    margin-bottom: 16px;
    padding: 3px;
  }

  .oneRow div {
    width: 100% !important;
  }

  .oneRow3 div {
    display: block !important;
    width: 100% !important;
  }
}

.uniqueError {
  font-size: 13px;
}

.noRow[error="false"] .uniqueError {
  display: none !important;
}

.noRow[error="true"] .uniqueError {
  display: block;
}

.noRow[error="true"] :nth-child(1) {
  border-color: red !important;
}

[input-error="true"] {
  border-color: red !important;
}
</style>
