<template>
  <main id="rDomain">
    <center>
      <form class="row" @submit.prevent="validate">
        <h4 class="text-center mb-3">
          Modification des contacts du domaine (.bf)
        </h4>
        <div class="rDomaintitle">
          <h5>Information sur le nom de domaine</h5>
        </div>
        <!--  -->
        <div class="row">
          <div class="form-floating mb-3 p-1">
            <input
              type="text"
              id="rDomainDname"
              spellcheck="false"
              class="form-control"
              :value="domainName"
              disabled
              required
              readonly
            />
            <label for="rDomainDname">Nom de domaine</label>
          </div>
        </div>

        <!-- ////DESACTIVE POUR VERIFICATION AVEC L'ABDI
          
        <div class="mt-4 rDomaintitle">
          <h5>Information sur le demandeur</h5>
        </div>
        
        <div class="row oneRow">
          <div class="form-floating mb-3 p-1">
            <input type="text" id="rDomainFname1" spellcheck="false" class="form-control" placeholder="Krepin Jhon"
              :value="data == null ? '' : data.registrant.name" @input="resetInput" required>
            <label for="rDomainFname1">Nom/Prénoms ou organisation</label>
          </div>

          <div class="form-floating mb-3 p-1">
            <input type="email" id="rDomainEmail1" class="form-control" placeholder="krepin@jhon.com"
              :value="data == null ? '' : data.registrant.email" required>
            <label for="rDomainEmail1">Adresse Email</label>
          </div>
        </div>
        
        <div class="row oneRow">
          <div class="form-floating mb-3 p-1">
            <CountryList id="rDomainCountry1"></CountryList>
            <label for="rDomainCountry1">Pays</label>
          </div>

          <div class="form-floating mb-3 p-1">
            <input type="text" id="rDomainCity1" class="form-control" placeholder="Ouagadougou"
              :value="data == null ? '' : data.registrant.city" v-on:input="resetInput" required>
            <label for="rDomainCity1">Ville</label>
          </div>
        </div>
        
        <div class="row oneRow">
          <div class="form-floating mb-3 p-1">
            <input type="text" id="rDomainAddress1" class="form-control" placeholder="Ouaga 2000"
              :value="data == null ? '' : data.registrant.address" v-on:input="resetInput" required>
            <label for="rDomainAddress1">Adresse</label>
          </div>

          <div class="form-floating mb-3 p-1">
            <input type="number" id="rDomainPostalCode1" class="form-control" placeholder=""
              :value="data == null ? '' : data.registrant.zipcode" v-on:input="resetInput" required>
            <label for="rDomainPostalCode1">Code postal</label>
          </div>
        </div>
        
        <div class="row oneRow">
          <div class="form-floating mb-3">
            <a style="font-size:14px">Numéro de téléphone</a>
            <br />
            <input type="tel" id="rDomainTel1" class="form-control rDomainTel"
              :value="data == null ? '' : data.registrant.voice.replace('.', '')" @input="resetInput" required>
          </div>
        </div>
         -->

        <div class="d-flex justify-content-between mb-2 mt-4 rDomaintitle">
          <h5 class="d-block float-start me-3 mt-2">
            Information sur le technicien
          </h5>
          <!-- <button
            type="button"
            class="btn btn-primary w-auto"
            @click="fillInput(2)"
          >
            <a>{{ repeatDataText }}</a
            ><i class="bi bi-arrow-down ms-2"></i>
          </button> -->
        </div>

        <div class="row oneRow">
          <div class="form-floating mb-3 p-1">
            <input
              type="text"
              id="rDomainFname2"
              spellcheck="false"
              class="form-control"
              placeholder="Krepin Jhon"
              @input="resetInput"
              :value="data == null ? '' : data.contacts.tech.name"
              required
            />
            <label for="rDomainFname2">Nom/Prénoms ou organisation</label>
          </div>

          <div class="form-floating mb-3 p-1">
            <input
              type="email"
              id="rDomainEmail2"
              class="form-control"
              placeholder="krepin@jhon.com"
              :value="data == null ? '' : data.contacts.tech.email"
              required
            />
            <label for="rDomainEmail2">Adresse Email</label>
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating mb-3 p-1">
            <CountryList id="rDomainCountry2"></CountryList>
            <label for="rDomainCountry3">Pays</label>
          </div>

          <div class="form-floating mb-3 p-1">
            <input
              type="text"
              id="rDomainCity2"
              class="form-control"
              placeholder="Ouagadougou"
              :value="data == null ? '' : data.contacts.tech.city"
              v-on:input="resetInput"
              required
            />
            <label for="rDomainCity2">Ville</label>
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating mb-3 p-1">
            <input
              type="text"
              id="rDomainAddress2"
              class="form-control"
              placeholder="Ouaga 2000"
              :value="data == null ? '' : data.contacts.tech.address"
              v-on:input="resetInput"
              required
            />
            <label for="rDomainAddress2">Adresse</label>
          </div>

          <div class="form-floating mb-3 p-1">
            <input
              type="number"
              id="rDomainPostalCode2"
              class="form-control"
              placeholder=""
              :value="data == null ? '' : data.contacts.tech.zipcode"
              v-on:input="resetInput"
              required
            />
            <label for="rDomainPostalCode2">Code postal</label>
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating mb-3">
            <a style="font-size: 14px">Numéro de téléphone</a>
            <br />
            <input
              type="tel"
              id="rDomainTel2"
              class="form-control rDomainTel"
              :value="
                data == null ? '' : data.contacts.tech.voice.replace('.', '')
              "
              @input="resetInput"
              required
            />
          </div>
        </div>

        <div class="d-flex mb-2 mt-4 rDomaintitle justify-content-between">
          <h5 class="d-block float-start me-3 mt-2">
            Information sur l'administrateur
          </h5>
          <button
            type="button"
            class="btn btn-primary w-auto"
            @click="fillInput(3)"
          >
            <a>{{ repeatDataText }}</a
            ><i class="bi bi-arrow-down ms-2"></i>
          </button>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating mb-3 p-1">
            <input
              type="text"
              id="rDomainFname3"
              spellcheck="false"
              class="form-control"
              placeholder="Krepin Jhon"
              :value="data == null ? '' : data.contacts.admin.name"
              @input="resetInput"
              required
            />
            <label for="rDomainFname3">Nom/Prénoms ou organisation</label>
          </div>

          <div class="form-floating mb-3 p-1">
            <input
              type="email"
              id="rDomainEmail3"
              class="form-control"
              placeholder="krepin@jhon.com"
              :value="data == null ? '' : data.contacts.admin.email"
              required
            />
            <label for="rDomainEmail3">Adresse Email</label>
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating mb-3 p-1">
            <CountryList id="rDomainCountry3"></CountryList>
            <label for="rDomainCountry3">Pays</label>
          </div>

          <div class="form-floating mb-3 p-1">
            <input
              type="text"
              id="rDomainCity3"
              class="form-control"
              placeholder="Ouagadougou"
              v-on:input="resetInput"
              :value="data == null ? '' : data.contacts.admin.city"
              required
            />
            <label for="rDomainCity3">Ville</label>
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating mb-3 p-1">
            <input
              type="text"
              id="rDomainAddress3"
              class="form-control"
              placeholder="Ouaga 2000"
              :value="data == null ? '' : data.contacts.admin.address"
              v-on:input="resetInput"
              required
            />
            <label for="rDomainAddress3">Adresse</label>
          </div>

          <div class="form-floating mb-3 p-1">
            <input
              type="number"
              id="rDomainPostalCode3"
              class="form-control"
              placeholder="Ouaga 2000"
              :value="data == null ? '' : data.contacts.admin.zipcode"
              v-on:input="resetInput"
              required
            />
            <label for="rDomainPostalCode3">Code postal</label>
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating mb-3">
            <a style="font-size: 14px">Numéro de téléphone</a>
            <br />
            <input
              type="tel"
              id="rDomainTel3"
              class="form-control rDomainTel"
              :value="
                data == null ? '' : data.contacts.admin.voice.replace('.', '')
              "
              @input="resetInput"
              required
            />
          </div>
        </div>

        <div class="d-flex mb-2 mt-4 rDomaintitle justify-content-between">
          <h5 class="d-block float-start me-3 mt-2">
            Information de facturation
          </h5>
          <button
            type="button"
            class="btn btn-primary w-auto"
            @click="fillInput(4)"
          >
            <a>{{ repeatDataText }}</a
            ><i class="bi bi-arrow-down ms-2"></i>
          </button>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating mb-3 p-1">
            <input
              type="text"
              id="rDomainFname4"
              spellcheck="false"
              class="form-control"
              placeholder="Krepin Jhon"
              :value="data == null ? '' : data.contacts.billing.name"
              @input="resetInput"
              required
            />
            <label for="rDomainFname4">Nom/Prénoms ou organisation</label>
          </div>

          <div class="form-floating mb-3 p-1">
            <input
              type="email"
              id="rDomainEmail4"
              class="form-control"
              placeholder="krepin@jhon.com"
              :value="data == null ? '' : data.contacts.billing.email"
              required
            />
            <label for="rDomainEmail4">Adresse Email</label>
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating mb-3 p-1">
            <CountryList id="rDomainCountry4"></CountryList>
            <label for="rDomainCountry4">Pays</label>
          </div>

          <div class="form-floating mb-3 p-1">
            <input
              type="text"
              id="rDomainCity4"
              class="form-control"
              placeholder="Ouagadougou"
              @input="resetInput"
              :value="data == null ? '' : data.contacts.billing.city"
              required
            />
            <label for="rDomainCity4">Ville</label>
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating mb-3 p-1">
            <input
              type="text"
              id="rDomainAddress4"
              class="form-control"
              placeholder="Ouaga 2000"
              :value="data == null ? '' : data.contacts.billing.address"
              v-on:input="resetInput"
              required
            />
            <label for="rDomainAddress4">Adresse</label>
          </div>

          <div class="form-floating mb-3 p-1">
            <input
              type="number"
              id="rDomainPostalCode4"
              class="form-control"
              placeholder="Ouaga 2000"
              @input="resetInput"
              :value="data == null ? '' : data.contacts.billing.zipcode"
              required
            />
            <label for="rDomainPostalCode4">Code postal</label>
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating mb-3">
            <a style="font-size: 14px">Numéro de téléphone</a>
            <br />
            <input
              type="tel"
              id="rDomainTel4"
              class="form-control rDomainTel"
              :value="
                data == null ? '' : data.contacts.billing.voice.replace('.', '')
              "
              @input="resetInput"
              required
            />
          </div>
        </div>
        <!--  -->
        <!--  -->
        <!--  -->
        <!--  -->
        <!--  -->
        <div class="col-12 d-flex justify-content-center">
          <button type="submit">
            SOUMETTRE LES INFORMATIONS
            <i class="bi bi-check-lg"></i>
          </button>
        </div>
      </form>
    </center>
  </main>
</template>

<script>
const $ = require("jquery");
import Globals from "/src/common/js/Globals";
import waitLoader from "/src/common/js/waitLoader";
// import functions from "/src/common/js/functions";
import axios from "axios";
import popup from "/src/common/js/popup";
import CountryList from "/src/components/countryList.vue";
//
import * as localforage from "localforage";
import intlTelInput from "intl-tel-input";
//
import { mapStores } from "pinia";
import { useUsersStore } from "/src/common/stores/users.js";

//login component
export default {
  computed: {
    ...mapStores(useUsersStore),
  },

  components: {
    CountryList,
  },

  data() {
    return {
      repeatDataText: "Repéter les informations",
      //
      pageName: "Modification des contacts du domaine (.bf)",
      hasEnterprise: false,
      //
      data: null,
      //
      intTel1: null,
      intTel2: null,
      intTel3: null,
      intTel4: null,
      //
      domainName: "---",
    };
  },

  mounted() {
    //set the routeName
    $("#routeName")[0].innerHTML = this.pageName;
    //
    //
    //
    localforage
      .getItem("ecocloud/User")
      .then(async (result) => {
        let jsData = result;
        if (jsData == "" || jsData == "{}" || jsData == null) {
          this.$router.replace("/login");
          return;
        }
        try {
          jsData = JSON.parse(jsData);
        } catch (e) {
          this.$router.replace("/login");
          return;
        }
        //
        this.usersStore.User = jsData;
        //
      })
      .catch((err) => {
        console.log(err);
        // This code runs if there were any errors.
        this.$router.replace("/client/domains");
      });
    //
    //
    // this.intTel1 = intlTelInput($("#rDomainTel1")[0], {
    //   // any initialisation options go here
    //   initialCountry: "bf",
    //   preferredCountries: ["bf", "ci"],
    //   utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
    // });
    this.intTel2 = intlTelInput($("#rDomainTel2")[0], {
      // any initialisation options go here
      initialCountry: "bf",
      preferredCountries: ["bf", "ci"],
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
    });
    this.intTel3 = intlTelInput($("#rDomainTel3")[0], {
      // any initialisation options go here
      initialCountry: "bf",
      preferredCountries: ["bf", "ci"],
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
    });
    this.intTel4 = intlTelInput($("#rDomainTel4")[0], {
      // any initialisation options go here
      initialCountry: "bf",
      preferredCountries: ["bf", "ci"],
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
    });
    //
    //
    this.__update();
  },

  methods: {
    __update() {
      waitLoader.show();
      //
      setTimeout(() => {
        this.update();
      }, 1);
    },

    update() {
      setTimeout(() => {
        //
        this.domainName = this.usersStore.User.updateDomain;
      }, 1000);
      //
      //
      this.getUserData();
    },

    async getUserData() {
      try {
        //get All user data in json format
        const resp = await axios.get(
          Globals.website_url +
            "php/api.php?query=get-domain-infos/" +
            this.usersStore.User.updateDomain
        );
        let h = resp.data;

        if (h == "") {
          waitLoader.hide();
          //say error
          popup.action = "Notification";
          popup.setTitle("Notification");
          popup.setMessage(
            "Erreur de récupération des données. Veuillez actualiser la page"
          );
          popup.setButton("Ok");
          popup.show();
          return;
        }

        h = JSON.parse(h);

        if (!h.success) {
          waitLoader.hide();
          //say error
          popup.action = "Notification";
          popup.setTitle("Notification");
          popup.setMessage(
            "Erreur de récupération des données. Veuillez actualiser la page"
          );
          popup.setButton("Ok");
          popup.show();
          return;
        }

        this.data = h.data;
        // console.log(this.data);

        //set user country
        // $("#rDomainCountry1")[0].value = this.data.registrant.countrycode;
        $("#rDomainCountry2")[0].value = this.data.contacts.tech.countrycode;
        $("#rDomainCountry3")[0].value = this.data.contacts.admin.countrycode;
        $("#rDomainCountry4")[0].value = this.data.contacts.billing.countrycode;
        //
        waitLoader.hide();
        //
      } catch (err) {
        // Handle Error Here
        waitLoader.hide();
        //say error
        popup.action = "Notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "Erreur de récupération des données. Veuillez actualiser la page"
        );
        popup.setButton("Ok");
        popup.show();
      }
    },

    fillInput(index) {
      $("#rDomainFname" + index).val($("#rDomainFname" + (index - 1)).val());
      $("#rDomainEmail" + index).val($("#rDomainEmail" + (index - 1)).val());
      //
      $("#rDomainTel" + index).val($("#rDomainTel" + (index - 1)).val());
      //
      $("#rDomainCountry" + index).val(
        $("#rDomainCountry" + (index - 1)).val()
      );
      $("#rDomainCity" + index).val($("#rDomainCity" + (index - 1)).val());
      $("#rDomainPostalCode" + index).val(
        $("#rDomainPostalCode" + (index - 1)).val()
      );
    },

    validate() {
      if (this.data == null) {
        //say error
        popup.action = "Notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "Impossible de modifier les contacts de ce domaine!<br>Veuillez choisir un autre nom de domaine."
        );
        popup.setButton("Ok");
        popup.show();
        return;
      }
      //
      //
      //
      waitLoader.show();
      //
      let data = {
        Domain: this.domainName,
      };
      //
      let $self = this;
      //
      function dataFy1() {
        data["Fname1"] = $self.data.registrant.name;
        data["Email1"] = $self.data.registrant.email;
        data["Tel1"] = $self.data.registrant.voice;
        data["Country1"] = $self.data.registrant.countrycode;
        data["City1"] = $self.data.registrant.city;
        data["PostalCode1"] = $self.data.registrant.zipcode;
        data["Address1"] = $self.data.registrant.address;
      }
      //
      function dataFy(index) {
        data["Fname" + index] = $("#rDomainFname" + index).val();
        data["Email" + index] = $("#rDomainEmail" + index).val();
        //
        let dc = $self["intTel" + index].getSelectedCountryData().dialCode;
        let num = $self["intTel" + index].getNumber();
        num = num.substr(dc.length + 1, num.length);
        data["Tel" + index] = "+" + dc + "." + num;
        //
        data["Country" + index] = $("#rDomainCountry" + index).val();
        data["City" + index] = $("#rDomainCity" + index).val();
        data["PostalCode" + index] = $("#rDomainPostalCode" + index).val();
        data["Address" + index] = $("#rDomainAddress" + index).val();
      }
      dataFy1();
      dataFy(2);
      dataFy(3);
      dataFy(4);
      //
      //
      axios
        .post(
          Globals.website_url + "php/domain/updateDomainContactsEpp.php",
          JSON.stringify(data)
        )
        .then((response) => {
          let h = response.data;

          //on error
          if (h != 1) {
            waitLoader.hide();
            //say error
            popup.action = "Notification";
            popup.setTitle("Notification");
            popup.setMessage(
              "Erreur d'enregistrement des informations!<br>Veuillez vérifier vos informations puis reéssayer."
            );
            popup.setButton("Ok");
            popup.show();
            return;
          }
          //
          waitLoader.hide();
          //on success
          popup.action = "contacts-ok";
          popup.setTitle("Notification");
          popup.setMessage("Contacts modifiés avec succès!");
          popup.setButton("Ok");
          popup.show();
          //
          $("#popupYes").click(() => {
            if (popup.action != "contacts-ok") {
              return;
            }
            //
            this.$router.push("/client/domains");
          });
          //
        })
        .catch((error) => {
          //
          console.log(error);
          waitLoader.hide();
          //say error
          popup.action = "notification";
          popup.setTitle("Notification");
          popup.setMessage("Problème de connexion!<br>Veuillez reéssayer.");
          popup.setButton("Ok");
          popup.show();
        });
    },
    //
  },
};
</script>

<style scoped>
@import "intl-tel-input/build/css/intlTelInput.css";

#rDomain {
  padding: 24px 12px;
  position: relative;
}

/*----------------------------------
USER DATA EDIT
----------------------------------*/
#rDomain form {
  max-width: 800px;
  background-color: #f2fbff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  text-align: left;
  color: #111a2b;
  padding: 28px;
}

#rDomain form input[readonly] {
  background-color: #f8f8f8;
  color: #888;
}

#rDomain form select {
  /**/
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("/src/assets/images/select-arrow.svg");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  background-color: white;
}

#rDomain form button[type="submit"] {
  margin-left: 12px;
  border: none;
  border-radius: 3px;
  padding: 8px 24px;
  width: auto;
  box-sizing: content-box;
  color: white;
  background-color: #111a2b;
  font-size: 17px;
}

#rDomain form button[type="submit"]:hover {
  color: white;
  background-color: #1b2842;
}

#rDomain form button[type="submit"] i {
  color: rgba(123, 255, 0);
  font-size: 16px;
}

#rDomain form button[type="submit"]:hover {
  background-color: #346;
}

.rDomaintitle {
  border-bottom: solid 1px black;
  padding-bottom: 8px;
  margin-bottom: 16px;
}

.rDomaintitle button {
  float: left !important;
}

.form-floating label {
  opacity: 0.5;
}

.rDomainTel {
  width: 344px !important;
}

#rDomain .oneRow div {
  display: inline !important;
  width: 50% !important;
}

#rDomain .oneRow3 div:first-child {
  display: inline !important;
  width: 40% !important;
}

#rDomain .oneRow3 div:nth-child(2) {
  display: inline !important;
  width: 25% !important;
}

#rDomain .oneRow3 div:last-child {
  display: inline !important;
  width: 35% !important;
}

#rDomain .oneRowItem div {
  display: inline !important;
  width: 100% !important;
}
</style>
./EditDomainContactBF.vue
