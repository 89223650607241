import OneSignal from "./OneSignal";

const __env__ = "prod";
const __dev_website_url__ = "http://client.ecodev.africa/";
const __prod_website_url__ = "https://client.ecodev.dev/";

var globals = {
    website_url: __env__ == "dev" ? __dev_website_url__ : __prod_website_url__,
    eppClient_url: "http://gateway.upay-bf.com/",
    upay_url: "https://gateway.upay-bf.com:3005/v1",

    ns: [
        {
            name: "ns1.ecodev.africa",
            ip: "13.248.158.180",
        },
        {
            name: "ns2.ecodev.africa",
            ip: "75.2.118.134",
        },
        {
            name: "ns3.ecodev.africa",
            ip: "76.223.26.245",
        },
        {
            name: "ns4.ecodev.africa",
            ip: "99.83.147.209",
        }
    ],

    clientMain: null,
    ClientleftMenu: null,
    clientHome: null,

    clientMainIsOk: false,
    phoneNumberConfirm: null,

    domain: {
        name: "",
        price: 0,
        reab: 0,
    },

    finalStep: false,


    AppVersion: "2024.1.0",
    //
    rDomainBF: {},
    //
    deferredPrompt: null,
    //
    chatFetchTime: 5000,
    notifsFetchTime: 5000,
    //
    notifPlayerID: null,
}

globals.cinet_notify_url = globals.website_url + "php/subscriptionOrderCardPayment.php";
globals.cinet_return_url = globals.website_url + "#/client/Subscriptions/services";
globals.cinet_apikey = "108320773565f879e7749091.94478800";

export default globals;