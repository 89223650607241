<template>
  <div id="bg">
    <PayDetails></PayDetails>
  </div>
</template>

<script>
const $ = require("jquery");
import waitLoader from "/src/common/js/waitLoader";
//
import PayDetails from "/src/components/PayDetails.vue";

//confirmOrder component
export default {
  components: {
    PayDetails,
  },

  data() {
    return {};
  },

  mounted() {
    waitLoader.show();
  },

  methods: {},
};
</script>

<style scoped>
#bg {
  background: url("/src/assets/images/app-bg.jpg") no-repeat center center fixed;
  background-size: cover;
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
}

/*-------------------------------
ConfirmOrder
-------------------------------*/
#confirmOrder {
  max-width: 840px;
  width: 100%;
  margin: auto;
  font-size: 16px;
}

.confirmOrderBox {
  width: 100%;
  margin: auto;
  background-color: #f2fbff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  height: max-content;
  padding: 28px;
  color: #111a2b;
  text-align: left;
  margin-bottom: 32px;
}

.confirmOrderTitle {
  color: orange !important;
}

.confirmOrderItems {
  margin-top: 8px;
  width: 100%;
  height: max-content;
}

.confirmOrderItems {
  margin-bottom: 32px;
}

.confirmOrderItem:nth-child(odd) {
  background-color: white;
}

.confirmOrderItem a {
  color: black;
}

.confirmOrderItem p {
  overflow: auto;
  max-height: 128px;
}

#confirmOrderPayNumber {
  padding: 0 14px;
  height: 68px;
}

.confirmOrderItemBackBtn i {
  font-size: 20px;
  margin-top: -3px;
  float: right;
}

.confirmOrderItemValidateBtn i {
  font-size: 20px;
  margin-top: -3px;
  float: right;
}

@media only screen and (max-width: 316px) {
}

.paymod {
  background-color: transparent;
  margin: 0 8px;
}

.paymodbtn {
  display: flex;
  height: 80px;
  border: solid 1px #ccc;
  border-radius: 3px;
  background-color: white;
}

.paymodbtn:hover {
  background-color: orange;
  color: white;
}

.paymodbtn[selected="true"] {
  background-color: #f60;
  color: white;
}

#sellerContract {
  width: 100%;
  height: 200px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 16px;
  font-size: 13px;
  background-color: #d9edf7;
  border-radius: 6px;
  padding: 20px;
  border: none;
}

#sellerContract:focus {
  outline: none;
}
</style>
