<template>
  <header class="d-flex justify-content-between">
    <div>
      <i
        id="leftMenuToggleBtn"
        class="bi bi-list h-75"
        @click="toggleLeftMenu"
      ></i>
      <!--  -->
      <div id="headerRoute">
        <span id="headerDot" :isOnline="isOnline"> </span>
        <a id="headerSiteName">Ecoweb / </a><a id="routeName"></a>
      </div>
    </div>

    <div id="headerUserBar">
      <div id="headerUser">
        <img
          id="headerUserImg"
          class="headerUserImg"
          @click="changePhoto()"
          :src="
            user.photo_path == '' || user.photo_path == null
              ? './user-default-image.webp'
              : server_url + 'media/images/' + user.photo_path
          "
        />
        <div id="headerUserName">
          {{ user.name }}
        </div>
      </div>

      <div id="headerUserMenuBar">
        <button
          id="headerUserNotifBtn"
          :use="showNotif"
          @click="showNotif = !showNotif"
          class="headerUserMenuItem headerUserMenuCounter"
        >
          <i class="bi bi-bell-fill"></i>
          <span v-show="newNotifCount >= 1">{{
            newNotifCount <= 99 ? newNotifCount : newNotifCount + "+"
          }}</span>
        </button>
        <!--  -->
        <div id="headerNotifBox" v-show="showNotif">
          <div
            id="noNotifs"
            v-if="notifs.length == 0"
            class="w-100 d-flex justify-content-center align-items-center"
          >
            Aucune notification
          </div>
          <!--  -->
          <button
            v-for="(notif, index) in notifs"
            :key="index"
            class="headerNotifItem row border-0"
            :isNew="notif.state == 'NEW'"
            @click="gotoNotifLink(notif)"
          >
            <div class="col-2 text-center">
              <img
                :src="notif.icon"
                width="40"
                height="40"
                class="rounded-circle bg-secondary"
              />
            </div>
            <div class="col-10 text-start">
              <div class="text-truncate fw-bold">{{ notif.title }}</div>
              <div class="text-truncate">{{ notif.content }}</div>
            </div>
          </button>
        </div>
      </div>

      <div id="headerUserMenuBtns">
        <div id="headerUserLogout" class="headerUserMenuBtn" @click="logout">
          <i class="bi bi-box-arrow-right"></i>
          <span> Se déconnecter</span>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import $ from "jquery";
import axios from "axios";
//
import Globals from "/src/common/js/Globals";
import popup from "/src/common/js/popup";
import OneSignal from "/src/common/js/OneSignal";
//
import localforage from "localforage";
import moment from "moment";
//
import { mapStores } from "pinia";
import { useUsersStore } from "/src/common/stores/users.js";

//login component
export default {
  computed: {
    ...mapStores(useUsersStore),
  },

  data() {
    return {
      user: {},
      server_url: "",
      isOnline: true,
      //
      showNotif: false,
      notifs: [],
      newNotifCount: 0,
      //
      hasPlayNotifSound: false,
      notifyAudio: null,
    };
  },

  mounted() {
    this.server_url = Globals.website_url;
    //
    document.addEventListener("clientMainIsReady", () => {
      this.__update();
    });
    if (Globals.clientMainIsOk) {
      this.__update();
    }
    //
    //
    this.notifyAudio = new Audio(Globals.website_url + "notify.wav");
  },

  methods: {
    __update() {
      this.user = this.usersStore.User.data;
      $("#headerUserName")[0].innerHTML = this.usersStore.User.data.fname;
      this.update();
      this.setState();
    },

    update() {
      this.syncNotifications();
    },

    changePhoto() {
      this.$router.push("/client-change-photo");
      // OneSignal.sendNotification(window.notifPlayerID, "Test de notif from Ecoweb with Onesignal");
    },

    correctDate: function (dt) {
      return moment(dt).format("DD-MM-YYYY");
    },

    correctTime: function (dt) {
      let H = moment(dt).hours();
      let I = moment(dt).minutes();
      let S = moment(dt).seconds();
      return (
        (H < 10 ? "0" + H : H) +
        ":" +
        (I < 10 ? "0" + I : I) +
        ":" +
        (S < 10 ? "0" + S : S)
      );
    },

    correctDateTime: function (dt) {
      return this.correctDate(dt) + " " + this.correctTime(dt);
    },

    syncNotifications() {
      this.hasPlayNotifSound = false;
      this.getNotificationsFromDatabase();
    },

    playNotifySound() {
      this.notifyAudio.play();
    },

    async getNotificationsFromDatabase() {
      //
      try {
        const resp = await axios.get(
          Globals.website_url +
            "php/api.php?query=get-user-notifications/1/" +
            this.usersStore.User.account.id
        );
        this.notifs = resp.data;
        //
        this.newNotifCount = 0;

        this.notifs.forEach((notif) => {
          if (notif.user_from == "-1") {
            notif.title = "System";
          } else {
            notif.title = notif.user_from_fname;
          }

          if (notif.state == "NEW") {
            this.newNotifCount++;
            //-----------------------------------------------------
            // Compare notify created datetime to current datetime
            //-----------------------------------------------------
            let dt1 = moment(notif.created_at).utc();
            dt1 = dt1.add(notif.timezone * -1, "seconds");
            dt1 = moment(dt1.format());
            // Get the current date and time in UTC
            let dtUTC = moment.utc();
            // Calculer la différence en secondes
            let diffSecs = dtUTC.diff(dt1, "seconds");
            //
            if (
              !this.hasPlayNotifSound &&
              diffSecs < Globals.notifsFetchTime / 1000 &&
              this.$route.fullPath != "/client/Supports/chat"
            ) {
              this.playNotifySound();
              this.hasPlayNotifSound = true;
            }
          }

          //
          if (notif.type == "1") {
            //link
            notif.link = "#/client/Supports/chat";
            //icon
            if (notif.avatar != null && notif.avatar != "") {
              notif.icon = Globals.website_url + "media/images/" + notif.avatar;
            } else {
              notif.icon = Globals.website_url + "user-default-image.webp";
            }
          } else {
            notif.icon = Globals.website_url + "notif_system_icon.png";
          }
        });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
      //resync notif after each sync
      setTimeout(() => {
        this.syncNotifications();
      }, Globals.notifsFetchTime);
    },

    async gotoNotifLink(notif) {
      this.showNotif = false;
      //mark notif as seen
      const resp = await axios.get(
        Globals.website_url +
          "php/api.php?query=mark-notification-as-seen/" +
          notif.id
      );
      if (resp.data == "1") {
        this.notifs.forEach((not) => {
          if (not.id == notif.id) {
            not.state = "SEEN";
          }
        });
        //on no link
        if (notif.link == null || notif.link == "") {
          return;
        }
        //on link exists
        let a = document.createElement("a");
        a.href = notif.link;
        a.click();
      } else {
        //nothing to do here
      }
    },

    setState() {
      this.isOnline = navigator.onLine;
      //
      setTimeout(() => {
        this.setState();
      }, 100);
    },

    logout() {
      //on success
      popup.action = "logout-user";
      popup.setTitle("Question");
      popup.setMessage("Voulez-vous vraiment vous déconnecter?");
      popup.setButton("Oui", "Non");
      popup.show();
      //
      $("#popupYes").click(async () => {
        if (popup.action != "logout-user") return;
        //logout this user session
        this.usersStore.User = {
          account: {},
          theme: "default",
          confirmEmail: "",
          data: {},
        };
        //
        try {
          await localforage.setItem("ecocloud/User", JSON.stringify({}));
          location = "./";
          return;
        } catch (err) {
          // This code runs if there were any errors.
        }
        //
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage("Problème de déconnection!<br>Veuillez reéssayer.");
        popup.setButton("Ok");
        popup.show();
      });
    },

    toggleLeftMenu: function () {
      //show leftMenu for small screen
      $("#leftMenu").attr(
        "shown",
        $("#leftMenu").attr("shown") == "true" ? "false" : "true"
      );
      //call function manageLeftMenu from LeftMenu.js
      Globals.ClientleftMenu.manageLeftMenu();
    },
  },
};
</script>

<style scoped>
#header {
  width: 100%;
  height: 48px;
  background-color: #f5fafe;
  color: black;
  text-align: left;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  z-index: 15;
  transition: left 0.25s ease;
}

#leftMenuToggleBtn {
  margin-top: 6px;
  margin-left: 6px;
  float: left;
  display: none;
  color: #ccc;
  font-size: 32px;
  font-weight: bold;
  line-height: 24px;
  border: solid 2px #ccc;
  padding: 0 2px;
  transition: all 0.25s ease;
  cursor: pointer;
}

#leftMenuToggleBtn:hover {
  color: orange;
  background-color: #152642;
  border-color: #152642;
}

#headerRoute {
  float: left;
  display: block;
  padding: 11px 16px;
  color: #3a4149;
}

#headerDot {
  float: left;
  border-radius: 50%;
  background-color: #b00;
  width: 8px;
  height: 8px;
  margin: 9px 6px 0 0;
}

#headerDot[isOnline="true"] {
  background-color: limegreen;
}

#headerSiteName {
  font-size: 14px;
  color: #b2b7bb;
}

#routeName {
  font-size: 14px;
  color: #3a4149;
}

#headerUserBar {
  float: right;
  height: 100%;
}

#headerUser {
  float: left;
  padding: 8px 4px;
  height: 100%;
  border-right: solid 1px #ddd;
}

#headerUserImg:hover {
  transform: scale(1.15);
  -webkit-transform: scale(1.15);
}

.headerUserImg {
  float: left;
  width: 32px;
  height: 32px;
  border: solid 2px #333;
  cursor: pointer;
  transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
}

#headerUserName {
  float: left;
  margin: 5px 8px 4px 8px;
  font-size: 14px;
  color: #3a4149;
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#headerUserMenuBar {
  float: left;
}

.headerUserMenuItem {
  height: 48px;
  cursor: pointer;
  position: relative;
  border: none;
  background: transparent;
}

.headerUserMenuItem[use="true"] {
  background-color: rgb(255, 201, 164);
}

.headerUserMenuCounter {
  padding-left: 12px;
  padding-right: 24px;
}

.headerUserMenuItem i {
  color: #152642;
  font-size: 24px;
}

.headerUserMenuItem span {
  position: absolute;
  margin-left: -12px;
  margin-top: 4px;
  background-color: #f60;
  border-radius: 13px;
  font-size: 11px;
  color: white;
  padding: 1px 4px;
  transition: all 0.25s ease;
}

.headerUserMenuItem[use="true"] span {
  background-color: white;
  color: #f60;
}

/* .headerUserMenuItem:hover span {
  margin-left: -8px;
  margin-top: 0px;
} */

#headerNotifBox {
  position: absolute;
  top: 48px;
  right: 165px;
  width: 280px;
  max-height: 100%;
  height: 480px;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  border: solid 1px #e8e8e8;
}

#noNotifs {
  height: 100% !important;
}

.headerNotifItem {
  font-size: 13px;
  margin-left: 0;
  width: 100%;
  padding: 12px 0 0 0;
  border-bottom: solid 1px #e8e8e8 !important;
  height: max-content;
  background-color: transparent;
}

.headerNotifItem[isNew="true"] {
  background-color: rgb(255, 234, 221);
}

#headerUserMenuBtns {
  float: left;
  background-color: #1b334f;
  height: 100%;
}

.headerUserMenuBtn {
  height: 100%;
  padding: 4px 20px;
  cursor: pointer;
  transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
}

.headerUserMenuBtn:hover {
  background-color: #2a4f7b;
}

.headerUserMenuBtn i {
  color: white;
  font-size: 24px;
}

.headerUserMenuBtn span {
  color: white;
  font-size: 14px;
  position: relative;
  top: -4px;
}

/*--------------------------------
Media queries for responsive site
--------------------------------*/
@media only screen and (max-width: 1160px) and (min-width: 968px) {
  #headerUserName {
    display: none;
  }

  #headerUser {
    padding-right: 14px;
    padding-left: 14px;
    border-left: solid 1px #ddd;
  }

  #headerNotifBox {
    position: fixed !important;
    top: 48px !important;
    width: 100% !important;
    height: calc(100vh - 48px) !important;
    left: 0 !important;
    right: 0 !important;
  }
}

@media only screen and (max-width: 968px) {
  #leftMenuToggleBtn {
    display: block;
  }
}

@media only screen and (max-width: 900px) {
  #headerRoute a:nth-child(2) {
    display: none;
  }

  #headerUserName {
    display: none;
  }

  #headerUser {
    padding-right: 14px;
    padding-left: 14px;
    border-left: solid 1px #ddd;
  }

  #headerNotifBox {
    position: fixed !important;
    top: 48px !important;
    width: 100% !important;
    height: calc(100vh - 48px) !important;
    left: 0 !important;
    right: 0 !important;
  }
}

@media only screen and (max-width: 680px) {
  .headerUserMenuBtn {
    padding: 4px 16px;
  }

  .headerUserMenuBtn span {
    display: none;
  }

  #headerNotifBox {
    right: 56px;
  }

  #headerRoute {
    display: none;
  }
}
</style>
