<template>
  <div id="bg"></div>
  <div class="d-flex justify-content-center">
    <form
      @submit.prevent="
        validation = 0;
        validate();
      "
      ref="form"
    >
      <h4 class="text-center mb-3">Enregistrement de nom de domaine .bf</h4>
      <div class="text-center">
        <button
          class="btn btn-outline-primary mb-3"
          @click="gotoForm()"
          type="button"
        >
          {{
            isFullForm
              ? "Afficher le formulaire simplifié"
              : "Afficher le formulaire complet"
          }}
        </button>
      </div>

      <h5 class="text-danger fs-6 mb-3">
        Seuls les champs avec (*) sont obligatoires
      </h5>

      <div class="rDomaintitle">
        <h5>Information sur le nom de domaine</h5>
      </div>
      <!--  -->
      <div class="row oneRow">
        <div class="form-floating p-1 mb-2">
          <input
            v-if="!disableDomain"
            type="text"
            id="rDomainDname"
            spellcheck="false"
            class="form-control"
            v-model="domainName"
            placeholder="xxxx.bf"
            required
          />
          <a class="form-control text-secondary" v-else>{{ domainName }}</a>
          <label>Nom de domaine *</label>
        </div>

        <div class="text-start mb-3">
          <div id="chooseOfferDomainExists" show="false" class="w-100">
            <i class="bi bi-x-circle me-1"></i>
            <a> Nom de domaine déjà réservé.</a>
          </div>
          <div id="chooseOfferDomainNotExists" show="false" class="w-100">
            <i class="bi bi-check-circle me-1"></i>
            <a> Nom de domaine libre.</a>
          </div>
        </div>

        <button
          type="button"
          @click="__checkDomain()"
          id="chooseOfferCheckDomainBtn"
          class="btn btn-primary float-start ms-1"
        >
          Verifier le nom de Domaine
        </button>
      </div>

      <div class="mt-4 rDomaintitle">
        <h5>Information sur le demandeur</h5>
      </div>
      <!--  -->
      <div class="row oneRow">
        <div class="form-floating p-1 mb-3">
          <select
            class="form-control"
            v-model="data.natureRegister"
            @input="makeTelInput()"
            required
          >
            <option value="PERSON">Personne physique</option>
            <option value="STRUCTURE">Structure</option>
            <option value="PRESTATAIRE">Prestataire</option>
          </select>
          <label>Nature du demandeur*</label>
        </div>

        <div
          class="form-floating p-1 mb-3"
          v-if="data.natureRegister == 'PERSON'"
        >
          <input
            type="text"
            spellcheck="false"
            class="form-control"
            placeholder="Krepin Jhon"
            v-model="data.Fname1"
            title="Ce champ est requis"
            required
          />
          <label>Nom/Prénoms*</label>
        </div>

        <div class="form-floating p-1 mb-3" v-else>
          <input
            type="text"
            spellcheck="false"
            class="form-control"
            placeholder="Ecodev"
            v-model="data.organism1"
            required
          />
          <label>Organisme*</label>
        </div>
      </div>
      <!--  -->
      <div class="row oneRow" v-if="data.natureRegister != 'PERSON'">
        <div class="form-floating p-1 mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Ouaga 2000"
            v-model="data.Sigle"
          />
          <label>Sigle de l'entreprise</label>
        </div>

        <div class="form-floating p-1 mb-3">
          <input type="text" class="form-control" v-model="data.rJuridic" />
          <label>Forme Juridique (Société,Université,...)*</label>
        </div>
      </div>
      <!--  -->
      <div class="row oneRow">
        <div class="form-floating p-1 mb-3">
          <input
            type="email"
            class="form-control"
            placeholder="krepin@jhon.com"
            v-model="data.Email1"
            required
          />
          <label>Adresse Email*</label>
        </div>

        <div class="form-floating mb-3">
          <a style="font-size: 14px">Numéro de téléphone*</a>
          <br />
          <input
            id="rDomainTel1"
            type="tel"
            class="form-control rDomainTel"
            v-model="data.Tel1"
            required
          />
        </div>
      </div>
      <!--  -->
      <div class="row oneRow">
        <div class="form-floating p-1 mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Ouagadougou"
            v-model="data.rProfession"
            required
          />
          <label>Profession/Activité*</label>
        </div>

        <div class="form-floating p-1 mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Ouaga 2000"
            v-model="data.Address1"
            required
          />
          <label>Adresse*</label>
        </div>
      </div>
      <!--  -->
      <div class="row oneRow">
        <div class="form-floating p-1 mb-3">
          <select
            id="rDomainCountry1"
            v-model="data.Country1"
            class="form-control"
            required
          >
            <option value="">Aucun Pays sélectionné</option>
            <option v-for="(c, index) in cc" :key="index" :value="c.code">
              {{ c.name }}
            </option>
          </select>
          <label>Pays*</label>
        </div>

        <div class="form-floating p-1 mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Ouagadougou"
            v-model="data.City1"
            required
          />
          <label>Ville*</label>
        </div>
      </div>
      <!--  -->
      <div v-show="isFullForm" class="row oneRow">
        <div class="form-floating p-1 mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Ouaga 2000"
            v-model="data.PostalBox1"
          />
          <label>Boîte Postale</label>
        </div>

        <div class="form-floating p-1 mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Ouaga 2000"
            v-model="data.Fax1"
          />
          <label>Fax</label>
        </div>
      </div>
      <!--  -->
      <div class="row oneRow">
        <span v-show="isFullForm" class="col-6 form-floating p-1 mb-3">
          <input
            type="number"
            class="form-control"
            placeholder="Ouaga 2000"
            v-model="data.PostalCode1"
          />
          <label>Code postal*</label>
        </span>
      </div>

      <div v-if="isFullForm">
        <div
          class="d-flex justify-content-between mb-2 mt-4 rDomaintitle"
          v-show="isFullForm"
        >
          <h5 class="d-block float-start me-3 mt-2">
            Information sur le technicien
          </h5>
          <button
            v-show="isFullForm"
            type="button"
            class="btn btn-primary w-auto"
            @click="fillInput(2)"
          >
            <a>{{ repeatDataText }}</a
            ><i class="bi bi-arrow-down ms-2"></i>
          </button>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              spellcheck="false"
              class="form-control"
              placeholder="Krepin Jhon"
              v-model="data.Fname2"
            />
            <label>Nom/Prénoms*</label>
          </div>

          <div v-show="isFullForm" class="form-floating p-1 mb-3">
            <select
              id="rDomainCountry2"
              v-model="data.Country2"
              class="form-control"
              required
            >
              <option value="">Aucun Pays sélectionné</option>
              <option v-for="(c, index) in cc" :key="index" :value="c.code">
                {{ c.name }}
              </option>
            </select>
            <label>Pays*</label>
          </div>
        </div>
        <!--  -->
        <div class="row oneRow" v-show="isFullForm">
          <div class="form-floating p-1 mb-3">
            <input
              type="email"
              class="form-control"
              placeholder="krepin@jhon.com"
              v-model="data.Email2"
            />
            <label>Adresse Email*</label>
          </div>

          <div class="form-floating mb-3">
            <a style="font-size: 14px">Numéro de téléphone*</a>
            <br />
            <input
              id="rDomainTel2"
              type="tel"
              class="form-control rDomainTel"
              v-model="data.Tel2"
            />
          </div>
        </div>
        <!--  -->
        <div v-show="isFullForm" class="row oneRow">
          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="X"
              v-model="data.rStructure2"
            />
            <label>Structure</label>
          </div>

          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="X"
              v-model="data.rServiceFunction2"
            />
            <label>Service & Fonction</label>
          </div>
        </div>
        <!--  -->
        <div v-show="isFullForm" class="row oneRow">
          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Ouagadougou"
              v-model="data.City2"
            />
            <label>Ville*</label>
          </div>

          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Ouaga 2000"
              v-model="data.Address2"
            />
            <label>Adresse*</label>
          </div>
        </div>
        <!--  -->
        <div v-show="isFullForm" class="row oneRow">
          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Ouaga 2000"
              v-model="data.PostalBox2"
            />
            <label>Boîte Postale</label>
          </div>

          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Ouaga 2000"
              v-model="data.Fax2"
            />
            <label>Fax</label>
          </div>
        </div>
        <!--  -->
        <div v-show="isFullForm" class="row oneRow">
          <div class="form-floating p-1 mb-3">
            <input
              type="number"
              id="rDomainPostalCode2"
              class="form-control"
              placeholder="Ouaga 2000"
              :value="data == null ? '' : data.PostalCode2"
            />
            <label>Code postal*</label>
          </div>
        </div>
      </div>

      <div v-if="data.natureRegister != 'PERSON' || isFullForm">
        <div
          class="d-flex justify-content-between mb-2 mt-4 rDomaintitle"
          v-show="isFullForm"
        >
          <h5 class="d-block float-start me-3 mt-2">
            Information sur l'administrateur
          </h5>
          <button
            v-show="isFullForm"
            type="button"
            class="btn btn-primary w-auto"
            @click="fillInput(3)"
          >
            <a>{{ repeatDataText }}</a
            ><i class="bi bi-arrow-down ms-2"></i>
          </button>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              spellcheck="false"
              class="form-control"
              placeholder="Krepin Jhon"
              v-model="data.Fname3"
            />
            <label>Nom/Prénoms*</label>
          </div>

          <div class="form-floating p-1 mb-3">
            <select
              id="rDomainCountry3"
              v-model="data.Country3"
              class="form-control"
              required
            >
              <option value="">Aucun Pays sélectionné</option>
              <option v-for="(c, index) in cc" :key="index" :value="c.code">
                {{ c.name }}
              </option>
            </select>
            <label>Pays*</label>
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating p-1 mb-3">
            <input
              type="email"
              class="form-control"
              placeholder="krepin@jhon.com"
              v-model="data.Email3"
            />
            <label>Adresse Email*</label>
          </div>

          <div class="form-floating mb-3">
            <a style="font-size: 14px">Numéro de téléphone*</a>
            <br />
            <input
              id="rDomainTel3"
              type="tel"
              class="form-control rDomainTel"
              v-model="data.Tel3"
            />
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="X"
              v-model="data.rStructure3"
            />
            <label>Structure</label>
          </div>

          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="X"
              v-model="data.rServiceFunction3"
            />
            <label>Service & Fonction</label>
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Ouagadougou"
              v-model="data.City3"
            />
            <label>Ville*</label>
          </div>

          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Ouaga 2000"
              v-model="data.Address3"
            />
            <label>Adresse*</label>
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Ouaga 2000"
              v-model="data.PostalBox3"
            />
            <label>Boîte Postale</label>
          </div>

          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Ouaga 2000"
              v-model="data.Fax3"
            />
            <label>Fax</label>
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating p-1 mb-3">
            <input
              type="number"
              class="form-control"
              placeholder="Ouaga 2000"
              v-model="data.PostalCode3"
            />
            <label>Code postal*</label>
          </div>
        </div>
      </div>

      <div v-if="isFullForm">
        <div class="d-flex justify-content-between mb-2 mt-4 rDomaintitle">
          <h5 class="d-block float-start me-3 mt-2">
            Information de facturation
          </h5>
          <button
            v-show="isFullForm"
            type="button"
            class="btn btn-primary w-auto"
            @click="fillInput(4)"
          >
            <a>{{ repeatDataText }}</a
            ><i class="bi bi-arrow-down ms-2"></i>
          </button>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating p-1 mb-3">
            <select class="form-control" v-model="data.natureBilling">
              <option value="PERSON">Personne physique</option>
              <option value="STRUCTURE">Structure</option>
              <option value="PRESTATAIRE">Prestataire</option>
            </select>
            <label>Nature - Facturation*</label>
          </div>

          <div
            class="form-floating p-1 mb-3"
            v-if="data.natureBilling == 'PERSON'"
          >
            <input
              type="text"
              spellcheck="false"
              class="form-control"
              placeholder="Krepin Jhon"
              v-model="data.Fname4"
            />
            <label>Nom/Prénoms*</label>
          </div>

          <div class="form-floating p-1 mb-3" v-else>
            <input
              type="text"
              spellcheck="false"
              class="form-control"
              placeholder="Ecodev"
              v-model="data.organism4"
            />
            <label>Organisme*</label>
          </div>
        </div>
        <!--  -->
        <div v-show="isFullForm" class="row oneRow">
          <div class="form-floating p-1 mb-3">
            <input
              type="email"
              class="form-control"
              placeholder="krepin@jhon.com"
              v-model="data.Email4"
            />
            <label>Adresse Email*</label>
          </div>

          <div class="form-floating mb-3">
            <a style="font-size: 14px">Numéro de téléphone*</a>
            <br />
            <input
              id="rDomainTel4"
              type="tel"
              class="form-control rDomainTel"
              v-model="data.Tel4"
            />
          </div>
        </div>
        <!--  -->
        <div v-show="isFullForm" class="row oneRow">
          <div class="form-floating p-1 mb-3">
            <select
              id="rDomainCountry4"
              v-model="data.Country4"
              class="form-control"
              required
            >
              <option value="">Aucun Pays sélectionné</option>
              <option v-for="(c, index) in cc" :key="index" :value="c.code">
                {{ c.name }}
              </option>
            </select>
            <label>Pays*</label>
          </div>

          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Ouagadougou"
              v-model="data.City4"
            />
            <label>Ville*</label>
          </div>
        </div>
        <!--  -->
        <div v-show="isFullForm" class="row oneRow">
          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Ouaga 2000"
              v-model="data.PostalBox4"
            />
            <label>Boîte Postale</label>
          </div>

          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Ouaga 2000"
              v-model="data.Fax4"
            />
            <label>Fax</label>
          </div>
        </div>
        <!--  -->
        <div v-show="isFullForm" class="row oneRow">
          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Ouaga 2000"
              v-model="data.Address4"
            />
            <label>Adresse*</label>
          </div>

          <div class="form-floating p-1 mb-3">
            <input
              type="number"
              class="form-control"
              placeholder="Ouaga 2000"
              v-model="data.PostalCode4"
            />
            <label>Code postal*</label>
          </div>
        </div>
      </div>

      <!--  -->
      <!--  -->
      <!--  -->
      <div v-show="isFullForm" class="mt-4 rDomaintitle">
        <h5>Serveurs de nom de domaine</h5>
        <h5 class="text-danger fs-6">
          Par défaut ce sont les dns de Ecodev qui sont renseigné. Si vous avez
          un hébergeur autre, vous devez renseigner ses dns.
        </h5>
      </div>
      <!--  -->
      <div v-show="isFullForm" class="row oneRow3">
        <div class="noRow" :error="dnsErr[0]">
          <div class="form-floating p-1">
            <input
              type="text"
              class="form-control"
              v-model="data.rServer_1"
              required
              @input="checkDnsInput()"
            />
            <label>Serveur 1*</label>
          </div>
          <div class="uniqueError">
            <i class="text-danger bi bi-info-circle-fill me-1"></i>
            <a class="text-danger">Vous avez déjà entré ce nom de serveur</a>
          </div>
        </div>

        <div class="form-floating p-1">
          <input
            type="text"
            class="form-control"
            v-model="data.rIPv4_1"
            required
          />
          <label>Adresse IPv4*</label>
        </div>

        <div class="form-floating p-1">
          <input type="text" class="form-control" v-model="data.rIPv6_1" />
          <label>Adresse IPv6</label>
        </div>
      </div>

      <div v-show="isFullForm" class="row oneRow3">
        <div class="noRow" :error="dnsErr[1]">
          <div class="form-floating p-1">
            <input
              type="text"
              class="form-control"
              v-model="data.rServer_2"
              required
              @input="checkDnsInput()"
            />
            <label>Serveur 2*</label>
          </div>
          <div class="uniqueError">
            <i class="text-danger bi bi-info-circle-fill me-1"></i>
            <a class="text-danger">Vous avez déjà entré ce nom de serveur</a>
          </div>
        </div>

        <div class="form-floating p-1">
          <input
            type="text"
            class="form-control"
            v-model="data.rIPv4_2"
            required
          />
          <label>Adresse IPv4*</label>
        </div>

        <div class="form-floating p-1">
          <input
            type="text"
            id="rIPv6_2"
            class="form-control"
            v-model="data.rIPv6_2"
          />
          <label>Adresse IPv6</label>
        </div>
      </div>

      <div v-show="isFullForm" class="row oneRow3">
        <div class="noRow" :error="dnsErr[2]">
          <div class="form-floating p-1">
            <input
              type="text"
              class="form-control"
              v-model="data.rServer_3"
              @input="checkDnsInput()"
            />
            <label>Serveur 3</label>
          </div>
          <div class="uniqueError">
            <i class="text-danger bi bi-info-circle-fill me-1"></i>
            <a class="text-danger">Vous avez déjà entré ce nom de serveur</a>
          </div>
        </div>

        <div class="form-floating p-1">
          <input type="text" class="form-control" v-model="data.rIPv4_3" />
          <label>Adresse IPv4</label>
        </div>

        <div class="form-floating p-1">
          <input type="text" class="form-control" v-model="data.rIPv6_3" />
          <label>Adresse IPv6</label>
        </div>
      </div>

      <div v-show="isFullForm" class="row oneRow3">
        <div class="noRow" :error="dnsErr[3]">
          <div class="form-floating p-1">
            <input
              type="text"
              class="form-control"
              v-model="data.rServer_4"
              @input="checkDnsInput()"
            />
            <label>Serveur 4</label>
          </div>
          <div class="uniqueError">
            <i class="text-danger bi bi-info-circle-fill me-1"></i>
            <a class="text-danger">Vous avez déjà entré ce nom de serveur</a>
          </div>
        </div>

        <div class="form-floating p-1">
          <input
            type="text"
            class="form-control"
            placeholder="X"
            v-model="data.rIPv4_4"
          />
          <label>Adresse IPv4</label>
        </div>

        <div class="form-floating p-1">
          <input
            type="text"
            class="form-control"
            placeholder="X"
            v-model="data.rIPv6_4"
          />
          <label>Adresse IPv6</label>
        </div>
      </div>

      <!--  -->
      <!--  -->
      <!--  -->
      <div v-show="isFullForm" class="mt-4 rDomaintitle">
        <h5>Serveurs de nom de domaine pour les zones inverses</h5>
      </div>
      <!--  -->
      <div v-show="isFullForm" class="row oneRow3">
        <div class="noRow" :error="zoneInvErr[0]">
          <div class="form-floating p-1">
            <input
              type="text"
              class="form-control"
              v-model="data.zServer_1"
              @input="checkZoneInvInput"
            />
            <label>Serveur 1</label>
          </div>
          <div class="uniqueError">
            <i class="text-danger bi bi-info-circle-fill me-1"></i>
            <a class="text-danger">Vous avez déjà entré ce nom de serveur</a>
          </div>
        </div>

        <div class="form-floating p-1">
          <input type="text" class="form-control" v-model="data.zIPv4_1" />
          <label>Adresse IPv4</label>
        </div>

        <div class="form-floating p-1">
          <input type="text" class="form-control" v-model="data.zIPv6_1" />
          <label>Adresse IPv6</label>
        </div>
      </div>

      <div v-show="isFullForm" class="row oneRow3">
        <div class="noRow" :error="zoneInvErr[1]">
          <div class="form-floating p-1">
            <input
              type="text"
              class="form-control"
              v-model="data.zServer_2"
              @input="checkZoneInvInput"
            />
            <label>Serveur 2</label>
          </div>
          <div class="uniqueError">
            <i class="text-danger bi bi-info-circle-fill me-1"></i>
            <a class="text-danger">Vous avez déjà entré ce nom de serveur</a>
          </div>
        </div>

        <div class="form-floating p-1">
          <input type="text" class="form-control" v-model="data.zIPv4_2" />
          <label>Adresse IPv4</label>
        </div>

        <div class="form-floating p-1">
          <input type="text" class="form-control" v-model="data.zIPv6_2" />
          <label>Adresse IPv6</label>
        </div>
      </div>
      <!--  -->
      <!--  -->
      <!--  -->

      <div
        v-show="isFullForm"
        class="justify-content-between mb-2 mt-4 rDomaintitle"
      >
        <h5>Délégation de gestion</h5>
      </div>
      <!--  -->
      <div v-show="isFullForm" class="row oneRow">
        <div class="form-floating p-1 mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Ouaga 2000"
            v-model="data.OldPrest"
          />
          <label>Ancien Prestataire</label>
        </div>

        <div class="form-floating p-1 mb-3">
          <input
            type="text"
            class="form-control"
            placeholder="Ouaga 2000"
            v-model="data.NewPrest"
          />
          <label>Prestataire</label>
        </div>
      </div>

      <!--  -->
      <!--  -->
      <!--  -->

      <div v-show="data.natureRegister == 'STRUCTURE'" class="mb-3">
        <div class="d-flex justify-content-between mb-2 mt-4 rDomaintitle">
          <h5 class="d-block float-start me-3 mt-2">
            STRUCTURE - PIECE A FOURNIR
          </h5>
        </div>
        <!--  -->
        <div class="row">
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="exampleRadios"
              value="RCCM"
              checked
              v-model="data.cDocs"
            />
            <label class="form-check-label">
              Extrait du régistre de commerce et de crédit mobilier (RCCM)
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="exampleRadios"
              value="IFU"
              v-model="data.cDocs"
            />
            <label class="form-check-label">
              Identifiant Fiscal Unique (IFU)
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="exampleRadios"
              value="RECEPISSE"
              v-model="data.cDocs"
            />
            <label class="form-check-label">
              Autre Document prouvant l'existence (ex: Recepicé...)
            </label>
          </div>
        </div>
      </div>

      <!--  -->
      <!--  -->
      <!--  -->

      <div class="col-12 d-flex justify-content-center">
        <button type="submit" v-if="!hasGotPDF" class="btn btn-primary">
          GENERER LE FORMULAIRE
          <i class="bi bi-filetype-pdf fs-5"></i>
        </button>

        <button
          type="button"
          v-else
          class="btn btn-primary"
          @click="
            validation = 1;
            validate();
          "
        >
          VALIDER ET CONTINUER
          <i class="bi bi-check-lg fs-5"></i>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
//
import countryList from "/src/components/countryList.json";
//
import waitLoader from "/src/common/js/waitLoader";
import Globals from "/src/common/js/Globals";
import functions from "/src/common/js/functions";
//
const $ = require("jquery");
import axios from "axios";
import popup from "/src/common/js/popup";
//
import * as localforage from "localforage";
import intlTelInput from "intl-tel-input";
import utilsScript from "intl-tel-input/build/js/utils.js";

//login component
export default {
  components: {},

  data() {
    return {
      cc: countryList,
      repeatDataText: "Repéter les informations",
      //
      pageName: "Enregistrer son nom de domaine (.bf)",
      //
      historyData: {},
      data: {
        Country1: "",
        Country2: "",
        Country3: "",
        Country4: "",
        natureRegister: "PERSON",
        natureBilling: "PERSON",
        cDocs: "RCCM",
        rServer_1: Globals.ns[0].name,
        rIPv4_1: Globals.ns[0].ip,
        rServer_2: Globals.ns[1].name,
        rIPv4_2: Globals.ns[1].ip,
        rServer_3: Globals.ns[2].name,
        rIPv4_3: Globals.ns[2].ip,
        rServer_4: Globals.ns[3].name,
        rIPv4_4: Globals.ns[3].ip,
        noPaymentAccess: false,
      },
      //
      isFullForm: false,
      validation: 0,
      //
      intTel1: null,
      intTel2: null,
      intTel3: null,
      intTel4: null,
      //
      domainName: "",
      disableDomain: false,
      npa_hash: null,
      //
      hasGotPDF: false,
      //
      dnsErr: [false, false, false, false],
      zoneInvErr: [false, false],
    };
  },

  created() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.isFullForm = urlParams.get("form") == "full" ? true : false;
    this.domainName = urlParams.get("domain") ? urlParams.get("domain") : "";
    this.domainName = this.domainName.toLowerCase();

    //fill automatically recieved data
    this.noPaymentAccess = urlParams.has("npa") ? true : false;
    this.npa_hash = urlParams.get("npa") ?? null;

    if (this.npa_hash != null) {
      this.$router.replace("/fin-promo");
      return;
    }

    if (this.noPaymentAccess) {
      this.disableDomain = true;
      //
      popup.action = "notification";
      popup.setTitle("Important");
      popup.setMessage(
        "<h5>Intégrer notre groupe whatsapp pour la promo .bf</h5>" +
          "<br>" +
          "<a href='https://chat.whatsapp.com/H2vSq6uiyQ307oUUof0lV1' target='_blank'><button type='button' class='join_wha_group' width='128px'><img src='" +
          Globals.website_url +
          "wha_icon.png' width='32px'/> Nous rejoindre sur Whatsapp</button></a>" +
          "<br><br>" +
          "<b>Contact support Ecodev:</b> <b><a class='link-success' href='tel:+22651088080' target='_blank'>+226 51 08 80 80</a></b>"
      );
      popup.setButton("Ok");
      popup.show();
    }

    this.data.natureRegister =
      urlParams.get("natureRegister") === "Entreprise" ? "STRUCTURE" : "PERSON";
    this.data.Fname1 = urlParams.get("fullname") ?? "";
    this.data.Fname2 = urlParams.get("fullname") ?? "";
    this.data.Fname3 = urlParams.get("fullname") ?? "";
    this.data.Fname4 = urlParams.get("fullname") ?? "";
    this.data.organism1 = urlParams.get("organism") ?? "";
    this.data.organism2 = urlParams.get("organism") ?? "";
    this.data.organism3 = urlParams.get("organism") ?? "";
    this.data.organism4 = urlParams.get("organism") ?? "";
    this.data.rJuridic = urlParams.get("formJuridic") ?? "";
    this.data.Email1 = urlParams.get("email") ?? "";
    this.data.Email2 = urlParams.get("email") ?? "";
    this.data.Email3 = urlParams.get("email") ?? "";
    this.data.Email4 = urlParams.get("email") ?? "";
    this.data.Tel1 = urlParams.get("tel") ?? "";
    this.data.Tel2 = urlParams.get("tel") ?? "";
    this.data.Tel3 = urlParams.get("tel") ?? "";
    this.data.Tel4 = urlParams.get("tel") ?? "";
    this.data.rProfession = urlParams.get("profession") ?? "";
    this.data.Address1 = urlParams.get("address") ?? "";
    this.data.Address2 = urlParams.get("address") ?? "";
    this.data.Address3 = urlParams.get("address") ?? "";
    this.data.Address4 = urlParams.get("address") ?? "";
    this.data.Country1 = urlParams.get("country") ?? "BF";
    this.data.Country2 = urlParams.get("country") ?? "BF";
    this.data.Country3 = urlParams.get("country") ?? "BF";
    this.data.Country4 = urlParams.get("country") ?? "BF";
    this.data.City1 = urlParams.get("city") ?? "";
    this.data.City2 = urlParams.get("city") ?? "";
    this.data.City3 = urlParams.get("city") ?? "";
    this.data.City4 = urlParams.get("city") ?? "";
    this.data.PostalCode1 = urlParams.get("postalCode") ?? "226";
    this.data.PostalCode2 = urlParams.get("postalCode") ?? "226";
    this.data.PostalCode3 = urlParams.get("postalCode") ?? "226";
    this.data.PostalCode4 = urlParams.get("postalCode") ?? "226";
  },

  mounted() {
    if (!this.isFullForm) {
      $("#rDomainCountry2").removeAttr("required");
      $("#rDomainCountry3").removeAttr("required");
      $("#rDomainCountry4").removeAttr("required");
    }
    //
    this.__update();
    //
    this.trackFormUpdate();

    $("#popupYes").click(() => {
      if (popup.action !== "continue-last-process") {
        return;
      }
      //onExternalAccount
      location =
        "?uid=" + this.histData.externalAccountId + "#/enregistrer-mon-compte";
    });
  },

  methods: {
    trackFormUpdate() {
      $("select,input,textarea").on("input", () => {
        this.hasGotPDF = false;
      });
    },

    __update() {
      waitLoader.show();
      //
      setTimeout(() => {
        this.update();
        this.makeTelInput();
      }, 100);
    },

    async update() {
      localforage
        .getItem("ecocloud/fullaccess")
        .then(async (result) => {
          let jsData = result;
          if (jsData == "" || jsData == "{}" || jsData == null) {
            return;
          }
          try {
            jsData = JSON.parse(jsData);
          } catch (e) {
            return;
          }
          //
          this.histData = await jsData;
          //
          if (this.histData && this.histData.Domain) {
            if (
              this.histData.externalAccountCreated &&
              this.histData.externalAccountId
            ) {
              //
              let fd = new FormData();
              fd.append(
                "query",
                "is-external-account/" + this.histData.externalAccountId
              );

              axios
                .post(Globals.website_url + "php/api.php", fd)
                .then((response) => {
                  let h = response.data;
                  //on no external account or encountred error
                  if (!h || typeof h === String) {
                    return;
                  }

                  //on external account
                  popup.action = "continue-last-process";
                  popup.setTitle("Important");
                  popup.setMessage(
                    "Voulez vous continuer votre dernière procédure ?<br>Email: " +
                      this.histData.Email1 +
                      "<br>Domaine: " +
                      this.histData.Domain +
                      "<br>Tel: " +
                      this.histData.Tel1.replaceAll(".", "") +
                      "<br>Nom complet: " +
                      this.histData.Fname1
                  );
                  popup.setButton("Oui", "Non");
                  popup.show();
                  //
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });

      localforage
        .getItem("ecocloud/fullaccess-history")
        .then(async (result) => {
          let jsData = result;
          if (jsData == "" || jsData == "{}" || jsData == null) {
            return;
          }
          try {
            jsData = JSON.parse(jsData);
          } catch (e) {
            return;
          }
          //
          this.historyData = await jsData;

          if (this.historyData[this.domainName]) {
            this.data = this.historyData[this.domainName];
          }
          //
          if (this.data && this.data.Domain) {
            this.data.Tel1 = this.data.Tel1.replaceAll(".", "");
            this.data.Tel2 = this.data.Tel2.replaceAll(".", "");
            this.data.Tel3 = this.data.Tel3.replaceAll(".", "");
            this.data.Tel4 = this.data.Tel4.replaceAll(".", "");
          }
          //
          waitLoader.hide();
          //
        })
        .catch((err) => {
          console.log(err);
        });
    },

    makeTelInput() {
      setTimeout(() => {
        if (document.getElementById("rDomainTel1")) {
          this.intTel1 = intlTelInput($("#rDomainTel1")[0], {
            // any initialisation options go here
            initialCountry: "bf",
            preferredCountries: ["bf", "ci"],
            utilsScript: utilsScript,
          });
        }

        if (document.getElementById("rDomainTel2")) {
          this.intTel2 = intlTelInput($("#rDomainTel2")[0], {
            // any initialisation options go here
            initialCountry: "bf",
            preferredCountries: ["bf", "ci"],
            utilsScript: utilsScript,
          });
        }

        if (document.getElementById("rDomainTel3")) {
          this.intTel3 = intlTelInput($("#rDomainTel3")[0], {
            // any initialisation options go here
            initialCountry: "bf",
            preferredCountries: ["bf", "ci"],
            utilsScript: utilsScript,
          });
        }

        if (document.getElementById("rDomainTel4")) {
          this.intTel4 = intlTelInput($("#rDomainTel4")[0], {
            // any initialisation options go here
            initialCountry: "bf",
            preferredCountries: ["bf", "ci"],
            utilsScript: utilsScript,
          });
        }

        waitLoader.hide();
      }, 500);
    },

    gotoForm() {
      const baseUrl = "/";
      const params = new URLSearchParams({
        form: this.isFullForm ? "simple" : "full",
        domain: this.domainName,
      });
      const route = "#/formulaire-enregistrement-nom-domaine-bf";
      //
      window.location.href = `${baseUrl}?${params.toString()}${route}`;
    },

    fillInput(index) {
      if (index <= 1) {
        this.data["Fname" + index] =
          this.data["Fname" + index] !== ""
            ? this.data["Fname" + index]
            : this.data["Fname" + (index - 1)];
        this.data["organism" + index] = this.data["organism" + (index - 1)];
      }
      //
      this.data["Email" + index] = this.data["Email" + (index - 1)];
      this.data["Tel" + index] = this.data["Tel" + (index - 1)];
      this.data["Country" + index] = this.data["Country" + (index - 1)];
      this.data["City" + index] = this.data["City" + (index - 1)];
      this.data["PostalCode" + index] = this.data["PostalCode" + (index - 1)];
      this.data["Address" + index] = this.data["Address" + (index - 1)];
      this.data["Fax" + index] = this.data["Fax" + (index - 1)];
      this.data["PostalBox" + index] = this.data["PostalBox" + (index - 1)];
      //
      if (index == 3) {
        this.data["rStructure" + index] = this.data["rStructure" + (index - 1)];
        this.data["rServiceFunction" + index] =
          this.data["rServiceFunction" + (index - 1)];
      }
    },

    checkDnsInput() {
      let dnsZones = [
        this.data.rServer_1,
        this.data.rServer_2,
        this.data.rServer_3,
        this.data.rServer_4,
      ];
      let i = 0;
      dnsZones.forEach((dns1) => {
        let j = 0;
        this.dnsErr[i] = false;
        dnsZones.forEach((dns2) => {
          if (i != j && dns1 && dns2 && dns1 == dns2) {
            this.dnsErr[i] = true;
          }
          j++;
        });
        i++;
      });
    },

    checkZoneInvInput() {
      let dnsZones = [this.data.zServer_1, this.data.zServer_2];
      let i = 0;
      dnsZones.forEach((dns1) => {
        let j = 0;
        this.zoneInvErr[i] = false;
        dnsZones.forEach((dns2) => {
          if (i != j && dns1 && dns2 && dns1 == dns2) {
            this.zoneInvErr[i] = true;
          }
          j++;
        });
        i++;
      });
    },

    checkDns() {
      let dnsZones = [
        this.data.rServer_1,
        this.data.rServer_2,
        this.data.rServer_3,
        this.data.rServer_4,
      ];
      //
      let err = false;
      let i = 0;
      dnsZones.forEach((dns1) => {
        let j = 0;
        if (err) return;
        dnsZones.forEach((dns2) => {
          if (err) return;
          if (i != j && dns1 && dns2 && dns1 == dns2) {
            err = true;
            //say error
            popup.action = "Notification";
            popup.setTitle("Notification");
            popup.setMessage(
              "Tous les serveurs de noms doivent être différents.<br> Veuillez vérifier vos serveurs de noms."
            );
            popup.setButton("Ok");
            popup.show();
            return;
          }
          j++;
        });
        i++;
      });
      return !err;
    },

    checkZoneInv() {
      let dnsZones = [this.data.zServer_1, this.data.zServer_2];
      //
      let err = false;
      let i = 0;
      dnsZones.forEach((dns1) => {
        let j = 0;
        if (err) return;
        dnsZones.forEach((dns2) => {
          if (err) return;
          if (i != j && dns1 && dns2 && dns1 == dns2) {
            err = true;
            //say error
            popup.action = "Notification";
            popup.setTitle("Notification");
            popup.setMessage(
              "Tous les serveurs de zones inverses doivent être différents.<br> Veuillez vérifier vos serveurs de zones inverses."
            );
            popup.setButton("Ok");
            popup.show();
            return;
          }
          j++;
        });
        i++;
      });
      return !err;
    },

    validate() {
      if (!this.checkDns()) return;
      if (!this.checkZoneInv()) return;
      //
      waitLoader.setLabel("Enregistrement des informations");
      waitLoader.show();
      //
      if (!this.isFullForm) {
        this.fillInput(2);
        this.fillInput(3);
        this.fillInput(4);
      }
      //
      this.domainName = this.domainName.toLowerCase();
      this.data.Domain = this.domainName;
      this.data.Years = 1;
      this.data.noPaymentAccess = this.noPaymentAccess;
      this.data.npa_hash = this.npa_hash;
      //
      this.data.Fname1 =
        this.data.natureRegister === "PERSON"
          ? this.data.Fname1
          : this.data.organism1;
      //
      this.data.Fname4 =
        this.data.natureBilling === "PERSON"
          ? this.data.Fname4
          : this.data.organism4;
      //
      this.historyData[this.domainName] = this.data;
      //save cache data
      localforage
        .setItem(
          "ecocloud/fullaccess-history",
          JSON.stringify(this.historyData)
        )
        .then(() => {
          localforage
            .setItem("ecocloud/fullaccess", JSON.stringify(this.data))
            .then(() => {
              if (this.validation == 0) {
                this.genPDF(this.data);
                // this.hasGotPDF = true;
                // waitLoader.hide();
                return;
              }

              if (this.__EC_checkDomain()) {
                this.validateData();
              }
            })
            .catch(function () {
              waitLoader.hide();
              // console.log(err);
            });
        })
        .catch(function () {
          waitLoader.hide();
          // console.log(err);
        });
    },

    validateData() {
      //FB Pixel event
      window.fbq("trackCustom", "ExternalValidateDomainBfForm", {
        domain: this.domainName,
      });
      //Google Analytics
      this.$gtag.event("ExternalValidateDomainBfForm", {
        domain: this.domainName,
      });
      //
      this.$router.push("/telecharger-documents-nom-domaine-bf");
      waitLoader.hide();
    },

    genPDF(data) {
      waitLoader.setLabel("Génération du formulaire PDF");
      //
      let $this = this;
      let fd = new FormData();
      fd.append(
        "query",
        "generate-external-domain-bf-form/" +
          functions.correctQueryString(JSON.stringify(data))
      );
      //
      //generate db form data
      axios
        .post(Globals.website_url + "php/api.php", fd)
        .then((response) => {
          let h = response.data;
          //on error
          if (h == "0" || typeof h != "object") {
            waitLoader.hide();
            //say error
            popup.action = "Notification";
            popup.setTitle("Notification");
            popup.setMessage(
              "Erreur d'enregistrement des données!<br>Veuillez reéssayer."
            );
            popup.setButton("Ok");
            popup.show();
            return;
          }
          //
          //create pdf
          const options = {
            method: "POST",
            url: "https://api.pdfendpoint.com/v1/convert",
            headers: {
              "Content-Type": "application/json",
              Authorization: h.auth,
            },
            data: JSON.stringify({
              url:
                "https://client.ecodev.dev/?ids=" + h.ids + "#/domain-bf-pdf",
              margin_top: "0px",
              margin_bottom: "0px",
              margin_left: "0px",
              margin_right: "0px",
              page_width: "724px",
              page_height: "1024px",
              title: "ABDI form",
              author: "ECODEV",
              creator: "ECODEV",
              producer: "pdfEndpoint",
            }),
          };

          axios
            .request(options)
            .then(function (response) {
              //
              axios
                .get(response.data.data.url, { responseType: "blob" })
                .then((response) => {
                  waitLoader.hide();
                  $this.hasGotPDF = true;
                  //
                  let a = document.createElement("a");
                  a.href = URL.createObjectURL(response.data);
                  a.target = "_blank";
                  a.download = "Nomdedomaine_" + data.Domain + ".pdf";
                  a.click();
                  URL.revokeObjectURL(response.data);
                  //
                })
                .catch((error) => {
                  console.log(error);
                  waitLoader.hide();
                  //
                  popup.action = "Notification";
                  popup.setTitle("Notification");
                  popup.setMessage(
                    "Erreur lors du téléchargement du document !<br>Veuillez reéssayer."
                  );
                  popup.setButton("Ok");
                  popup.show();
                });
              //
            })
            .catch(function (error) {
              console.error(error);
            });
          //
        })
        .catch((error) => {
          console.log(error);
          waitLoader.hide();
        });
    },

    __checkDomain() {
      waitLoader.show();
      waitLoader.setLabel("Vérification du nom de domaine");
      //
      $("#chooseOfferDomainExists")[0].setAttribute("show", "false");
      $("#chooseOfferDomainNotExists")[0].setAttribute("show", "false");
      $("#chooseOfferDomainNotExists a")[0].innerHTML = "";
      //
      setTimeout(() => {
        this.__EC_checkDomain();
      }, 100);
    },

    __EC_checkDomain() {
      let domain = this.domainName;
      //check extension of choosen domain name
      let ext = domain.substring(domain.lastIndexOf(".") + 1);
      if (ext != "bf") {
        waitLoader.hide();
        //
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "Vous devez obligatoirement choisir un nom de domaine .bf"
        );
        popup.setButton("Ok");
        popup.show();
        return false;
      }
      //
      if (
        domain.length < 4 ||
        domain.indexOf(".") < 2 ||
        domain.indexOf(".") > domain.length - 2
      ) {
        waitLoader.hide();
        //
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "Veuillez saisir un nom de domaine valide (2 caractères minimum) suivi d'une extension (ex: .bf)."
        );
        popup.setButton("Ok");
        popup.show();
        return false;
      }
      //
      let h = functions.syncHttpGet(
        Globals.website_url + "php/domain/checkDomain.php?domain=" + domain
      );
      //
      try {
        h = JSON.parse(h);
      } catch (e) {
        waitLoader.hide();
        //
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage("Problème de vérification! Veuillez reéssayer.");
        popup.setButton("Ok");
        popup.show();
        //
        waitLoader.hide();
        return false;
      }
      //
      if (!h.success) {
        $("#chooseOfferDomainExists")[0].setAttribute("show", "false");
        $("#chooseOfferDomainNotExists")[0].setAttribute("show", "false");
        //
        if (h.code == "domain_premium") {
          popup.setMessage(
            "Pour les noms de domaines prémium, veuillez contacter l'assistance de Ecoweb."
          );
          $("#chooseOfferDomainNotExists")[0].setAttribute("show", "false");
          $("#chooseOfferDomainExists")[0].setAttribute("show", "true");
          $("#chooseOfferDomainExists a")[0].innerHTML =
            "Nom de domaine premium.";
        } else {
          popup.setMessage(
            "Problème de vérification du nom de domaine! Veuillez reéssayer."
          );
        }
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setButton("Ok");
        popup.show();
        //
        waitLoader.hide();
        return false;
      }
      //FB Pixel event
      window.fbq("trackCustom", "ExternCheckOfferDomain", {
        domain: domain,
        available: h.data[domain].availability,
      });
      //Google Analytics
      this.$gtag.event("ExternCheckOfferDomain", {
        domain: domain,
        available: h.data[domain].availability,
      });
      //
      if (h.data[domain].availability) {
        $("#chooseOfferDomainExists")[0].setAttribute("show", "false");
        $("#chooseOfferDomainNotExists")[0].setAttribute("show", "true");
        $("#chooseOfferDomainNotExists a")[0].innerHTML =
          "Nom de domaine libre.";
        //
        waitLoader.hide();
        return true;
      } else {
        $("#chooseOfferDomainExists")[0].setAttribute("show", "true");
        $("#chooseOfferDomainExists a")[0].innerHTML =
          "Nom de domaine déjà réservé.";
        $("#chooseOfferDomainNotExists")[0].setAttribute("show", "false");
        //
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "Ce nom de domaine est déjà réservé! Veuillez en choisir un autre."
        );
        popup.setButton("Ok");
        popup.show();
        //
        waitLoader.hide();
        return false;
      }
    },
  },
};
</script>

<style scoped>
@import "intl-tel-input/build/css/intlTelInput.css";

#bg {
  background: url("/src/assets/images/app-bg.jpg") no-repeat center center fixed;
  background-size: cover;
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

form {
  margin: 20px 0;
  width: 100%;
  max-width: 800px;
  background-color: #f2fbff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.35);
  text-align: left;
  color: #111a2b;
  padding: 28px;
}

form input[readonly] {
  background-color: #f8f8f8;
  color: #888;
}

form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("/src/assets/images/select-arrow.svg");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  background-color: white;
}

.rDomaintitle {
  border-bottom: solid 1px black;
  padding-bottom: 8px;
  margin-bottom: 16px;
}

.rDomaintitle button {
  float: left !important;
}

.form-floating label {
  opacity: 0.5;
}

.rDomainTel {
  width: 100% !important;
}

.oneRow div {
  display: inline;
  width: 50%;
}

.noRow {
  padding: 0;
}

.noRow div {
  display: block !important;
  width: 100% !important;
}

.oneRow3 div:first-child {
  display: inline;
  width: 40%;
}

.oneRow3 div:nth-child(2) {
  display: inline;
  width: 25%;
}

.oneRow3 div:last-child {
  display: inline;
  width: 35%;
}

.oneRowItem div {
  display: inline !important;
  width: 100% !important;
}

@media only screen and (max-width: 512px) {
  .oneRow3 {
    background-color: rgb(169, 221, 255);
    margin-bottom: 16px;
    padding: 3px;
  }

  .oneRow div {
    display: block !important;
    width: 100% !important;
  }

  .oneRow3 div {
    display: block !important;
    width: 100% !important;
  }
}

.uniqueError {
  font-size: 13px;
}

.noRow[error="false"] .uniqueError {
  display: none !important;
}

.noRow[error="true"] .uniqueError {
  display: block;
}

.noRow[error="true"] :nth-child(1) {
  border-color: red !important;
}

*:required:invalid {
  border: 2px solid red !important;
}

*:focus:required:invalid {
  -webkit-box-shadow: 0px 0px 0px 4px rgba(255, 0, 0, 0.35);
  -moz-box-shadow: 0px 0px 0px 4px rgba(255, 0, 0, 0.35);
  box-shadow: 0px 0px 0px 4px rgba(255, 0, 0, 0.35);
}

#chooseOfferDomainNotExists {
  font-size: 32px;
  color: green;
  position: relative;
  top: 10px;
}

#chooseOfferDomainNotExists a {
  font-size: 16px;
  position: relative;
  top: -7px;
}

#chooseOfferDomainExists {
  font-size: 32px;
  color: #c00;
  position: relative;
  top: 10px;
}

#chooseOfferDomainExists a {
  font-size: 16px;
  position: relative;
  top: -7px;
}

#chooseOfferDomainExists[show="false"] {
  display: none !important;
}

#chooseOfferDomainNotExists[show="false"] {
  display: none !important;
}

#chooseOfferCheckDomainBtn {
  width: max-content;
}
</style>
