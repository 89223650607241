<template>
  <main>
    <section class="part1">
      <div class="box">
        <img src="@/assets/images/logo-light.png" class="logo" />
      </div>

      <div class="bottom">
        <p class="mt-3">
          <a>Nom de domaine | Stockage de données</a>
          <a> | </a>
          <a>Emails Professionnels | Hebergement WEB</a>
        </p>
      </div>
    </section>
    <!--  -->
    <!--  -->
    <!--  -->
    <section id="part2" class="part part2" shown="true">
      <div class="box">
        <div class="text-center">
          <img src="@/assets/images/logo-ecodev.png" class="logo" />
        </div>
        <!--  -->
        <div class="col">
          <button
            id="loginPageBtn"
            class="page-btn"
            selected="true"
            @click="showPage"
            pageId="loginPage"
          >
            Connexion
          </button>
          <button
            id="signupPageBtn"
            class="page-btn"
            selected="false"
            @click="showPage"
            pageId="signupPage"
          >
            Inscription
          </button>
        </div>
        <!--  -->
        <br />
        <!--  -->
        <form
          id="loginPage"
          class="page"
          @submit.prevent="__loginValidate()"
          shown="true"
        >
          <h5 @click="texter">MON ESPACE CLIENT</h5>
          <br />
          <div class="input-box linput-box">
            <i class="bi bi-person-fill"></i>
            <input
              type="text"
              id="lEmail"
              class="form-control"
              spellcheck="false"
              @input="resetInput"
              placeholder="Email ou Numéro de téléphone"
              required
              vinput="both"
            />
          </div>
          <!--  -->
          <div class="input-box linput-box">
            <i class="bi bi-lock-fill"></i>
            <input
              type="password"
              id="lPassword"
              class="form-control"
              @input="resetInput"
              placeholder="Mot de passe"
              required
            />
          </div>
          <!--  -->
          <a class="page-a w-100" @click="showPart" partId="part3"
            >Mot de passe oublié ?</a
          >
          <br />
          <!--  -->
          <button type="submit" class="page-validate-btn mt-3">
            Se connecter
          </button>
          <br />
          <button
            type="button"
            class="app-install-btn mt-3 btn-primary"
            @click="downloadApp()"
            v-if="deferredPrompt"
          >
            <i class="bi bi-download"></i>
            Installer l'application
          </button>
        </form>
        <!--  -->
        <!--  -->
        <form
          id="signupPage"
          class="page"
          @submit.prevent="__signupValidate()"
          shown="false"
        >
          <h5>BIENVENUE SUR ECOWEB</h5>
          <br />
          <!--  -->
          <div class="input-box sinput-box">
            <i class="bi bi-person-lines-fill"></i>
            <input
              type="text"
              id="sFullname"
              class="form-control"
              spellcheck="false"
              @input="resetInput"
              placeholder="Nom et prénom"
              required
            />
          </div>
          <!--  -->
          <div class="input-box sinput-box">
            <input
              type="tel"
              spellcheck="false"
              id="userDataTel"
              class="form-control"
              @input="resetInput"
              required
            />
          </div>
          <!--  -->
          <div class="input-box sinput-box">
            <i class="bi bi-envelope-fill"></i>
            <input
              vinput="email"
              type="text"
              id="sEmail"
              class="form-control"
              spellcheck="false"
              @input="resetInput"
              placeholder="Email"
            />
          </div>
          <!--  -->
          <div class="input-box sinput-box">
            <i class="bi bi-lock-fill"></i>
            <input
              type="password"
              @input="resetInput"
              id="sPassword"
              class="form-control"
              placeholder="Mot de passe"
              required
            />
          </div>
          <!--  -->
          <div class="input-box sinput-box">
            <i class="bi bi-lock-fill"></i>
            <input
              type="password"
              id="sConfPassword"
              @input="resetInput"
              class="form-control"
              placeholder="Confirm. Mot de passe"
              required
            />
          </div>
          <!--  -->
          <button type="submit" class="page-validate-btn mt-2">
            S'inscrire
          </button>
        </form>
      </div>
    </section>
    <!--  -->
    <!--  -->
    <!--  -->
    <section id="part3" class="part part3" shown="false">
      <div class="box">
        <div class="text-center">
          <img src="@/assets/images/logo-ecodev.png" class="logo" />
        </div>
        <!--  -->
        <div class="col">
          <i
            class="back-button float-start bi bi-arrow-left rounded-circle"
            @click="showPart"
            partId="part2"
          ></i>
          <strong class="title">MOT DE PASSE OUBLIE</strong>
        </div>
        <!--  -->
        <br />
        <!--  -->
        <form
          id="forgotPage"
          class="page"
          @submit.prevent="forgotValidate()"
          shown="true"
        >
          <label class="text-start">
            Veuillez entrez votre adresse email pour recevoir un lien de
            réinitialisation de mot de passe.
          </label>
          <br />
          <br />
          <div class="input-box">
            <i class="bi bi-envelope-fill"></i>
            <input
              vinput="email"
              class="form-control"
              type="text"
              id="forgotPasswordEmail"
              placeholder="Adresse email"
              @input="resetInput"
              required
            />
          </div>
          <br />
          <!--  -->
          <button
            type="submit"
            id="forgotPasswordValidateBtn"
            enabled="true"
            class="page-validate-btn"
          >
            Récupérer mon compte
          </button>
          <br />
          <br />
          <a
            >Vous pourrez renvoyer un nouveau lien dans <b> {{ ctimer }}</b></a
          >
        </form>
      </div>
    </section>
  </main>
</template>

<script>
const $ = require("jquery");
import axios from "axios";
//
import waitLoader from "/src/common/js/waitLoader";
import functions from "/src/common/js/functions";
import popup from "/src/common/js/popup";
import Globals from "/src/common/js/Globals";
//
import * as localforage from "localforage";
import intlTelInput from "intl-tel-input";
import utilsScript from "intl-tel-input/build/js/utils.js";
//
import { mapStores } from "pinia";
import { useUsersStore } from "/src/common/stores/users.js";
//
import validator from "/src/modules/validator/";

export default {
  data() {
    return {
      deferredPrompt: null,
      intTel: null,
      //
      cutdown: 1,
      counter: 0,
    };
  },

  computed: {
    ...mapStores(useUsersStore),

    ctimer() {
      let ct = this.cutdown - this.counter;
      let min = Math.floor(ct / 60);
      let s = ct - min * 60;
      if (this.counter == -1) {
        return "00:00";
      }
      return (min < 10 ? "0" + min : min) + ":" + (s < 10 ? "0" + s : s);
    },
  },

  async mounted() {
    try {
      let jsData = await localforage.getItem("ecocloud/common_pwd");
      if (jsData == "" || jsData == "{}" || jsData == null) {
      } else {
        jsData = JSON.parse(jsData);
        this.cutdown = jsData.cutdown ?? 1;
        this.counter = jsData.counter ?? (this.cutdown == 1 ? -1 : 0);
      }
    } catch (err) {
      console.log(err);
    }
    //
    this.count();

    //
    window.addEventListener("beforeinstallprompt", (e) => {
      this.deferredPrompt = e;
    });
    //
    window.addEventListener("appinstalled", async () => {
      await axios.post(
        Globals.website_url +
          "php/api.php?query=append-pwa-installation/" +
          functions.getDeviceType() +
          "/" +
          functions.getDevice() +
          "/" +
          functions.getOS()
      );
    });
    //
    //
    waitLoader.hide();
    //
    this.intTel = intlTelInput($("#userDataTel")[0], {
      // any initialisation options go here
      initialCountry: "bf",
      preferredCountries: ["bf", "ci"],
      utilsScript: utilsScript,
    });
    //
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let signup = urlParams.get("signup");
    //let mustcount = urlParams.get('mstcnt');
    let forgot = urlParams.get("forgot");
    //
    if (signup == 1) {
      $(".part2 .page-btn").attr("selected", "false");
      $("#signupPageBtn")[0].setAttribute("selected", "true");
      //
      $(".part2 .page").attr("shown", "false");
      $("#signupPage").attr("shown", "true");
      //
      /*if (mustcount == 1) {

            }*/
    }

    if (forgot == 1) {
      $(".part").attr("shown", "false");
      $("#part3").attr("shown", "true");
    }
  },

  methods: {
    count() {
      setTimeout(() => {
        if (this.counter > -1) {
          this.counter++;
          $("#forgotPasswordValidateBtn").attr("enable", "false");
          //
          if (this.counter >= this.cutdown) {
            this.cutdown *= 5;
            this.counter = -1;
          }
        } else {
          $("#forgotPasswordValidateBtn").attr("enable", "true");
        }

        localforage
          .setItem(
            "ecocloud/common_pwd",
            JSON.stringify({ cutdown: this.cutdown, counter: this.counter })
          )
          .then(() => {})
          .catch(function (err) {
            console.log(err);
          });

        this.count();
      }, 1000);
    },

    showPage(e) {
      $(".part2 .page-btn").attr("selected", "false");
      e.target.setAttribute("selected", "true");
      //
      $(".part2 .page").attr("shown", "false");
      $("#" + e.target.getAttribute("pageId")).attr("shown", "true");
    },

    showPart(e) {
      //
      $(".part").attr("shown", "false");
      $("#" + e.target.getAttribute("partId")).attr("shown", "true");
    },

    async downloadApp() {
      if (this.deferredPrompt !== null) {
        this.deferredPrompt.prompt();
        const { outcome } = await this.deferredPrompt.userChoice;
        if (outcome === "accepted") {
          this.deferredPrompt = null;
        }
      }
    },

    __loginValidate() {
      waitLoader.show();
      //
      setTimeout(() => {
        this.loginValidate();
      }, 100);
    },

    loginValidate() {
      var email = document.getElementById("lEmail").value;
      //auto detect phone number
      if (email.substr(0, 2) == "00" && email.substr(2, 1) != 0) {
        email = "+" + email.substr(2, 1000);
      } else if (this.isDigitOrPlus(email[0])) {
        waitLoader.hide();
        //say error
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "Votre numéro de téléphone doit commencé par l'indicatif du pays soit +226xxxxxxxx ou 00226xxxxxxxx."
        );
        popup.setButton("Ok");
        popup.show();
        return;
      } else if (!this.isDigitOrPlus(email[0]) && !validator.isEmail(email)) {
        waitLoader.hide();
        //say error
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage("Votre adresse email n'est pas valide.");
        popup.setButton("Ok");
        popup.show();
        return;
      }

      //
      var password = document.getElementById("lPassword").value;
      //errors
      if (password.length < 4) {
        this.lError();
        waitLoader.hide();
        return false;
      }
      //blur focus from input
      $("input").blur();

      //encrypt data
      email = functions.correctQueryString(email);
      password = functions.correctQueryString(password);
      let fd = new FormData();
      fd.append("query", "login-user/" + email + "/" + password);
      //
      //
      axios
        .post(Globals.website_url + "php/api.php", fd)
        .then((response) => {
          let h = response.data;
          //on error
          if (h == null || h == [] || h.length == 0) {
            //say error
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage(
              "Informations de connexion incorrectes!<br>Veuillez reéssayer."
            );
            popup.setButton("Ok");
            popup.show();
            //
            this.lError();
            waitLoader.hide();
            return false;
          }

          if(h==="error-invalid-email"){
            //say error
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage(
              "Votre adresse email est invalide."
            );
            popup.setButton("Ok");
            popup.show();
            //
            this.lError();
            waitLoader.hide();
            return false;
          }
          
          //on success
          //use pinia
          this.usersStore.User.account.id = h.id;
          this.usersStore.User.account.email = h.email;
          this.usersStore.User.account.password = h.passwd;
          this.usersStore.User.account.atLogin = true;
          this.usersStore.User.data = h;
          //
          //
          //save cache data
          localforage
            .setItem("ecocloud/User", JSON.stringify(this.usersStore.User))
            .then(() => {
              waitLoader.hide();
              //
              if (
                this.usersStore.User.data.creating_step <= 0 &&
                this.usersStore.User.data.email_code != null &&
                this.usersStore.User.data.email_code != ""
              ) {
                let amail = functions.correctQueryString(email);
                location = "?amail=" + amail + "#/confirm-email";
                return false;
              }
              this.$router.push("/client/Home");
            })
            .catch(function (err) {
              // we got an error
              waitLoader.hide();
              console.log(err);
            });
        })
        .catch((error) => {
          //
          console.log(error);
          waitLoader.hide();
          //say error
          popup.action = "notification";
          popup.setTitle("Notification");
          popup.setMessage("Problème de connexion!<br>Veuillez reéssayer.");
          popup.setButton("Ok");
          popup.show();
        });
    },

    __signupValidate() {
      waitLoader.show();
      //
      setTimeout(() => {
        this.signupValidate();
      }, 100);
    },

    signupValidate() {
      var fullname = document.getElementById("sFullname").value;
      var email = document.getElementById("sEmail").value;
      //
      var password = document.getElementById("sPassword").value;
      var cpassword = document.getElementById("sConfPassword").value;
      let tel = this.intTel.getNumber();
      //reset all errors
      let color1 = "#FFF";
      document.getElementById("sFullname").style.backgroundColor = color1;
      document.getElementById("sEmail").style.backgroundColor = color1;
      document.getElementById("sPassword").style.backgroundColor = color1;
      document.getElementById("sConfPassword").style.backgroundColor = color1;
      //
      let color = "#F66";
      let error = false;
      //check email validity
      if (!validator.isEmail(email)) {
        this.sError(document.getElementById("sEmail"));
        error = true;
      }

      //password error
      if (password.length < 4) {
        this.sError(document.getElementById("sPassword"));
        error = true;
      }
      //confPassword error
      if (password != cpassword) {
        this.sError(document.getElementById("sConfPassword"));
        error = true;
      }

      //name errors
      if (fullname.length < 2) {
        this.sError(document.getElementById("sFullname"));
        error = true;
      }
      //encryt data
      fullname = functions.correctQueryString(fullname);
      email = functions.correctQueryString(email);
      password = functions.correctQueryString(password);
      tel = functions.correctQueryString(tel);
      //
      //check with email
      //on error
      if (error) {
        waitLoader.hide();
        return false;
      }
      //blur focus from input
      $("input").blur();
      //
      //try to create user account
      axios
        .post(
          Globals.website_url +
            "php/api.php?query=create-user/" +
            email +
            "/" +
            tel +
            "/" +
            fullname +
            "/" +
            password
        )
        .then((response) => {
          let h = response.data;
          //on error
          if (!h.success) {
            waitLoader.hide();
            //
            //on no access account
            if (h.errorCode == 1) {
              popup.action = "set-account-access";
              popup.setTitle("Notification");
              popup.setMessage(
                "Un compte est déjà lié à cet identifiant, Voulez vous y avoir accès ?"
              );
              popup.setButton("Oui", "Non");
              popup.show();
              //
              $("#popupYes").click(() => {
                if (popup.action == "set-account-access") {
                  location.href = "?uid=" + h.data + "#/enregistrer-mon-compte";
                }
              });
              //
              return;
            }
            //
            if (h.errorCode == 2) {
              //say error
              document.getElementById("sEmail").style.backgroundColor = color;
              error = true;
              //
              popup.action = "notification";
              popup.setTitle("Notification");
              popup.setMessage(
                "Vous posséder déjà un compte lié à cet identifiant, veuillez cliquer sur connexion pour vous connecter."
              );
              popup.setButton("Ok");
              popup.show();
              //
              return;
            }

            if (h.errorCode == 4) {
              //say error
              document.getElementById("sEmail").style.backgroundColor = color;
              error = true;
              //
              popup.action = "notification";
              popup.setTitle("Notification");
              popup.setMessage(
                "Votre addresse email n'est pas valide."
              );
              popup.setButton("Ok");
              popup.show();
              //
              return;
            }
            //say error
            popup.action = "notification";
            popup.setTitle("Notification");
            popup.setMessage(
              "Problème de création du compte!<br>Veuillez reéssayer."
            );
            popup.setButton("Ok");
            popup.show();
            //
            return;
          }
          //
          //on success
          //FB pixel
          window.fbq("trackCustom", "Signup", {
            fullName: document.getElementById("sFullname").value,
            email: document.getElementById("sEmail").value,
          });
          //Google Analytics
          this.$gtag.event("Signup", {
            fullName: document.getElementById("sFullname").value,
            email: document.getElementById("sEmail").value,
          });
          //
          this.sendConfirmEmail();
          //
        })
        .catch((error) => {
          waitLoader.hide();
          //say error
          popup.action = "notification";
          popup.setTitle("Notification");
          popup.setMessage("Veuillez vérifier votre connexion à internet.");
          popup.setButton("Ok");
          popup.show();
          //
          console.log(error);
        });
    },

    sendConfirmEmail() {
      //on good email
      let email = document.getElementById("sEmail").value;
      let h = functions.syncHttpGet(
        Globals.website_url +
          "php/mailer/sendConfirmEmailLink.php?email=" +
          email
      );
      //
      if (h != 1) {
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "Echec de l'envoi du lien de confirmation!<br>Votre email n'existe peut être pas.<br>Veuillez reéssayer."
        );
        popup.setButton("Ok");
        popup.show();
        //
        waitLoader.hide();
        return;
      }
      //
      waitLoader.hide();
      //
      popup.action = "confirm-email";
      popup.setTitle("Notification");
      popup.setMessage(
        "Félicitation, votre compte a bien été créé ! Un email de confirmation vous a été envoyé.  Vérifiez également vos SPAMs si vous ne le trouvez pas."
      );
      popup.setButton("Ok");
      popup.show();

      $("#popupYes").click(() => {
        if (popup.action == "confirm-email") {
          document.getElementById("loginPageBtn").click();
        }
      });

      return;
    },

    isDigitOrPlus(char) {
      return /\d|\+/.test(char);
    },

    resetInput: function (e) {
      e.target.parentElement.style.borderColor = "transparent";
      //
      if (
        e.target.getAttribute("vinput") === "email" &&
        !validator.isEmail(e.target.value)
      ) {
        let border = "solid 1px red";
        e.target.parentElement.style.border = border;
      }

      if (
        e.target.getAttribute("vinput") === "both" &&
        !this.isDigitOrPlus(e.target.value[0]) &&
        !validator.isEmail(e.target.value)
      ) {
        let border = "solid 1px red";
        e.target.parentElement.style.border = border;
      }
    },

    lError() {
      let border = "solid 1px red";
      $(".linput-box").css("border", border);
    },

    sError(elem) {
      let border = "solid 1px red";
      $(elem)[0].parentElement.style.border = border;
    },

    forgotValidate() {
      //show waitLoader
      waitLoader.show();
      //exec send email request
      setTimeout(() => {
        this.sendEmail();
      }, 100);
    },

    sendEmail() {
      if (this.counter != -1) {
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "Vous devez patienter un moment avant de pouvoir renvoyer un lien de récupération !"
        );
        popup.setButton("Ok");
        popup.show();
        waitLoader.hide();
        return false;
      }
      //
      let email = $("#forgotPasswordEmail")[0].value.toLowerCase();
      let cemail = functions.correctQueryString(email);
      let h = functions.syncHttpGet(
        Globals.website_url +
          "php/api.php?query=check-if-user-exists-by-email/" +
          cemail
      );
      //
      if (h != 1) {
        //say error
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "Si vous possédez un compte lié à cette adresse email, vous recevrez un lien de réinitialisation de votre mot de passe."
        );
        popup.setButton("Ok");
        popup.show();
        //
        waitLoader.hide();
        return false;
      }

      //on good email
      h = functions.syncHttpGet(
        Globals.website_url +
          "php/mailer/sendPasswordForgottenLink.php?email=" +
          email
      );
      // console.log(h);
      if (h != 1) {
        waitLoader.hide();
        //
        popup.action = "notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "Echec de l'envoi du lien de recupération!<br>Veuillez reéssayer."
        );
        popup.setButton("Ok");
        popup.show();
        return false;
      }
      //
      waitLoader.hide();
      this.counter = 0;
      //
      popup.action = "notification";
      popup.setTitle("Notification");
      popup.setMessage(
        "Si vous possédez un compte lié à cette adresse email, vous recevrez un lien de réinitialisation de votre mot de passe."
      );
      popup.setButton("Ok");
      popup.show();
      return false;
    },
  },
};
</script>

<style scoped>
main {
  width: 100%;
  height: 100vh;
  display: flex;
}

.part1 {
  width: 50%;
  height: 100%;
  background-color: #111a2b;
  background: url("/src/assets/images/logBg.jpeg") no-repeat center center;
  background-size: cover;
  /*  */
  display: flex;
  justify-content: center;
  align-items: center;
}

.part1 .box {
  width: 512px;
  max-width: 100%;
  max-height: 100%;
  padding: 48px 32px;
  border-radius: 20px;
  /*  */
  background-color: rgba(0, 0, 35, 0.3);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.part1 .box .logo {
  width: 200px;
  height: auto;
}

.part1 .box .image {
  width: 100%;
  height: auto;
  display: none;
  transform: translateY(-1.5%);
  animation: swipe 3s infinite;
}

.part1 .box .image[shown="true"] {
  display: block;
}

.part1 .bottom {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.part1 .bottom p {
  color: #111a2b;
  font-size: 18px;
}

.part2 {
  width: 50%;
  height: 100%;
  /*  */
  display: none;
  justify-content: center;
  align-items: center;
}

.part2[shown="true"] {
  display: flex;
}

.part2 .box {
  width: 380px;
  max-width: 100%;
  height: max-content;
  max-height: 100%;
  /* box-shadow: rgba(99, 99, 99, 0.125) 0px 0px 3px 0.5px;
    border-radius: 19px; */
}

.part2 .box .logo {
  display: none;
  width: 200px;
  height: auto;
}

.part2 .page-btn {
  width: 50%;
  height: 64px;
  border: none;
  color: #777;
  border-bottom: solid 1px #ccc;
  background-color: transparent;
}

.part2 .page-btn:hover {
  color: #111a2b;
  cursor: pointer;
}

.part2 .page-btn[selected="true"] {
  border-bottom-color: #ff6904;
  color: #ff6904;
  font-weight: 600;
}

.part2 .page {
  display: none;
  height: max-content;
  width: 100%;
  /* padding: 8px 28px 28px 28px; */
}

.part2 .page[shown="true"] {
  display: block;
}

form h5 {
  font-weight: 600;
  color: #111a2b;
}

.input-box {
  display: flex;
  background-color: #eaecf5;
  border-radius: 12px;
  padding: 4px 10px;
  margin-bottom: 12px;
}

.input-box i {
  font-size: 24px;
  color: #979fb6;
}

.input-box input {
  background-color: transparent !important;
  border: none;
}

.input-box input:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.page-a {
  color: #7e859b;
  text-decoration: underline !important;
  cursor: pointer;
}

.page-validate-btn {
  width: 100%;
  border: none;
  height: 54px;
  border-radius: 99px;
  background-color: #111a2b;
  color: white;
}

.app-install-btn {
  width: 100%;
  border: none;
  height: 54px;
  border-radius: 99px;
}

.part3 {
  display: none;
  width: 50%;
  height: 100%;
  /*  */
  justify-content: center;
  align-items: center;
}

.part3[shown="true"] {
  display: flex;
}

.part3 .box {
  width: 380px;
  max-width: 100%;
  height: max-content;
  max-height: 100%;
}

.part3 .box .logo {
  display: none;
  width: 200px;
  height: auto;
}

.part3 .page {
  height: max-content;
  width: 100%;
  /* padding: 8px 28px 28px 28px; */
}

.back-button {
  padding: 4px 8px;
  background-color: #111a2b;
  color: white;
  cursor: pointer;
}

.back-button:hover {
  background-color: #ff6904;
}

.title {
  display: inline-block;
  margin-top: -2px;
  color: #111a2b;
  font-size: 24px;
}

@media only screen and (max-width: 800px) {
  .part1 {
    display: none !important;
  }

  .part2 .box .logo {
    display: inline;
  }

  .part2 {
    width: 100%;
    padding: 16px;
  }

  .part3 {
    width: 100%;
    padding: 16px;
  }
}
</style>
