<template>
  <main>
    <PayDetails></PayDetails>
  </main>
</template>

<script>
const $ = require("jquery");
import waitLoader from "/src/common/js/waitLoader";
//
import { mapStores } from "pinia";
import { useUsersStore } from "/src/common/stores/users.js";
//
import PayDetails from "/src/components/PayDetails.vue";

//confirmOrder component
export default {
  computed: {
    ...mapStores(useUsersStore),
  },

  components: {
    PayDetails,
  },

  data() {
    return {
      pageName: "Confirmation du service",
    };
  },

  mounted() {
    waitLoader.show();
    //set the routeName
    $("#routeName")[0].innerHTML = this.pageName;
  },

  methods: {},
};
</script>

<style scoped>
/*-------------------------------
ConfirmOrder
-------------------------------*/
#confirmOrder {
  max-width: 840px;
  width: 100%;
  margin: auto;
  font-size: 16px;
}

.confirmOrderBox {
  width: 100%;
  margin: auto;
  background-color: #f2fbff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  height: max-content;
  padding: 28px;
  color: #111a2b;
  text-align: left;
  margin-bottom: 32px;
}

.infos {
  position: relative;
  top: 0px;
}

.infos:hover .tooltips {
  display: inline-block;
}

.infos .tooltips {
  display: none;
  position: absolute;
  left: 0;
  top: 22px;
  border-width: 1px;
  border-style: solid;
  width: 640px;
  max-width: calc(100vw - 16px) !important;
}

.infos i {
  border-radius: 50%;
  padding: 0 1.5px;
}
.infos span {
  font-style: italic;
}

.confirmOrderTitle {
  color: orange !important;
}

.confirmOrderItems {
  margin-top: 8px;
  width: 100%;
  height: max-content;
}

.confirmOrderItems {
  margin-bottom: 32px;
}

.confirmOrderItem:nth-child(odd) {
  background-color: white;
}

.space {
  height: 20px;
}

.confirmOrderItem a,
.confirmOrderItem2 a {
  color: black;
}

.confirmOrderItem p,
.confirmOrderItem2 p {
  overflow: auto;
  max-height: 128px;
}

#confirmOrderPayNumber {
  padding: 0 14px;
  height: 68px;
}

.confirmOrderItemBackBtn i {
  font-size: 20px;
  margin-top: -3px;
  float: right;
}

.confirmOrderItemValidateBtn i {
  font-size: 20px;
  margin-top: -3px;
  float: right;
}

@media only screen and (max-width: 316px) {
}

.paymod {
  background-color: transparent;
  margin: 0 8px;
}

.paymodbtn {
  display: flex;
  height: 80px;
  border: solid 1px #ccc;
  border-radius: 3px;
  background-color: white;
  cursor: pointer;
}

.paymodbtn:hover {
  background-color: orange;
  color: white;
}

.paymodbtn[selected="true"] {
  background-color: #f60;
  color: white;
}

.promoBox {
  border-radius: 8px;
  border: solid 6px #47ffb2;
  padding: 8px;
  background-color: #c1ffe5;
}

#sellerContract {
  width: 100%;
  height: 200px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 16px;
  font-size: 13px;
  background-color: #d9edf7;
  border-radius: 6px;
  padding: 20px;
  border: none;
}

#sellerContract:focus {
  outline: none;
}
</style>
