<template>
  <main id="rDomain">
    <center>
      <form class="row" @submit.prevent="validate">
        <h4 class="text-center mb-3">Activation du nom de domaine</h4>
        <div class="rDomaintitle">
          <h5>Information sur le nom de domaine</h5>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating mb-3 p-1">
            <input
              type="text"
              id="rDomainDname"
              spellcheck="false"
              class="form-control"
              :value="domainName"
              disabled
              required
              readonly
            />
            <label for="rDomainDname">Nom de domaine</label>
          </div>

          <div class="form-floating mb-3 p-1">
            <input
              type="text"
              id="rDomainYears"
              class="form-control"
              :value="1"
              disabled
              required
              readonly
            />
            <label for="rDomainYears">Nombre d'années d'enregistrement</label>
          </div>
        </div>

        <div class="mt-4 rDomaintitle">
          <h5>Information sur le demandeur</h5>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating mb-3 p-1">
            <input
              type="text"
              id="rDomainFname1"
              spellcheck="false"
              class="form-control"
              placeholder="Krepin Jhon"
              :value="getFirstName(userData.fname)"
              @input="resetInput"
              required
            />
            <label for="rDomainFname1">Nom</label>
          </div>

          <div class="form-floating mb-3 p-1">
            <input
              type="text"
              id="rDomainLname1"
              spellcheck="false"
              class="form-control"
              placeholder="Krepin Jhon"
              :value="getLastName(userData.fname)"
              @input="resetInput"
              required
            />
            <label for="rDomainLname1">Prénom</label>
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating mb-3 p-1">
            <input
              vinput="email"
              @input="checkEmailInput"
              type="text"
              id="rDomainEmail1"
              class="form-control"
              placeholder="krepin@jhon.com"
              :value="userData.email"
              required
            />
            <label for="rDomainEmail1">Adresse Email</label>
          </div>

          <div class="form-floating mb-3">
            <a style="font-size: 14px">Numéro de téléphone</a>
            <br />
            <input
              type="tel"
              id="rDomainTel1"
              class="form-control rDomainTel"
              :value="userData.tel"
              @input="resetInput"
              required
            />
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating mb-3 p-1">
            <CountryList id="rDomainCountry1"></CountryList>
            <label for="rDomainCountry1">Pays</label>
          </div>

          <div class="form-floating mb-3 p-1">
            <input
              type="text"
              id="rDomainProvince1"
              class="form-control"
              placeholder="Ouagadougou"
              :value="userData.city"
              v-on:input="resetInput"
              required
            />
            <label for="rDomainProvince1">Province</label>
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating mb-3 p-1">
            <input
              type="text"
              id="rDomainCity1"
              class="form-control"
              placeholder="Ouagadougou"
              :value="userData.city"
              v-on:input="resetInput"
              required
            />
            <label for="rDomainCity1">Ville</label>
          </div>

          <div class="form-floating mb-3 p-1">
            <input
              type="number"
              id="rDomainPostalCode1"
              class="form-control"
              placeholder="Ouaga 2000"
              :value="userData.home_address"
              v-on:input="resetInput"
              required
            />
            <label for="rDomainPostalCode1">Code postale</label>
          </div>
        </div>
        <!--  -->
        <div class="row oneRowItem">
          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              id="rDomainAddress1"
              class="form-control"
              placeholder="Ouaga 2000"
              :value="userData.home_address"
              v-on:input="resetInput"
              required
            />
            <label for="rDomainAddress1">Adresse</label>
          </div>
        </div>

        <div class="d-flex justify-content-between mb-2 mt-4 rDomaintitle">
          <h5 class="d-block float-start me-3 mt-2">
            Information sur le technicien
          </h5>
          <button
            type="button"
            class="btn btn-primary w-auto"
            @click="fillInput(2)"
          >
            <a>{{ repeatDataText }}</a
            ><i class="bi bi-arrow-down ms-2"></i>
          </button>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating mb-3 p-1">
            <input
              type="text"
              id="rDomainFname2"
              spellcheck="false"
              class="form-control"
              placeholder="Krepin Jhon"
              @input="resetInput"
              required
            />
            <label for="rDomainFname2">Nom</label>
          </div>

          <div class="form-floating mb-3 p-1">
            <input
              type="text"
              id="rDomainLname2"
              spellcheck="false"
              class="form-control"
              placeholder="Krepin Jhon"
              @input="resetInput"
              required
            />
            <label for="rDomainLname2">Prénom</label>
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating mb-3 p-1">
            <input
              vinput="email"
              @input="checkEmailInput"
              type="text"
              id="rDomainEmail2"
              class="form-control"
              placeholder="krepin@jhon.com"
              required
            />
            <label for="rDomainEmail2">Adresse Email</label>
          </div>

          <div class="form-floating mb-3">
            <a style="font-size: 14px">Numéro de téléphone</a>
            <br />
            <input
              type="tel"
              id="rDomainTel2"
              class="form-control rDomainTel"
              @input="resetInput"
              required
            />
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating mb-3 p-1">
            <CountryList id="rDomainCountry2"></CountryList>
            <label for="rDomainCountry2">Pays</label>
          </div>

          <div class="form-floating mb-3 p-1">
            <input
              type="text"
              id="rDomainProvince2"
              class="form-control"
              placeholder="Ouagadougou"
              v-on:input="resetInput"
              required
            />
            <label for="rDomainProvince2">Province</label>
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating mb-3 p-1">
            <input
              type="text"
              id="rDomainCity2"
              class="form-control"
              placeholder="Ouagadougou"
              v-on:input="resetInput"
              required
            />
            <label for="rDomainCity2">Ville</label>
          </div>

          <div class="form-floating mb-3 p-1">
            <input
              type="number"
              id="rDomainPostalCode2"
              class="form-control"
              placeholder="Ouaga 2000"
              v-on:input="resetInput"
              required
            />
            <label for="rDomainPostalCode2">Code postale</label>
          </div>
        </div>
        <!--  -->
        <div class="row oneRowItem">
          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              id="rDomainAddress2"
              class="form-control"
              placeholder="Ouaga 2000"
              @input="resetInput"
              required
            />
            <label for="rDomainAddress2">Adresse</label>
          </div>
        </div>

        <div class="d-flex mb-2 mt-4 rDomaintitle justify-content-between">
          <h5 class="d-block float-start me-3 mt-2">
            Information sur l'administrateur
          </h5>
          <button
            type="button"
            class="btn btn-primary w-auto"
            @click="fillInput(3)"
          >
            <a>{{ repeatDataText }}</a
            ><i class="bi bi-arrow-down ms-2"></i>
          </button>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating mb-3 p-1">
            <input
              type="text"
              id="rDomainFname3"
              spellcheck="false"
              class="form-control"
              placeholder="Krepin Jhon"
              @input="resetInput"
              required
            />
            <label for="rDomainFname3">Nom</label>
          </div>

          <div class="form-floating mb-3 p-1">
            <input
              type="text"
              id="rDomainLname3"
              spellcheck="false"
              class="form-control"
              placeholder="Krepin Jhon"
              @input="resetInput"
              required
            />
            <label for="rDomainLname3">Prénom</label>
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating mb-3 p-1">
            <input
              vinput="email"
              @input="checkEmailInput"
              type="text"
              id="rDomainEmail3"
              class="form-control"
              placeholder="krepin@jhon.com"
              required
            />
            <label for="rDomainEmail3">Adresse Email</label>
          </div>

          <div class="form-floating mb-3">
            <a style="font-size: 14px">Numéro de téléphone</a>
            <br />
            <input
              type="tel"
              id="rDomainTel3"
              class="form-control rDomainTel"
              @input="resetInput"
              required
            />
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating mb-3 p-1">
            <CountryList id="rDomainCountry3"></CountryList>
            <label for="rDomainCountry3">Pays</label>
          </div>

          <div class="form-floating mb-3 p-1">
            <input
              type="text"
              id="rDomainProvince3"
              class="form-control"
              placeholder="Ouagadougou"
              v-on:input="resetInput"
              required
            />
            <label for="rDomainProvince3">Province</label>
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating mb-3 p-1">
            <input
              type="text"
              id="rDomainCity3"
              class="form-control"
              placeholder="Ouagadougou"
              v-on:input="resetInput"
              required
            />
            <label for="rDomainCity3">Ville</label>
          </div>

          <div class="form-floating mb-3 p-1">
            <input
              type="number"
              id="rDomainPostalCode3"
              class="form-control"
              placeholder="Ouaga 2000"
              v-on:input="resetInput"
              required
            />
            <label for="rDomainPostalCode3">Code postale</label>
          </div>
        </div>
        <!--  -->
        <div class="row oneRowItem">
          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              id="rDomainAddress3"
              class="form-control"
              placeholder="Ouaga 2000"
              v-on:input="resetInput"
              required
            />
            <label for="rDomainAddress3">Adresse</label>
          </div>
        </div>

        <div class="d-flex mb-2 mt-4 rDomaintitle justify-content-between">
          <h5 class="d-block float-start me-3 mt-2">
            Information de facturation
          </h5>
          <button
            type="button"
            class="btn btn-primary w-auto"
            @click="fillInput(4)"
          >
            <a>{{ repeatDataText }}</a
            ><i class="bi bi-arrow-down ms-2"></i>
          </button>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating mb-3 p-1">
            <input
              type="text"
              id="rDomainFname4"
              spellcheck="false"
              class="form-control"
              placeholder="Krepin Jhon"
              @input="resetInput"
              required
            />
            <label for="rDomainFname4">Nom</label>
          </div>

          <div class="form-floating mb-3 p-1">
            <input
              type="text"
              id="rDomainLname4"
              spellcheck="false"
              class="form-control"
              placeholder="Krepin Jhon"
              @input="resetInput"
              required
            />
            <label for="rDomainLname4">Prénom</label>
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating mb-3 p-1">
            <input
              vinput="email"
              @input="checkEmailInput"
              type="text"
              id="rDomainEmail4"
              class="form-control"
              placeholder="krepin@jhon.com"
              required
            />
            <label for="rDomainEmail4">Adresse Email</label>
          </div>

          <div class="form-floating mb-3">
            <a style="font-size: 14px">Numéro de téléphone</a>
            <br />
            <input
              type="tel"
              id="rDomainTel4"
              class="form-control rDomainTel"
              @input="resetInput"
              required
            />
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating mb-3 p-1">
            <CountryList id="rDomainCountry4"></CountryList>
            <label for="rDomainCountry4">Pays</label>
          </div>

          <div class="form-floating mb-3 p-1">
            <input
              type="text"
              id="rDomainProvince4"
              class="form-control"
              placeholder="Ouagadougou"
              @input="resetInput"
              required
            />
            <label for="rDomainProvince4">Province</label>
          </div>
        </div>
        <!--  -->
        <div class="row oneRow">
          <div class="form-floating mb-3 p-1">
            <input
              type="text"
              id="rDomainCity4"
              class="form-control"
              placeholder="Ouagadougou"
              @input="resetInput"
              required
            />
            <label for="rDomainCity4">Ville</label>
          </div>

          <div class="form-floating mb-3 p-1">
            <input
              type="number"
              id="rDomainPostalCode4"
              class="form-control"
              placeholder="Ouaga 2000"
              @input="resetInput"
              required
            />
            <label for="rDomainPostalCode4">Code postale</label>
          </div>
        </div>
        <!--  -->
        <div class="row oneRowItem">
          <div class="form-floating p-1 mb-3">
            <input
              type="text"
              id="rDomainAddress4"
              class="form-control"
              placeholder="Ouaga 2000"
              @input="resetInput"
              required
            />
            <label for="rDomainAddress4">Adresse</label>
          </div>
        </div>
        <!--  -->
        <!--  -->
        <!--  -->
        <!--  -->
        <!--  -->
        <div class="col-12 d-flex justify-content-center">
          <button type="submit">
            ACTIVER LE NOM DE DOMAINE
            <i class="bi bi-check-lg"></i>
          </button>
        </div>
      </form>
    </center>
  </main>
</template>

<script>
const $ = require("jquery");
import Globals from "/src/common/js/Globals";
import waitLoader from "/src/common/js/waitLoader";
import functions from "/src/common/js/functions";
import axios from "axios";
import popup from "/src/common/js/popup";
import CountryList from "/src/components/countryList.vue";
//
import intlTelInput from "intl-tel-input";
//
import { mapStores } from "pinia";
import { useUsersStore } from "/src/common/stores/users.js";

//login component
export default {
  computed: {
    ...mapStores(useUsersStore),
  },

  components: {
    CountryList,
  },

  data() {
    return {
      repeatDataText: "Repéter les informations",
      //
      pageName: "Enregistrer son nom de domaine",
      hasEnterprise: false,
      //
      userData: {
        id: "",
        fname: "",
        email: "",
        tel: "",
        home_address: "",
        country: "",
        city: "",
      },
      //
      intTel1: null,
      intTel2: null,
      intTel3: null,
      intTel4: null,
      //
      domainName: "---",
    };
  },

  mounted() {
    //set the routeName
    $("#routeName")[0].innerHTML = this.pageName;
    //
    this.intTel1 = intlTelInput($("#rDomainTel1")[0], {
      // any initialisation options go here
      initialCountry: "bf",
      preferredCountries: ["bf", "ci"],
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
    });
    this.intTel2 = intlTelInput($("#rDomainTel2")[0], {
      // any initialisation options go here
      initialCountry: "bf",
      preferredCountries: ["bf", "ci"],
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
    });
    this.intTel3 = intlTelInput($("#rDomainTel3")[0], {
      // any initialisation options go here
      initialCountry: "bf",
      preferredCountries: ["bf", "ci"],
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
    });
    this.intTel4 = intlTelInput($("#rDomainTel4")[0], {
      // any initialisation options go here
      initialCountry: "bf",
      preferredCountries: ["bf", "ci"],
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
    });
    //
    //
    this.__update();
  },

  methods: {
    __update() {
      waitLoader.show();
      //
      setTimeout(() => {
        this.update();
        waitLoader.hide();
      }, 1);
    },

    update() {
      setTimeout(() => {
        //
        this.domainName = this.usersStore.User.activateDomain;
      }, 1000);

      //check if enterprise id exist
      let h = this.usersStore.User.data.enterprise_id;
      this.hasEnterprise = h == null ? false : true;
      //
      //
      this.getUserData();
    },

    async getUserData() {
      try {
        //get All user data in json format
        let id = this.usersStore.User.account.id;
        id = functions.correctQueryString(id);
        const resp = await axios.get(
          Globals.website_url + "php/api.php?query=get-user-data-by-id/" + id
        );
        this.userData = resp.data;
        //refresh the header userName
        $("#headerUserName")[0].innerHTML = this.userData.fname;
        //set user country
        $("#rDomainCountry1")[0].value = this.userData.country;
        $("#rDomainCountry2")[0].value = this.userData.country;
        $("#rDomainCountry3")[0].value = this.userData.country;
        $("#rDomainCountry4")[0].value = this.userData.country;
        this.usersStore.User.data = this.userData;
        //
        waitLoader.hide();
      } catch (err) {
        // Handle Error Here
        waitLoader.hide();
        //
        //say error
        popup.action = "Notification";
        popup.setTitle("Notification");
        popup.setMessage(
          "Erreur de récupération des données. Veuillez actualiser la page"
        );
        popup.setButton("Ok");
        popup.show();
        //
        this.userData = {};
      }
    },

    getFirstName(str) {
      if (str == null) return "";
      return str.substr(0, str.indexOf(" "));
    },

    getLastName(str) {
      if (str == null) return "";
      return str.substr(str.indexOf(" ") + 1, 256);
    },

    fillInput(index) {
      $("#rDomainFname" + index).val($("#rDomainFname1").val());
      $("#rDomainLname" + index).val($("#rDomainLname1").val());
      $("#rDomainEmail" + index).val($("#rDomainEmail1").val());
      //
      $("#rDomainTel" + index).val($("#rDomainTel1").val());
      //
      $("#rDomainCountry" + index).val($("#rDomainCountry1").val());
      $("#rDomainProvince" + index).val($("#rDomainProvince1").val());
      $("#rDomainCity" + index).val($("#rDomainCity1").val());
      $("#rDomainPostalCode" + index).val($("#rDomainPostalCode1").val());
      $("#rDomainAddress" + index).val($("#rDomainAddress1").val());
    },

    checkEmailInput: function (e) {
      e.target.setAttribute("input-error", "false");
      //
      if (
        e.target.getAttribute("vinput") === "email" &&
        !validator.isEmail(e.target.value)
      ) {
        e.target.setAttribute("input-error", "true");
      }
    },

    async validate() {
      waitLoader.show();
      //
      let data = {
        Domain: this.domainName,
        Years: $("#rDomainYears").val(),
      };
      //
      let $self = this;
      //
      function dataFy(index) {
        data["Fname" + index] = $("#rDomainFname" + index).val();
        data["Lname" + index] = $("#rDomainLname" + index).val();
        data["Email" + index] = $("#rDomainEmail" + index).val();
        //
        let dc = $self["intTel" + index].getSelectedCountryData().dialCode;
        let num = $self["intTel" + index].getNumber();
        num = num.substr(dc.length + 1, num.length);
        data["Tel" + index] = "+" + dc + "." + num;
        //
        data["Country" + index] = $("#rDomainCountry" + index).val();
        data["Province" + index] = $("#rDomainProvince" + index).val();
        data["City" + index] = $("#rDomainCity" + index).val();
        data["PostalCode" + index] = $("#rDomainPostalCode" + index).val();
        data["Address" + index] = $("#rDomainAddress" + index).val();
        //
        data["subs_id"] = $self.usersStore.User.curSubscriptionIds;
      }
      dataFy(1);
      dataFy(2);
      dataFy(3);
      dataFy(4);
      //
      // Axios automatically serializes object into JSON.
      try {
        const resp = await axios.post(
          Globals.website_url + "php/domain/updateDomainInfos.php",
          JSON.stringify(data)
        );
        let h = resp.data.data;
        //
        // console.log(h);
        //
        if (h == -1 || h == -2) {
          waitLoader.hide();
          //say error
          popup.action = "Notification";
          popup.setTitle("Notification");
          popup.setMessage(
            "Erreur d'activation du nom de domaine!<br>Veuillez vérifier vos informations puis reéssayer."
          );
          popup.setButton("Ok");
          popup.show();
          return;
        }
        if (h == 0) {
          let error = resp.data.error;
          //
          waitLoader.hide();
          //say error
          popup.action = "Notification";
          popup.setTitle("Notification");
          popup.setMessage(
            error + " Veuillez contacter l'administrateur d'Ecocloud."
          );
          popup.setButton("Ok");
          popup.show();
          return;
        }
        //
        console.log(resp.data);
        //
        waitLoader.hide();
        //say error
        popup.action = "confirm";
        popup.setTitle("Notification");
        popup.setMessage(
          "Vos informations de domaine on été soumises avec succès!"
        );
        popup.setButton("Ok");
        popup.show();
        //
        $("#popupYes").click(() => {
          if (popup.action != "confirm") return;
          //
          this.$router.push("/client/Subscriptions/Services");
        });
        //
        return;
      } catch (e) {
        console.log(e);
      }
    },
    //
  },
};
</script>

<style scoped>
@import "intl-tel-input/build/css/intlTelInput.css";

#rDomain {
  padding: 24px 12px;
  position: relative;
}

/*----------------------------------
USER DATA EDIT
----------------------------------*/
#rDomain form {
  max-width: 800px;
  background-color: #f2fbff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  text-align: left;
  color: #111a2b;
  padding: 28px;
}

#rDomain form input[readonly] {
  background-color: #f8f8f8;
  color: #888;
}

#rDomain form select {
  /**/
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("/src/assets/images/select-arrow.svg");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  background-color: white;
}

#rDomain form button[type="submit"] {
  margin-left: 12px;
  border: none;
  border-radius: 3px;
  padding: 8px 24px;
  width: auto;
  box-sizing: content-box;
  color: white;
  background-color: #111a2b;
  font-size: 17px;
}

#rDomain form button[type="submit"]:hover {
  color: white;
  background-color: #1b2842;
}

#rDomain form button[type="submit"] i {
  color: rgba(123, 255, 0);
  font-size: 16px;
}

#rDomain form button[type="submit"]:hover {
  background-color: #346;
}

.rDomaintitle {
  border-bottom: solid 1px black;
  padding-bottom: 8px;
  margin-bottom: 16px;
}

.rDomaintitle button {
  float: left !important;
}

.rDomainTel {
  width: 356px !important;
}

#rDomain .oneRow div {
  display: inline !important;
  width: 50% !important;
}

#rDomain .oneRowItem div {
  display: inline !important;
  width: 100% !important;
}

[input-error="true"] {
  border-color: red !important;
}
</style>
